export class Subject {
  subjectId?: number;
  patientId?: number;
  displayName?: string; // ALong for the ride
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date; // Along for the ride
  gender?: string; // Along for the ride
  subjectStatusId?: number;
  subjectStatusName?: string;
  isDeleted: boolean;

  constructor(defaultValues: Partial<Subject>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }
}
