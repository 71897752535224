<div class="b_container messages">
  <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
  <div class="clear"></div>


  <div class="col-12 col-sm-6">
    <div>
      <button type="button" class="btn btn-primary marright" (click)="helpTopicsNav()">
        Help Topics
      </button>
      <button type="button" class="btn btn-primary" (click)="closeFaqRequest()">
        My Requests
      </button>
    </div>
  </div>
  <div class="spacer"></div>
  <div class="spacer"></div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p-messages [value]="msgs"></p-messages>

    <div class="row">
      <div class="col-12 col-sm-6">

        <div *ngIf="!addingNew" class="form-group">
          <div class="col-sm-12">
            <b>Ticket No: {{ helpDetailsId }}</b>
          </div>
        </div>
        <div *ngIf="helpClosed">
          <div class="form-group">
            <div class="col-sm-12">
              <span class="alert alert-warning">Note: <b>Ticket Closed</b>, User cannot edit
                <fa name="ban"></fa>
              </span>
            </div>
          </div>
          <div class="spacer"></div>
        </div>

        <div class="form-group">
          <div class="col-sm-12">Reason For Report</div>
          <div class="col-sm-12 dd95">
            <p-dropdown dropdownIcon="fa fa-caret-down" placeholder="Select" [options]="reasonSelections"
              formControlName="reportReason" optionLabel="helpReasonName"></p-dropdown>
            <p></p>
          </div>
        </div>

        <div class="form-group">
          <div class="col-sm-12">Phone Number, If you would like a call</div>
          <div class="col-sm-12">
            <p-inputMask mask="(999) 999-9999" placeholder="(999) 999-9999" formControlName="phoneNumber">
            </p-inputMask>
          </div>
        </div>

        <div class="form-group">
          <div class="col-sm-12">Description</div>

          <div class="col-sm-12">
            <textarea pInputTextarea formControlName="description" rows="10" class="form-control"></textarea>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-12">Priority</div>
          <div class="col-sm-12 dd95">
            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="prioritySelections" formControlName="priority"
              optionLabel="helpPriorityName" placeholder="Select"></p-dropdown>
            <p></p>
          </div>
        </div>

        <div class="form-group">
          <div class="col-sm-12">
            <div class="btnnav">
              <button *ngIf="addingNew" type="submit" [disabled]="!form.valid || helpClosed"
                class="btn btn-primary marright">
                Submit
              </button>
              <button type="button" (click)="closeFaqRequest()" class="btn btn-primary marright">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>



      <div class="col-sm-12 row">
        <div class="col-sm-6 center-block"></div>

        <div class="clear"></div>
        <div class="spacer"></div>
        <div class="clear"></div>
        <div class="spacer"></div>
      </div>
    </div>
  </form>
  <div class="forminfo">
    <ul>
      <li><span class="mandatory">*</span> fields are mandatory</li>
    </ul>
  </div>
</div>