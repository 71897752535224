<div class="bodycover">
    <div class="loginnav messages">
        <p-messages [value]="msgs"></p-messages>
        <div class="leftnav pgmsgs smsg">
            <div class="login-form-container">


                <div class="logoouter">
                    <div class="logo"></div>
                </div>
                <div id="sign-in-widget"></div>

            </div>
            <div class="login-footer">{{getYear()}} &copy; LIDO | <a href="http://lido.io" target="_blank">Clinical
                    Trial Operating System</a></div>
        </div>
    </div>
</div>