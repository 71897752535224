import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppsettingsService } from '../../core/appsettings.service';
import { ApiResponse } from '../../core/apiresponse';
import { Patient } from '../model/patient.model';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  // Get all patients for dropdown
  getPatients(): Observable<Patient[]> {
    return this.http.get<Patient[]>(this.settings.baseUrl + `api/patient`);
  }

  // Get a Patient for editing
  getByPatientId(patientId: number): Observable<Patient> {
    return this.http.get<Patient>(
      this.settings.baseUrl + 'api/patient/' + patientId,
      this.httpOptions
    );
  }

  getBySubjectId(subjectId: number): Observable<Patient> {
    return this.http.get<Patient>(
      this.settings.baseUrl + 'api/patient/subjectId/' + subjectId,
      this.httpOptions
    );
  }

  // Create a new subject
  createPatient(patient: Patient) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/patient/',
      patient,
      this.httpOptions
    );
  }

  // Update a subject details
  updatePatient(patient: Patient) {
    return this.http.put<ApiResponse>(
      this.settings.baseUrl + 'api/patient/',
      patient,
      this.httpOptions
    );
  }
}
