import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { ForgotPasswordEmailComponent } from './forgot-password/forgot-password-email/forgot-password-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordSecquestionsComponent } from './forgot-password/forgot-password-secquestions/forgot-password-secquestions.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule, AuthRoutingModule],
  declarations: [
    ForgotPasswordComponent,
    ForgotPasswordEmailComponent,
    ForgotPasswordSecquestionsComponent,
    ResetPasswordComponent,
    LoginComponent,
  ],
  exports: [
    ForgotPasswordComponent,
    ForgotPasswordEmailComponent,
    ForgotPasswordSecquestionsComponent,
    ResetPasswordComponent,
    LoginComponent,
  ],
})
export class AuthModule {}
