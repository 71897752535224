// User status Id values - StatusId 1 is active StatusId 2 is deleted or blocked?
export class User {
  userId?: number;
  userName?: string;
  password?: string;
  email?: string;
  siteId?: number; // If they are an admin
  siteName?: string; // if they are an admin
  shortName?: string;
  displayName?: string;
  securityQuestionId1?: number;
  answer1?: string;
  securityQuestionId2?: number;
  answer2?: string;
  securityQuestionId3?: number;
  answer3?: string;
  isSignatureAccept?: boolean;
  userTypeId?: number; // For the site you are on, the user's type id
  userTypeName?: string;
  userStatusId?: number;
  userStatusName?: string;
  isSignupVerified?: boolean;
  isProfileVerified?: boolean;
  createdBy?: number;
  createdOn?: Date;
  modifiedBy?: number;
  modifiedOn?: Date;
  isSiteAdmin?: boolean;
  isExternalUser?: boolean;
  ssoUserId?: string;

  address1?: string; // Address fields not used
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: number;
  phoneNumber?: string; // not used
  prefix?: string; // not used
  sendPromoMail?: string; // not used
  isChecked?: boolean; // not used
  action?: string; // not used
  secondaryEmail?: string; // not used
  signatureOption?: number; // which signature the user wants to use
  UserRoleId?: number; // this should not be here
  lockScreenId?: number; // not used
  primaryGuid?: string; // not used
  secondaryGuid?: string; // not used
  lstUserTypeIds?: number[]; // not used
  taskLibraryIds?: number[]; // not used
  isDefaultSiteUser?: boolean; // I can see this getting used but its not
  isPiUser?: boolean; // this is dumb

  isWorkFlowUserSelected?: boolean; // Used by workflowrouting
  isWorkFlowNotification?: boolean; // Used by workflowrouting
  isWorkFlowApproval?: boolean; // Used by workflowrouting

  userAssignedToTrial?: string; // trial
  userRoleName?: string; // trial

  constructor(defaultValues: Partial<User>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
    this.userTypeId = this.userTypeId === undefined ? 3 : this.userTypeId;
  }
}
