import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list.component';
import { SharedModule } from '../shared/shared.module';
import { EditUserComponent } from './add-user/edit-user.component';
import { SelectUserComponent } from './select-user/select-user.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    UserListComponent,
    EditUserComponent,
    SelectUserComponent,
  ],
  exports: [
    UserListComponent,
    EditUserComponent,
    SelectUserComponent
  ]
})
export class UserListModule { }
