import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { Message } from 'primeng/api';
import { TrialService } from '../service/trial.service';
import { SponsorLibraryService } from '../../sponsor/service/sponsorlibrary.service';

import { Trial } from '../model/trial.model';
import { SponsorDropdown } from '../../sponsor/model/sponsordropdown.model';

import { UserService } from 'src/app/users/service/user.service';
import { AppConstants } from '../../common/app.constants';
import { RequestErrors } from 'src/app/common/utils';
import { User } from 'src/app/users/model/user.model';
import { TrialUser } from '../model/trialuser.model';
import { UserRole } from 'src/app/users/model/userrole.model';

export interface SponsorItem {
  label: string;
  value: SponsorDropdown;
  sponsorId?: number;
  sponsorName?: string;
  sponsorLogo?: string;
}

@Component({
  selector: 'app-add-trial',
  templateUrl: './add-trial.component.html',
  styleUrls: ['./add-trial.component.scss'],
})
export class AddTrialComponent implements OnInit {
  @Output() closeTrialnav = new EventEmitter<boolean>();

  sponsorList: SponsorDropdown[];
  filteredSponsors: SponsorDropdown[];
  defaultSponsorLogo = AppConstants.defaultSponsorLogo;
  form: UntypedFormGroup;
  otherSponsor = false;
  siteName = sessionStorage.getItem('siteName');
  siteId = Number(sessionStorage.getItem('siteId'));
  trial: Trial;
  msgs: Message[];
  processing = false;
  selectedSponsor: SponsorDropdown;
  piUser: TrialUser;
  userRoles: UserRole[];

  users: User[];

  constructor(
    private trialService: TrialService,
    private sponsorLibraryService: SponsorLibraryService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) {}

  closeAddTrial(refresh = false) {
    this.closeTrialnav.emit(refresh);
  }

  filterSponsorDropDown(event) {
    const sponsorQuery = event.query;
    this.sponsorLibraryService.getSponsorList().subscribe(
      (sponsors) => {
        this.filteredSponsors = this.filterSponsor(sponsorQuery, sponsors);
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  filterSponsor(query, sponsors: SponsorDropdown[]): SponsorItem[] {
    const filteredSponsors: SponsorItem[] = [];
    for (let i = 0; i < sponsors.length; i++) {
      const sponsor = sponsors[i];
      if (
        sponsor.sponsorName.toLowerCase().indexOf(query.toLowerCase()) === 0
      ) {
        filteredSponsors.push({
          label: sponsor.sponsorName,
          value: sponsor,
          sponsorId: sponsor.sponsorId,
          sponsorLogo: sponsor.sponsorLogo,
          sponsorName: sponsor.sponsorName,
        });
      }
    }
    return filteredSponsors;
  }

  getSponsors() {
    this.sponsorLibraryService.getSponsorList().subscribe(
      (sponsorDropDown) => {
        this.filteredSponsors = sponsorDropDown;
        const idx = this.filteredSponsors.findIndex(
          (x) => x.sponsorName === 'Other'
        );
        const otherSponsor = this.filteredSponsors[idx];
        this.filteredSponsors.splice(idx, 1);
        this.filteredSponsors.push(otherSponsor);
        this.sponsorList = this.filteredSponsors.map((x) => {
          return {
            label: x.sponsorName,
            value: x,
            sponsorId: x.sponsorId,
            sponsorLogo: x.sponsorLogo,
            sponsorName: x.sponsorName,
          };
        });
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  onSubmit() {
    if (this.piUser === undefined) {
      this.msgs = [
        {
          severity: 'warn',
          detail: 'Select a primary investigator to create a trial',
        },
      ];
      return;
    }
    this.processing = true;

    this.trial.trialName = this.form.controls.trialName.value;
    this.trial.protocolNumber = this.form.controls.protocolNumber.value;
    this.trial.sponsorId = this.form.controls.sponsorSelect.value.sponsorId;
    this.trial.trialStatusId = 2; // Ignored on the backend for now
    this.trial.isPiApproved = false;
    this.trial.projectId = this.form.controls.projectId.value;
    this.trial.siteNumber = this.form.controls.siteNumber.value;
    this.trial.piUserId = this.piUser.userId;
    this.trial.piUserName = this.piUser.displayName;

    if (this.otherSponsor) {
      this.trial.sponsorName = this.form.controls.sponsorName.value;
    } else {
      this.trial.sponsorName =
        this.form.controls.sponsorSelect.value.sponsorName;
    }

    this.trialService.addTrial(this.trial).subscribe(
      (messageResponse) => {
        this.processing = false;
        if (messageResponse.success === true) {
          this.closeAddTrial(true);
        } else {
          this.msgs = messageResponse.errorMessages.map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      },
      (error) => {
        this.processing = false;
        const errors = RequestErrors(error);
        this.msgs = errors.map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  saveTrialUsers(trialUsers: TrialUser[]) {
    this.trial.trialUsers = [...trialUsers];
    this.piUser = this.trial.trialUsers.find((x) => x.userRoleId === 4);
  }

  ngOnInit() {
    this.trial = new Trial({
      siteId: this.siteId,
      trialId: 0,
      trialStatusName: 'Active',
      trialUsers: [],
    });
    this.getData();
    this.getSponsors();
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      trialName: ['', [Validators.required, Validators.minLength(3)]],
      protocolNumber: ['', [Validators.required, Validators.minLength(3)]],
      projectId: [''],
      siteNumber: ['', [Validators.required, Validators.minLength(3)]],
      sponsorSelect: ['', [Validators.required]],
      sponsorName: [''],
    });
    this.form.controls['sponsorSelect'].valueChanges.subscribe((val) => {
      this.otherSponsor = val.value.sponsorName === 'Other';
      this.selectedSponsor = val.value;

      if (this.otherSponsor) {
        this.form
          .get('sponsorName')
          .setValidators([Validators.required, Validators.minLength(3)]);
      } else {
        this.form.get('sponsorName').setValue('');
        this.form.get('sponsorName').clearValidators();
        this.form.controls['sponsorName'].setErrors(null);
      }
      this.form.updateValueAndValidity();
    });
  }

  getData(): void {
    this.userService
      .getNonPIUserDetailsforTrials(this.siteId, this.trial.trialId)
      .subscribe(
        (users) => {
          this.users = users;
          if (this.users.length === 0) {
            this.msgs = [
              {
                severity: 'info',
                detail:
                  'This site has no users - go to Site User Management to add users before adding trials',
              },
            ];
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }
}
