import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message, MenuItem } from 'primeng/api';
import { SubjectService } from './service/subject.service';
import { Subject } from './model/subject.model';
import { AppConstants } from '../common/app.constants';
import { RequestErrors, makeBreadCrumbs } from '../common/utils';
import { Patient } from './model/patient.model';
import { PatientService } from './service/patient.service';
@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss'],
})
export class SubjectComponent implements OnInit {
  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));

  loadEditSubject = false;
  loadEditPatient = false;
  loadUnlinkTrialSubject = false;
  subjectId: number;
  subjects: Subject[];
  selectedSubject: Subject;
  selectedPatient: Patient;
  allowEdits = false;
  sortOptions = [
    { label: 'Name A to Z', value: 'displayName' },
    { label: 'Name Z to A', value: '!displayName' },
    { label: 'Status', value: 'subjectStatusName' },
  ];

  pMenuItems: MenuItem[] = [];

  sortKey: string;
  sortField: string;
  sortOrder: number;
  recsPerPage = AppConstants.folderListGridPerPage;
  initialView = AppConstants.vendorFlowView;
  currentView = AppConstants.vendorFlowView;
  pageLinksPerPage = AppConstants.dataViewPagerLinks;
  emptyRecordMsg = AppConstants.emptyRecordMsg;

  msgs: Message[] = [];

  constructor(
    private subjectService: SubjectService,
    private patientService: PatientService,
    private router: Router
  ) {}

  onLayoutChange($event, dv) {
    if (dv.layout === 'list') {
      this.recsPerPage = AppConstants.gridViewRecsPerPage;
      this.currentView = 'list';
    } else {
      this.recsPerPage = AppConstants.gridViewRecsPerPage;
      this.currentView = 'grid';
    }
  }

  // sort
  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  // Used to edit an existing patient on an existing trial
  openEditPatient(subject: Subject) {
    this.selectedSubject = subject;
    this.patientService.getBySubjectId(subject.subjectId).subscribe(
      (patient: Patient) => {
        this.selectedPatient = patient;
        this.loadEditPatient = true;
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return {
            severity: 'warn',
            detail: x,
          };
        });
      }
    );
  }

  openAddPatient() {
    this.selectedPatient = new Patient({});
    this.loadEditPatient = true;
  }

  openAddSubject() {
    this.selectedSubject = new Subject({});
    this.loadEditSubject = true;
  }

  // Remove a subject from a trial
  openUnlinkTrialSubject(subject: Subject) {
    this.selectedSubject = subject;
    this.loadUnlinkTrialSubject = true;
  }

  closeModals(refresh = false) {
    this.loadEditSubject = false;
    this.loadEditPatient = false;
    this.loadUnlinkTrialSubject = false;
    if (refresh) {
      this.getData();
    }
  }

  gotoSubjectDocs(subject: Subject) {
    sessionStorage.setItem('subjectName', subject.displayName);
    this.router.navigate(['/layout/subject-documents', subject.subjectId], {});
  }

  getData() {
    this.subjectService
      .getSubjectsForTrial(this.siteId, this.trialId)
      .subscribe(
        (subjects) => {
          this.subjects = subjects;
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return {
              severity: 'warn',
              detail: x,
            };
          });
        }
      );
  }

  ngOnInit() {
    if (sessionStorage.getItem('trialStatusId') === '2') {
      this.allowEdits = true;
    }
    this.pMenuItems = makeBreadCrumbs(true, 'Subject Records');
    this.getData();
  }
}
