import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Trial } from '../model/trial.model';
import { AppsettingsService } from '../../core/appsettings.service';
import { ApiResponse } from '../../core/apiresponse';
import { TrialStatusDropDown } from '../model/trialstatusdropdown.model';
import { Vendor } from 'src/app/vendors/model/vendor.model';

@Injectable({
  providedIn: 'root',
})
export class TrialService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  // HomeComponent
  getAllTrialsBySiteId(siteId: number): Observable<Trial[]> {
    return this.http.get<Trial[]>(
      this.settings.baseUrl + 'api/trial/sid/' + siteId
    );
  }

  getTrialDetailsforEdit(trialId: number, siteId: number): Observable<Trial> {
    return this.http.get<Trial>(
      this.settings.baseUrl + `api/trial/trialedit/${siteId}/${trialId}`
    );
  }

  // Get all vendors for a trial for trialVendors page
  getTrialVendors(trialId: number, siteId: number): Observable<Vendor[]> {
    return this.http.get<Vendor[]>(
      this.settings.baseUrl + 'api/trial/' + trialId + '/' + siteId
    );
  }

  addTrial(trial: Trial) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/trial',
      trial,
      this.httpOptions
    );
  }

  editTrial(trial: Trial) {
    return this.http.patch<ApiResponse>(
      this.settings.baseUrl + 'api/trial',
      trial,
      this.httpOptions
    );
  }

  getTrialStatusDropDown(): Observable<TrialStatusDropDown[]> {
    return this.http.get<TrialStatusDropDown[]>(
      this.settings.baseUrl + 'api/trialstatus/TrialStatusDropDown'
    );
  }
}
