import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../auth/auth.service';
import { NavMenuService } from '../../nav-menu/nav-menu.service';
@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss'],
})
export class ProfileLayoutComponent implements OnInit {
  @Input() showUserManagement = false;
  displayName = sessionStorage.getItem('displayName');
  isSiteHome = sessionStorage.getItem('isSiteHome') === 'Yes';
  constructor(
    private authService: AuthService,
    private navService: NavMenuService
  ) {}

  ngOnInit() {
    this.navService.siteHomeChanged$.subscribe(() => {
      this.displayName = sessionStorage.getItem('displayName');
      if (sessionStorage.getItem('isSiteHome') === 'Yes') {
        this.isSiteHome = true;
      } else {
        this.isSiteHome = false;
      }
    });
  }

  logout() {
    this.authService.logout();
  }
}
