import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home.component';
import { SiteHomeComponent } from './sitehome.component';
import { TrialModule } from '../trial/trial.module';
@NgModule({
  imports: [CommonModule, SharedModule, TrialModule],
  declarations: [HomeComponent, SiteHomeComponent],

  exports: [HomeComponent, SiteHomeComponent],
})
export class HomeModule {}
