import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MenuItem, Message } from 'primeng/api';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ApiResponse } from '../core/apiresponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpPriority } from './model/HelpPriority';
import { HelpReason } from './model/HelpReason';
import { EncrDecrService } from '../EncrDecr/encr-decr.service';
import { FaqService } from './service/faq.service';
import { HelpDetails } from './model/HelpDetails';
import { HelpStatus } from './model/HelpStatus';
import { RequestErrors, makeBreadCrumbItemsHelp } from '../common/utils';
import { NavMenuService } from '../layout/components/nav-menu/nav-menu.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-faqeditrequest',
  templateUrl: './faqeditrequest.component.html',
  styleUrls: ['./faqeditrequest.component.scss'],
})
export class FaqEditRequestComponent implements OnInit {
  @ViewChild('fileInputUpload') myfileInput: ElementRef;
  ismobileNumberDisabled: boolean;
  pMenuItems: MenuItem[] = [];
  helpDetailsId = this.route.snapshot.paramMap.get('id');
  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));
  addingNew = false;
  faqRequestURL: string;
  form: UntypedFormGroup;
  msgs: Message[];
  apires: ApiResponse;
  reasonSelections: HelpReason[];
  prioritySelections: HelpPriority[];
  helpDetails: HelpDetails;
  statusSelections: HelpStatus[];
  selectedfaqStatus: HelpStatus;
  faqQuestion = '';
  faqReason = '';
  selectedReportReason: HelpReason;
  priority: HelpPriority;
  helpReasonId: number;
  description: string;

  helpClosed = false;
  DocsName = '';
  DocPath = '';
  DocType = '';
  testhtml = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private faqService: FaqService,
    private EncrDecr: EncrDecrService,
    private navMenuService: NavMenuService
  ) {}

  ngOnInit() {
    this.faqRequestURL = this.EncrDecr.decryptData(
      this.route.snapshot.queryParamMap.get('reqType')
    );
    const helpReason = this.route.snapshot.queryParamMap.get('helpReason');
    this.faqReason =
      helpReason !== null ? this.EncrDecr.decryptData(helpReason) : null;
    this.faqReason = this.EncrDecr.decryptData(
      this.route.snapshot.queryParamMap.get('helpReason')
    );
    // might be : 'Upgrade to Paid Subscription', 'Issue With SSO Login to Vendor'
    this.addingNew = this.helpDetailsId === null;
    this.navMenuService.trialState$.subscribe((trialDetails) => {
      this.pMenuItems = makeBreadCrumbItemsHelp(
        false,
        trialDetails.trialSelected
      );
      this.pMenuItems.push({
        label:
          this.addingNew === true ? 'Add Help Request' : 'View Help Request',
      });
    });
    this.initForm();
    this.getData();
  }

  initForm() {
    this.form = this.formBuilder.group({
      phoneNumber: [''],
      reportReason: ['', [Validators.required]],
      description: ['', [Validators.required]],
      priority: ['', [Validators.required]],
    });
  }

  closeFaqRequest() {
    this.router.navigate(['/layout/faq/helprequestlist']);
  }

  helpTopicsNav() {
    this.router.navigate(['/layout/lido-training/help']);
  }

  onSubmit(): void {
    let helpDetails;
    if (this.addingNew) {
      helpDetails = new HelpDetails({});
      helpDetails.siteId = this.siteId !== null ? this.siteId : 0;
      helpDetails.trialId = this.trialId !== null ? this.trialId : 0;
    } else {
      helpDetails = new HelpDetails(this.helpDetails);
    }

    helpDetails.phoneNumber = this.form.controls.phoneNumber.value;
    helpDetails.helpDescription = this.form.controls.description.value.trim();
    helpDetails.helpReasonId =
      this.form.controls.reportReason.value.helpReasonId;
    helpDetails.helpPriorityId =
      this.form.controls.priority.value.helpPriorityId;
    helpDetails.helpReasonName =
      this.form.controls.reportReason.value.helpReasonName;
    if (this.addingNew === true) {
      this.faqService.InsertHelpDetails(helpDetails).subscribe(
        (messageResponse: ApiResponse) => {
          this.apires = messageResponse;
          if (this.apires.success === true) {
            this.closeFaqRequest();
          } else {
            this.msgs = [];
            this.msgs.push({
              severity: 'error',
              detail: this.apires.errorMessages[0],
            });
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
    } else {
      this.faqService.updateHelpDetails(helpDetails).subscribe(
        (messageResponse: ApiResponse) => {
          this.apires = messageResponse;
          if (this.apires.success === false) {
            this.msgs = [];
            this.msgs.push({
              severity: 'error',
              detail: this.apires.errorMessages[0],
            });
          } else {
            this.closeFaqRequest();
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
    }
  }

  getData() {
    const getPriority = this.faqService.getHelpPriority();
    const getReason = this.faqService.getHelpReason();
    const getStatus = this.faqService.getHelpStatus();

    forkJoin([getPriority, getReason, getStatus]).subscribe(
      ([priorities, reasons, statuses]) => {
        this.prioritySelections = priorities;
        this.reasonSelections = reasons;
        this.statusSelections = statuses;
        if (this.addingNew === false) {
          this.getHelpDetails(Number(this.helpDetailsId));
        } else {
          if (this.faqReason !== null) {
            const reason = this.reasonSelections.find(
              (x) => x.helpReasonName === this.faqReason
            );
            this.form.patchValue({ reportReason: reason });
          }
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  getHelpDetails(helpid: number) {
    this.faqService.getHelpDetail(helpid).subscribe(
      (data) => {
        this.helpDetails = new HelpDetails(data);
        if (this.helpDetails.helpStatusName === 'Closed') {
          this.helpClosed = true;
        }
        this.patchForm();
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  patchForm() {
    const reason = this.reasonSelections.find(
      (x) => x.helpReasonId === this.helpDetails.helpReasonId
    );
    const status = this.statusSelections.find(
      (x) => x.helpStatusId === this.helpDetails.helpStatusId
    );
    const priority = this.prioritySelections.find(
      (x) => x.helpPriorityId === this.helpDetails.helpPriorityId
    );
    this.form.patchValue({
      phoneNumber: this.helpDetails.phoneNumber,
      reportReason: reason,
      description: this.helpDetails.helpDescription,
      priority: priority,
    });
  }
}
