import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Message } from 'primeng/api';

import { ApiResponse } from '../../../core/apiresponse';

import { ApiResponseResetPassword } from '../model/apiresponseresetpassword';
import { ForgotPasswordService } from '../service/forgotpassword.service';
import { ForgotPassword } from '../model/forgotpassword.model';
import { SecurityQuestions } from '../model/securityquestions.model';
import { UserSecurityQuestions } from '../model/usersecurityquestions.model';
import { ForgotPasswordLockUser } from '../model/forgotpassword-lockuser.model';
import { RequestErrors } from 'src/app/common/utils';

@Component({
  selector: 'app-forgot-password-secquestions',
  templateUrl: './forgot-password-secquestions.component.html',
  styleUrls: ['./forgot-password-secquestions.component.scss'],
})
export class ForgotPasswordSecquestionsComponent implements OnInit {
  verifyEmailForm: UntypedFormGroup;
  verifySecQtnsForm: UntypedFormGroup;

  public securityQuestion: string;

  showEmailNav = true;
  showSecQtionsNav = false;
  btnDisabled = true;
  sId: string;

  forgotPassword: ForgotPassword = {};
  forgotPasswordLockUser: ForgotPasswordLockUser = {};
  secQuestion: SecurityQuestions = {};
  forgotPasswordSecqtn: UserSecurityQuestions = {};
  apires: ApiResponse;
  apiresResetPwd: ApiResponseResetPassword;
  apiresDeactivate: ApiResponse;
  msgs: Message[] = [];
  sq: SecurityQuestions[] = [];
  tempsq: SecurityQuestions[];

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private forgotPasswordService: ForgotPasswordService
  ) {}

  getYear() {
    return new Date().getUTCFullYear();
  }

  openResetPassword() {
    this.router.navigate(['/auth/reset-password'], {
      skipLocationChange: true,
    });
  }

  cancelForgotPassword() {
    this.router.navigate(['/auth/forgot-password']);
    sessionStorage.clear();
  }

  onSubmit() {
    this.forgotPassword.email = this.verifyEmailForm.controls.email.value;
    this.forgotPassword.requestType = 2;
    this.forgotPasswordService
      .verifyEmailAddress(this.forgotPassword)
      .subscribe(
        (messageResponse) => {
          this.apiresResetPwd = messageResponse;
          if (messageResponse.id !== -1) {
            if (this.apiresResetPwd.success === true) {
              this.sq.push({
                colName: 'securityQuestionId1',
                securityQuestionId:
                  this.apiresResetPwd.resetPassword[0].securityQuestionId1,
                securityQuestion:
                  this.apiresResetPwd.resetPassword[0].securityQuestion1,
              });
              this.sq.push({
                colName: 'securityQuestionId2',
                securityQuestionId:
                  this.apiresResetPwd.resetPassword[0].securityQuestionId2,
                securityQuestion:
                  this.apiresResetPwd.resetPassword[0].securityQuestion2,
              });
              this.sq.push({
                colName: 'securityQuestionId3',
                securityQuestionId:
                  this.apiresResetPwd.resetPassword[0].securityQuestionId3,
                securityQuestion:
                  this.apiresResetPwd.resetPassword[0].securityQuestion3,
              });
              sessionStorage.setItem('sq', JSON.stringify(this.sq));
              this.secQuestion =
                this.sq[Math.floor(Math.random() * this.sq.length)];
              sessionStorage.setItem(
                'selQtn',
                JSON.stringify(this.secQuestion)
              );
              this.msgs = [];
              this.msgs.push({
                severity: 'success',
                detail: this.apiresResetPwd.message,
              });
              this.showSecQtionsNav = !this.showSecQtionsNav;
              this.showEmailNav = !this.showEmailNav;
              this.verifySecQtnsForm.controls.uId.setValue(
                this.apiresResetPwd.id
              );
              this.verifySecQtnsForm.controls.securityQuestionId.setValue(
                this.secQuestion.securityQuestionId
              );
              this.verifySecQtnsForm.controls.colName.setValue(
                this.secQuestion.colName
              );
              this.verifySecQtnsForm.controls.securityQuestionText.setValue(
                this.secQuestion.securityQuestion
              );
            }
            if (this.apiresResetPwd.success === false) {
              this.msgs = [
                {
                  severity: 'error',
                  detail: this.apiresResetPwd.errorMessages[0],
                },
              ];
            }
          } else {
            this.msgs.push({
              severity: 'error',
              detail: 'Security Questions are not Updated by You',
            });
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  onSubmitSeqQtns() {
    this.forgotPasswordSecqtn.userId =
      +this.verifySecQtnsForm.controls.uId.value;
    this.forgotPasswordSecqtn.isFailureAttemptReached = false;
    if (this.secQuestion.colName === 'securityQuestionId1') {
      this.forgotPasswordSecqtn.securityQuestionId1 =
        this.verifySecQtnsForm.controls.securityQuestionId.value;
      this.forgotPasswordSecqtn.answer1 =
        this.verifySecQtnsForm.controls.answer.value;
      this.forgotPasswordSecqtn.securityQuestionId2 = 0;
      this.forgotPasswordSecqtn.answer2 = '';
      this.forgotPasswordSecqtn.securityQuestionId3 = 0;
      this.forgotPasswordSecqtn.answer3 = '';
    }
    if (this.secQuestion.colName === 'securityQuestionId2') {
      this.forgotPasswordSecqtn.securityQuestionId2 =
        this.verifySecQtnsForm.controls.securityQuestionId.value;
      this.forgotPasswordSecqtn.answer2 =
        this.verifySecQtnsForm.controls.answer.value;
      this.forgotPasswordSecqtn.securityQuestionId1 = 0;
      this.forgotPasswordSecqtn.answer1 = '';
      this.forgotPasswordSecqtn.securityQuestionId3 = 0;
      this.forgotPasswordSecqtn.answer3 = '';
    }
    if (this.secQuestion.colName === 'securityQuestionId3') {
      this.forgotPasswordSecqtn.securityQuestionId3 =
        this.verifySecQtnsForm.controls.securityQuestionId.value;
      this.forgotPasswordSecqtn.answer3 =
        this.verifySecQtnsForm.controls.answer.value;
      this.forgotPasswordSecqtn.securityQuestionId2 = 0;
      this.forgotPasswordSecqtn.answer2 = '';
      this.forgotPasswordSecqtn.securityQuestionId1 = 0;
      this.forgotPasswordSecqtn.answer1 = '';
    }
    this.forgotPasswordService
      .verifySecQtnsAns(this.forgotPasswordSecqtn)
      .subscribe(
        (messageResponse) => {
          this.apiresResetPwd = messageResponse;
          if (this.apiresResetPwd.success === true) {
            this.msgs = [];
            this.msgs.push({
              severity: 'success',
              detail: this.apiresResetPwd.message,
            });
            setTimeout(() => {
              this.router.navigate(['/auth/reset-password'], {
                skipLocationChange: true,
              });
            }, 2000);
            sessionStorage.removeItem('sq');
            sessionStorage.removeItem('selQtn');
            sessionStorage.setItem(
              'userId',
              this.forgotPasswordSecqtn.userId.toString()
            );
            sessionStorage.setItem('requestType', '2');
          }
          if (this.apiresResetPwd.success === false) {
            this.verifySecQtnsForm.controls.securityQuestionId.setValue('0');
            this.verifySecQtnsForm.controls.answer.setValue('');
            const secQtn = sessionStorage.getItem('sq');
            this.tempsq = JSON.parse(secQtn);
            const tmpSq = JSON.parse(sessionStorage.getItem('selQtn'));
            const index = this.tempsq.findIndex(
              (s) => s.securityQuestionId === tmpSq.securityQuestionId
            );
            this.tempsq.splice(index, 1);
            sessionStorage.setItem('sq', JSON.stringify(this.tempsq));

            if (this.tempsq.length === 0) {
              this.forgotPasswordSecqtn.userId =
                +this.verifySecQtnsForm.controls.uId.value;
              this.forgotPasswordSecqtn.isFailureAttemptReached = true;
              this.forgotPasswordService
                .verifySecQtnsAns(this.forgotPasswordSecqtn)
                .subscribe((mResponse) => {
                  this.apiresResetPwd = mResponse;
                  if (this.apiresResetPwd.success === true) {
                    this.msgs = [];
                    this.msgs.push({
                      severity: 'error',
                      detail: this.apiresResetPwd.message,
                    });
                    this.btnDisabled = false;
                    sessionStorage.clear();
                    setTimeout(() => {
                      this.router.navigate(['/auth/forgot-password'], {
                        skipLocationChange: true,
                      });
                    }, 12000);
                  }
                  if (this.apiresResetPwd.success === false) {
                    this.msgs = [];
                    this.msgs.push({
                      severity: 'error',
                      detail: this.apiresResetPwd.errorMessages[0],
                    });
                    this.btnDisabled = false;
                    sessionStorage.clear();
                  }
                });
            }

            if (this.tempsq.length > 0) {
              this.msgs = [];
              this.msgs.push({
                severity: 'error',
                detail:
                  'Your Answer is Incorrect. Please Answer Your Next Question',
              });
              this.secQuestion = {};
              this.secQuestion =
                this.tempsq[Math.floor(Math.random() * this.tempsq.length)];
              sessionStorage.setItem(
                'selQtn',
                JSON.stringify(this.secQuestion)
              );

              this.verifySecQtnsForm.controls.uId.setValue(
                this.forgotPasswordSecqtn.userId
              );
              this.verifySecQtnsForm.controls.securityQuestionId.setValue(
                this.secQuestion.securityQuestionId
              );
              this.verifySecQtnsForm.controls.colName.setValue(
                this.secQuestion.colName
              );
              this.verifySecQtnsForm.controls.securityQuestionId.setValue(
                this.secQuestion.securityQuestionId
              );
              this.verifySecQtnsForm.controls.securityQuestionText.setValue(
                this.secQuestion.securityQuestion
              );
              this.verifySecQtnsForm.controls.answer.reset();
            }
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  ngOnInit() {
    this.verifyEmailForm = this.formBuilder.group({
      email: ['', [Validators.required]],
    });

    this.sId = sessionStorage.getItem('sId');

    this.securityQuestion = sessionStorage.getItem('secQtn');

    this.verifySecQtnsForm = this.formBuilder.group({
      answer: ['', [Validators.required]],
      uId: [''],
      colName: [''],
      securityQuestionId: [''],
      securityQuestionText: [''],
      btndis: [''],
    });
  }
}
