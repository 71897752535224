import { Component, OnInit } from '@angular/core';
import { NavMenuService } from '../../nav-menu/nav-menu.service';
import { Message } from 'primeng/api';
import { RequestErrors } from 'src/app/common/utils';
import { Router } from '@angular/router';
import { FactLidoUserEvents } from 'src/app/notifications/model/notifications.model';
import { NotificationService } from 'src/app/notifications/service/notification-service.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  itemCount = 0;
  items: FactLidoUserEvents[];
  siteId = Number(sessionStorage.getItem('siteId'));
  msgs: Message[] = [];
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private navMenuService: NavMenuService
  ) {}

  ngOnInit() {
    this.getList();
    this.navMenuService.updateAlerts$.subscribe(() => this.getList());
  }

  onEventClick(userEvent: FactLidoUserEvents) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this.router.navigate([
          '/layout/notifications-list',
          userEvent.userEventId,
        ])
      );
  }

  getList() {
    this.notificationService
      .getHeaderNotificationList('Notification', this.siteId, 5)
      .subscribe(
        (data) => {
          this.items = data;
          if (this.items.length > 0 && this.items[0] != undefined) {
            this.itemCount = this.items[0].nCount;
          } else {
            this.itemCount = 0;
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }
}
