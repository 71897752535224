<div class="datatable">
    <p-table #dt [columns]="cols" [value]="trialUsers" dataKey="userId" editMode="row">
        <ng-template pTemplate="caption">
            <div class="divline"></div>
            <div class="wdsrch">
                Search Existing Site User
                <div class="p-inputgroup mb-3">
                    <input type="text" class="form-control" pInputText placeholder="User Name"
                        (input)="dt.filterGlobal($event.target.value, 'contains')">
                    <span class="p-inputgroup-addon" id="basic-addon2"><i class="fa fa-search"></i></span>
                </div>
            </div>
            <div *ngIf="needPi" style="text-align: left;">
                Select a principal investigator to create a trial.
            </div>
            <div class="chip-row">
                <div *ngFor="let user of savedTrialUsers">
                    <button type="button" class="chip-button" (click)="onRemoveTrialUser(user)"> {{user.displayName |
                        truncate:20}} {{user.userRoleName | trialrole }}<i class="pi pi-times"></i></button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns; let i = index;">
                    {{col.header}}
                </th>
                <th style="text-align: center;">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex" let-user>
            <tr [pEditableRow]="user">
                <td>
                    {{user.displayName}}
                </td>
                <td>
                    {{user.userTypeName}}
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="dd95" *ngIf="user.userTypeName === 'External User'; else notExternal">
                                <p-dropdown overlayOptions="" zIndex="1000" dropdownIcon="fa fa-caret-down"
                                    [options]="externalUserRoles" required="true" placeholder="Trial Role"
                                    dataKey="value" optionLabel="label" [(ngModel)]="user.userRole"
                                    [style]="{'width':'100%'}"></p-dropdown>
                            </div>
                            <ng-template #notExternal>
                                <div class="dd95">
                                    <p-dropdown dropdownIcon="fa fa-caret-down" [options]="userRoleItems"
                                        required="true" placeholder="Trial Role" dataKey="value" optionLabel="label"
                                        [(ngModel)]="user.userRole" [style]="{'width':'100%'}"></p-dropdown>
                                </div>

                            </ng-template>

                        </ng-template>
                        <ng-template pTemplate="output">
                            {{user.userRoleName}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="text-align:center">
                    <button *ngIf="!editing" type="button" pInitEditableRow class="btn btn-inline-edit"
                        (click)="onRowEditInit(user, ri)"><i id="menubutton" class="pi pi-pencil"></i></button>
                    <div class="edit-buttons">
                        <button *ngIf="editing" type="button" [disabled]="user.userRole.value === 0" pSaveEditableRow
                            class="btn btn-inline-save" style="margin-right: .5em" (click)="onRowEditSave(user)"><i
                                id="menubutton" class="pi pi-check"></i></button>
                        <button *ngIf="editing" type="button" pCancelEditableRow class="btn btn-inline-cancel"
                            (click)="onRowEditCancel(ri)"><i id="menubutton" class="pi pi-times"></i></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <div style="height: 70px;" class="my-5">
            </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            {{emptyRecordMsg}}
        </ng-template>
    </p-table>
</div>