import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Trial } from 'src/app/trial/model/trial.model';
import { ConfirmationService, Message } from 'primeng/api';
import { SponsorDropdown } from 'src/app/sponsor/model/sponsordropdown.model';
import { TrialStatusDropDown } from 'src/app/trial/model/trialstatusdropdown.model';
import { SponsorLibraryService } from 'src/app/sponsor/service/sponsorlibrary.service';
import { TrialService } from 'src/app/trial/service/trial.service';
import { AuthUser } from 'src/app/users/model/authuser.model';
import { AppConstants } from 'src/app/common/app.constants';
import {
  RequestErrors,
  getTrialStatus,
  updateCurrentUserRole,
} from 'src/app/common/utils';
import { forkJoin, of } from 'rxjs';
import { TrialUserService } from 'src/app/trial/service/trialuser.service';

import { mergeMap } from 'rxjs/operators';
import { TrialUser } from '../model/trialuser.model';

export interface SponsorItem {
  label: string;
  value: SponsorDropdown;
  sponsorId?: number;
  sponsorName?: string;
  sponsorLogo?: string;
}
@Component({
  selector: 'app-edit-trial',
  templateUrl: './edit-trial.component.html',
  styleUrls: ['./edit-trial.component.scss'],
  providers: [ConfirmationService],
})
export class EditTrialComponent implements OnInit {
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() trialId: number;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  form: UntypedFormGroup;
  trial: Trial;
  titleLabel = '';
  needPi = false;
  msgs: Message[] = [];
  apires: AuthUser;
  trialUsers: TrialUser[];
  selectedTrialUser: TrialUser;
  editedTrialUsers: TrialUser[] = [];
  filteredSponsors: SponsorDropdown[];
  sponsorList: SponsorItem[];
  trialStatusSelections: TrialStatusDropDown[];
  siteId = parseInt(sessionStorage.getItem('siteId'));
  siteName = sessionStorage.getItem('siteName');
  userId = Number(sessionStorage.getItem('userId'));
  loadAddUserLanding = false;
  loadAddTrialUserList = false;
  editTrialComp = false;
  authErrorString: string;
  showConfirmDialog = false;
  currentTrialRoleId = 0;
  confirmTitleLabel = '';
  allowEdits = false;
  allowSave = false;
  otherSponsor = false;
  otherSponsorSelection: SponsorDropdown;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private sponsorLibraryService: SponsorLibraryService,
    private trialService: TrialService,
    private trialUserService: TrialUserService
  ) {}

  confirmChangeStatus(statusName: string) {
    if (statusName === 'Closed') {
      this.confirmTitleLabel = 'Are you sure you want to close this trial?';
    } else if (statusName === 'Deleted') {
      this.confirmTitleLabel = 'Are you sure you want to delete this trial?';
      if (this.trial.actionItemCount > 0) {
        this.confirmTitleLabel += ' There are open action items';
      }
    } else if (statusName === 'Archived') {
      this.confirmTitleLabel = 'Are you sure you want to archive this trial?';
    }
    this.showConfirmDialog = true;
  }

  confirmCancel() {
    // Whe you get here, you have checked for PI
    this.confirmationService.confirm({
      message: 'You have unsaved changes. Are you sure you want to cancel?',
      header: 'Confirmation',
      accept: () => {
        // Yes they want to leave without saving
        this.closeModal.emit(false);
      },
    });
  }

  // When the user cancels
  // TODO: Make sure user can throw away changes if no PI in edited trial users
  closeEditTrial(refresh = false) {
    // if the form is dirty or editedTrialUsers.length > 0 then ask are you sure
    const needPi = this.NeedAPi();
    if (needPi === true) {
      this.msgs = [
        {
          severity: 'warn',
          detail: 'Select a primary investigator to leave',
        },
      ];
      return;
    }
    if (
      this.form.dirty ||
      (this.editedTrialUsers !== undefined && this.editedTrialUsers.length > 0)
    ) {
      this.confirmCancel();
    } else {
      this.closeModal.emit(refresh);
    }
  }

  showAddTrialUserList() {
    this.editTrialComp = false;
    this.needPi = this.NeedAPi();
    this.loadAddUserLanding = true;
  }

  closeModals(refresh = false) {
    this.msgs = [];
    // For right now, the only thing I need to refresh is the trial
    this.loadAddUserLanding = false;
    this.editTrialComp = true;
    if (refresh) {
      this.trialUserService
        .getTrialUsers(this.trialId, this.siteId, true)
        .subscribe(
          (data) => {
            this.trialUsers = data;
          },
          (error) => {
            this.msgs = RequestErrors(error).map((x) => {
              return { severity: 'warn', detail: x };
            });
          }
        );
    }
  }

  NeedAPi() {
    // Return true if you need a pi, false if you don't
    let currentTrialUsers = [...this.trialUsers];
    if (this.editedTrialUsers !== undefined) {
      currentTrialUsers = this.trialUsers.map((x) => {
        const newUser = this.editedTrialUsers.find(
          (y) => x.userId === y.userId
        );
        if (newUser !== undefined) {
          return newUser;
        } else {
          return x;
        }
      });
    }
    const piUser = currentTrialUsers.find((x) => x.userRoleId === 4);
    return piUser === undefined ? true : false;
  }

  onSubmit() {
    // Check that there is a PI before saving
    this.msgs = [];
    const needPi = this.NeedAPi();
    if (needPi === true) {
      this.msgs = [
        {
          severity: 'warn',
          detail: 'Select a primary investigator to save changes',
        },
      ];

      return;
    }

    sessionStorage.setItem('trialName', this.form.controls.trialName.value);
    const newTrial = new Trial(this.trial);
    newTrial.trialId = this.trialId;
    newTrial.siteId = Number(sessionStorage.getItem('siteId'));
    newTrial.trialName = this.form.controls.trialName.value;
    newTrial.protocolNumber = this.form.controls.protocolNumber.value;

    newTrial.isTokenChangeNeeded = false;
    newTrial.siteNumber = this.form.controls.siteNumber.value;
    newTrial.projectId = this.form.controls.projectId.value;
    newTrial.sponsorId = this.form.controls.sponsorSelect.value.sponsorId;
    if (this.otherSponsor) {
      newTrial.sponsorName = this.form.controls.sponsorName.value;
    } else {
      newTrial.sponsorName = this.form.controls.sponsorSelect.value.sponsorName;
    }
    newTrial.trialStatusId = this.form.controls.statusName.value.trialStatusId;
    this.trialService
      .editTrial(newTrial)
      .pipe(
        mergeMap(() => {
          if (
            this.editedTrialUsers !== undefined &&
            this.editedTrialUsers.length > 0
          ) {
            return this.trialUserService.editTrialUser(this.editedTrialUsers);
          } else {
            return of({ success: true, errorMessages: [] });
          }
        })
      )
      .subscribe(
        (messageResponse) => {
          if (messageResponse.success === true) {
            // Update this so it stays current
            const loggedInTrialUser = this.editedTrialUsers.find(
              (x) => x.userId === this.userId
            );
            if (loggedInTrialUser !== undefined) {
              // I am changing my own trial role
              updateCurrentUserRole(loggedInTrialUser.userRoleId);
            }

            this.closeModal.emit(true);
          } else {
            this.msgs = [
              {
                severity: 'error',
                detail:
                  'onsubmit, success: ' +
                  messageResponse.errorMessages[0].toString(),
              },
            ];
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  removeTrialUser(trialUser: TrialUser) {
    this.selectedTrialUser = trialUser;
    this.trialUserService
      .removeTrialUser(trialUser.trialUserId)
      .pipe(
        mergeMap(() => {
          const removingCurrentUser = trialUser.userId === this.userId;
          if (removingCurrentUser === true) {
            // I am changing my own trial role
            updateCurrentUserRole(0);
          }
          return this.trialUserService.getTrialUsers(
            this.trialId,
            this.siteId,
            true
          );
        })
      )
      .subscribe(
        (trialUsers: TrialUser[]) => {
          this.trialUsers = trialUsers;

          // See if I need to update my trialuserrole
          // Remove this user from the editedTrialUsersArray
          if (this.editedTrialUsers.length > 0) {
            const idx = this.editedTrialUsers.findIndex(
              (x) => x.userId === trialUser.userId
            );
            if (idx !== -1) {
              this.editedTrialUsers.splice(idx, 1);
            }
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return {
              severity: 'warn',
              detail: x,
            };
          });
        }
      );
  }

  saveTrialUsers(trialUsers: TrialUser[]) {
    this.editedTrialUsers = [...trialUsers];
  }

  ngOnInit() {
    this.editTrialComp = true;
    this.initForm();
    this.getData();
  }

  initForm() {
    this.form = this.formBuilder.group({
      trialName: ['', [Validators.required]],
      protocolNumber: ['', [Validators.required]],
      siteNumber: ['', [Validators.required]],
      sponsorSelect: ['', Validators.required],
      sponsorName: ['', [Validators.required, Validators.minLength(3)]],
      statusName: ['', [Validators.required]],
      projectId: [''],
    });
    this.form.controls['sponsorSelect'].valueChanges.subscribe((val) => {
      if (val !== undefined) {
        this.otherSponsor = val.sponsorName === 'Other';
        if (this.otherSponsor) {
          this.form.controls['sponsorName'].setValue('');
        } else {
          this.form.controls['sponsorName'].setValue(val.sponsorName);
        }
        this.form.updateValueAndValidity();
      }
    });
  }

  patchForm(): void {
    const selectedSponsor = this.sponsorList.find(
      (x) => x.sponsorId === this.trial.sponsorId
    );

    const selectedStatus = this.trialStatusSelections.find(
      (x) => x.trialStatusId === this.trial.trialStatusId
    );
    this.form.patchValue({
      trialName: this.trial.trialName,
      protocolNumber: this.trial.protocolNumber,
      siteNumber: this.trial.siteNumber,
      projectId: this.trial.projectId,
      statusName: selectedStatus,
      sponsorName: this.trial.sponsorName,
      sponsorSelect: selectedSponsor,
    });
    this.form.patchValue({ sponsorName: this.trial.sponsorName });

    // If the trial is closed, they can only change the status.
    // If the trial is archived, they can't do anything
    if (this.trial.trialStatusId === 5 || this.trial.trialStatusId === 7) {
      this.form.controls.trialName.disable();
      this.form.controls.protocolNumber.disable();
      this.form.controls.siteNumber.disable();
      this.form.controls.projectId.disable();
      this.form.controls.sponsorName.disable();
      this.form.controls.sponsorSelect.disable();
    }
    if (this.trial.trialStatusId === 5) {
      this.form.controls.statusName.disable();
    }
  }

  getData() {
    const getTrial = this.trialService.getTrialDetailsforEdit(
      this.trialId,
      this.siteId
    );
    const getTrialUsers = this.trialUserService.getTrialUsers(
      this.trialId,
      this.siteId,
      true
    );
    const getStatusDropdown = this.trialService.getTrialStatusDropDown();
    const getSponsors = this.sponsorLibraryService.getSponsorList();

    forkJoin([
      getTrial,
      getTrialUsers,
      getStatusDropdown,
      getSponsors,
    ]).subscribe(
      (result) => {
        this.trial = result[0];
        this.allowEdits = this.trial.trialStatusId === 2;
        if (this.trial.trialStatusId === 5) {
          this.msgs = [
            {
              severity: 'warn',
              detail:
                'Please contact LynK Support through the Help Feature for assistance with Archived Trials',
            },
          ];
        }
        this.allowSave =
          this.trial.trialStatusId === 2 || this.trial.trialStatusId === 7;
        this.trialUsers = result[1];
        const statusList = result[2];
        this.titleLabel = `Edit ${this.trial.trialName}`;
        this.filteredSponsors = result[3];
        const idx = this.filteredSponsors.findIndex(
          (x) => x.sponsorName === 'Other'
        );
        this.otherSponsorSelection = this.filteredSponsors[idx];

        this.otherSponsor =
          this.trial.sponsorId === this.otherSponsorSelection.sponsorId;

        this.filteredSponsors.splice(idx, 1);
        this.filteredSponsors.push(this.otherSponsorSelection);
        this.sponsorList = this.filteredSponsors.map((x) => {
          return {
            label: x.sponsorName,
            value: x,
            sponsorId: x.sponsorId,
            sponsorLogo: x.sponsorLogo,
            sponsorName: x.sponsorName,
          };
        });

        let includeStatus: number[] = [];

        const trialStatusString = getTrialStatus(this.trial.trialStatusId);
        if (trialStatusString === 'Active') {
          includeStatus = [2, 6, 7];
          this.trialStatusSelections = statusList.filter((x) =>
            includeStatus.includes(x.trialStatusId)
          );
        } else if (trialStatusString === 'Closed') {
          includeStatus = [2, 5];
          this.trialStatusSelections = statusList.filter((x) =>
            includeStatus.includes(x.trialStatusId)
          );
        } else {
          this.trialStatusSelections = statusList;
        }

        this.patchForm();
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  onSelectTrialStatus(event) {
    if (event.value.statusName === 'Closed' && this.trial.actionItemCount > 0) {
      this.msgs = [
        {
          severity: 'warn',
          detail: 'Respond to all action items before closing this trial',
        },
      ];
      this.form.controls.statusName.setValue({
        trialStatusId: this.trial.trialStatusId,
        statusName: this.trial.trialStatusName,
      });
      return;
    }

    if (event.value.statusName !== 'Active') {
      this.confirmChangeStatus(event.value.statusName);
    }
  }

  confirmTrialStatus(answer: boolean) {
    this.showConfirmDialog = false;
    if (answer === false) {
      this.form.controls.statusName.setValue({
        trialStatusId: this.trial.trialStatusId,
        statusName: this.trial.trialStatusName,
      });
    }
  }
}
