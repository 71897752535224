import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { TermsOfUseComponent } from './terms-of-use.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    TermsOfUseComponent

  ],
  exports: [
    TermsOfUseComponent
  ]
})
export class TermsOfUseModule {

 }
