<div class="dropdown">
  <a href="#" id="profilemenu" class="dropdown-toggle" appcustomdropdown data-bs-toggle="dropdown" aria-haspopup="true"
    aria-expanded="false">
    <fa name="user-circle marright"></fa><span class="text-truncate">{{displayName}}</span>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="profilemenu">
      <button class="dropdown-item" routerLink="/layout/profile">
        <fa name="user-circle" class="marright"></fa>My Profile
      </button>
      <button class="dropdown-item" *ngIf="showUserManagement" routerLink="/layout/users/user-list">
        <fa name="user" class="marright"></fa>
        Site User Management
      </button>
      <button class="dropdown-item" (click)="logout()">
        <fa name="sign-out" class="marright"></fa>Logout
      </button>
    </div>

  </a>
</div>