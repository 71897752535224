import { deepCopy } from '../../common/utils';
import { BinderDocumentType } from './document-add.model';

export class Folder {
  folderType: BinderDocumentType; // has to be sitedocument, operationsbinder, regulatorybinder, subjectdocument
  folderId?: number;
  folderName?: string;
  isDeleted?: boolean;
  isArchived?: boolean;
  isSubFolder?: boolean;
  parentFolderId?: number;
  parentFolderName?: string;
  remarks?: boolean;
  siteId?: number;
  trialId?: number;
  subjectId?: number;
  createdByUserId?: number;
  createdOn?: Date;
  modifiedByUserId?: number;
  modifiedOn?: Date;

  constructor(defaultValues: Partial<Folder>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }

  clone() {
    return new Folder(deepCopy(this));
  }
}
