import { Params } from '@angular/router';
import { BinderDocumentType } from '../documents/model/document-add.model';
import { DocumentComponentInfo } from '../documents/model/document-component-info.model';
import { MenuItem } from 'primeng/api';

//TODO: this is here temporarily until all components use the enum type
export function getWorkflowTypeString(binderDocumentType: BinderDocumentType) {
  switch (binderDocumentType) {
    case BinderDocumentType.operationsbinder:
      return 'o';
    case BinderDocumentType.regulatorybinder:
      return 'r';
    case BinderDocumentType.sitedocument:
      return 'site';
    case BinderDocumentType.subjectdocument:
      return 'sub';
    default:
      return '';
  }
}

// TODO: Implement a more typescript way of doing this
export function sortDocumentList(
  documentList: any[],
  docType: BinderDocumentType,
  sortField: string,
  sortOrder: number
): any[] {
  const docFolders: any[] = documentList.filter((x) => x.isDocument === false);
  const docFiles: any[] = documentList.filter((x) => x.isDocument === true);
  if (sortField === 'modifiedOn') {
    docFolders.sort((x, y) => {
      if (sortOrder === 1) {
        return x.modifiedOn > y.modifiedOn
          ? -1
          : x.modifiedOn < y.modifiedOn
          ? 1
          : 0;
      } else {
        return y.modifiedOn > x.modifiedOn
          ? -1
          : y.modifiedOn < x.modifiedOn
          ? 1
          : 0;
      }
    });
    docFiles.sort((x, y) => {
      if (sortOrder === 1) {
        return x.modifiedOn > y.modifiedOn
          ? -1
          : x.modifiedOn < y.modifiedOn
          ? 1
          : 0;
      } else {
        return y.modifiedOn > x.modifiedOn
          ? -1
          : y.modifiedOn < x.modifiedOn
          ? 1
          : 0;
      }
    });
  } else {
    let folderField = '';
    let docField = '';
    switch (docType) {
      case BinderDocumentType.operationsbinder:
        folderField = 'operationBinderFolderName';
        docField = 'operationBinderDocsName';
        break;
      case BinderDocumentType.regulatorybinder:
        (folderField = 'regulatoryBinderFolderName'),
          (docField = 'regulatoryBinderDocsName');
        break;
      case BinderDocumentType.sitedocument:
        folderField = 'siteDocumentFolderName';
        docField = 'siteDocumentName';
        break;
      case BinderDocumentType.subjectdocument:
        (folderField = 'subjectRecordsFolderName'),
          (docField = 'subjectDocumentName');
        break;
      default:
        console.error('Document sort called with unknown binder doctype');
        return documentList;
    }

    docFolders.sort((x, y) => {
      if (sortOrder === 1) {
        if (x[folderField] < y[folderField]) {
          return -1;
        }
      } else {
        if (x[folderField] > y[folderField]) {
          return -1;
        }
      }
      return 0;
    });
    docFiles.sort((x, y) => {
      if (sortOrder === 1) {
        if (x[docField] < y[docField]) {
          return -1;
        }
      } else {
        if (x[docField] > y[docField]) {
          return -1;
        }
      }
      return 0;
    });
  }
  return docFolders.concat(docFiles);
}

export function getBinderDocTypeString(binderDocumentType: BinderDocumentType) {
  switch (binderDocumentType) {
    case BinderDocumentType.operationsbinder:
      return 'operationsbinder';
    case BinderDocumentType.regulatorybinder:
      return 'regulatorybinder';
    case BinderDocumentType.sitedocument:
      return 'sitedocument';
    case BinderDocumentType.subjectdocument:
      return 'subjectdocument';
    default:
      return 'Document';
  }
}

export function getBinderDocLabel(binderDocumentType: BinderDocumentType) {
  switch (binderDocumentType) {
    case BinderDocumentType.operationsbinder:
      return 'Operations Binder Document';
    case BinderDocumentType.regulatorybinder:
      return 'Regulatory Binder Document';
    case BinderDocumentType.sitedocument:
      return 'Site Document';
    case BinderDocumentType.subjectdocument:
      return 'Subject Record Document';
    default:
      return 'Document';
  }
}

export function getBinderLabel(binderDocumentType: BinderDocumentType) {
  switch (binderDocumentType) {
    case BinderDocumentType.operationsbinder:
      return 'Operations Binder';
    case BinderDocumentType.regulatorybinder:
      return 'Regulatory Binder';
    case BinderDocumentType.sitedocument:
      return 'Site Documents';
    case BinderDocumentType.subjectdocument:
      return 'Subject Records';
    default:
      return 'Document';
  }
}

export function createDocumentBreadCrumbs(
  folderId: number,
  queryParams: Params,
  componentRoot: string
): DocumentComponentInfo {
  // Get the folder Id, the folder name, the menuitems, archive switch, myparent state
  const menuItems: MenuItem[] = [];
  const atRoot = false;
  const folderName = atRoot ? '' : queryParams['fName'];
  const showArchivedFolders = atRoot
    ? false
    : queryParams['showArchived'] === 'true';
  const parentIsArchived = atRoot
    ? false
    : queryParams['showArchived'] === 'true';

  const folderpath = sessionStorage.getItem('folderpath').slice(1);
  const parentFolders = folderpath.split('/').map((x) => {
    const pathway = x.split(':');
    return {
      folderId: pathway[0],
      folderName: pathway[1],
      isArchived: pathway[2],
    };
  });

  // If the user has gotten here by clicking on an ancestor folder, idx will not be -1
  const idx = parentFolders.findIndex(
    (x) => x.folderId === folderId.toString()
  );
  if (idx !== null && idx !== parentFolders.length - 1) {
    // This means you went up the chain
    parentFolders.splice(idx + 1);
    // Now fix the folderpath in session storage
    let newFolderPath = '';
    parentFolders.forEach((x) => {
      newFolderPath =
        newFolderPath + `/${x.folderId}:${x.folderName}:${x.isArchived}`;
    });
    sessionStorage.setItem('folderpath', newFolderPath);
  }

  parentFolders.forEach((x) => {
    const qParams = {
      fname: x.folderName,
    };
    const item: MenuItem = {
      label: x.folderName,
      routerLink: `${componentRoot}/${x.folderId}`,
      queryParams: qParams,
    };
    menuItems.push(item);
  });
  return {
    folderId: folderId,
    folderName: folderName,
    menuItems: menuItems,
    showArchivedFolders: showArchivedFolders,
    parentIsArchived: parentIsArchived,
  };
}
