export class VendorUser {
  vendorUserId?: number;
  vendorId?: number;
  vendorName?: string; // From vendor
  trialId?: number;
  siteId?: number;
  vendorLogo?: string; // From the vendor
  vendorCategoryId?: number;
  vendorCategoryName?: string;
  userId?: number; // filled in on the backend
  vendorUserName?: string;
  vendorPassword?: string;
  swaVendor?: boolean; // From vendor
  vendorType?: number; // From vendor
  vendorUrl?: string; // From vendor
  ssoUserId?: string; // From the user record for creating the vendor link
  vendorAppId?: string; // from the vendor record for creating the vendor link
  ssoAppUrl?: string; // For the edit screen
  constructor(defaultValues: Partial<VendorUser>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }
}
