import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'GetfirstLetter',
})
export class GetfirstLetterPipe implements PipeTransform {
  transform(val: string): string {
    var x;
    if (val != null && val != '') {
      x = val
        .split(' ')
        .map((x) => x[0])
        .join('');
    }

    return x;
  }
}
