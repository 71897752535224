<div class="b_container messages">
  <div class="rtheader">
    {{pageTitle}}
    <div *ngIf="loading===true" class="closesidebar">X</div>
    <div *ngIf="loading!==true" class="closesidebar" (click)="closeForm()">X</div>
  </div>
  <div class="rtcontainer userform">
    <p-messages [value]="msgs"></p-messages>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12">

          <div class="row my-3 align-items-center">
            <label class="col-2 col-form-label">Email</label>
            <div class="col-8">
              <input pInputText class="form-control" type="email" placeholder="Email Address" formControlName="email" />
              <div class="alert alert-danger"
                *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched) ">
                Enter a valid Email ID.
              </div>
            </div>
          </div>
          <div class="row my-3 align-items-center">
            <label class="col-2 col-form-label">Legal Name</label>
            <div class="col-8">
              <input pInputText class="form-control" type="text" placeholder="Legal Name" formControlName="shortName" />
              <div class="alert alert-danger"
                *ngIf="form.controls['shortName'].invalid && form.controls['shortName'].dirty">
                Legal Name must be at least 3 characters long
              </div>
            </div>
          </div>
          <div class="row my-3 align-items-center">
            <label class="col-2 col-form-label">Display Name</label>
            <div class="col-8">
              <input pInputText class="form-control" type="text" formControlName="displayName"
                placeholder="Display Name" />
              <div class="alert alert-danger"
                *ngIf="form.controls['displayName'].invalid && form.controls['displayName'].dirty">
                Display Name must be at least 3 characters long
              </div>
            </div>
          </div>

          <div class="row my-3 align-items-center">
            <label class="col-2 col-form-label">User Type</label>
            <div class="col-8 dd95">
              <p-dropdown dropdownIcon="fa fa-caret-down" [options]="userTypeSelections" formControlName="userTypeId"
                optionLabel="userTypeName" placeholder="User Type"></p-dropdown>
            </div>
          </div>
          <div class="col-4">
            <div class="forminfo">
              <ul>
                <li><span class="mandatory">*</span> fields are mandatory.</li>
              </ul>
            </div>
          </div>
          <div class="btnnav">
            <button type="submit" [disabled]="!form.valid || (form.valid && btnCreate==='Processing..')"
              class="btn btn-primary marright">{{btnCreate}}

              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            </button>
            <button type="button" [disabled]="loading===true" (click)="closeForm()"
              class="btn btn-default">Cancel</button>
          </div>
        </div>


      </div>

    </form>

  </div>
</div>