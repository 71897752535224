import { deepCopy } from 'src/app/common/utils';

export class OperationsBinderFolder {
  operationBinderFolderId?: number;
  operationBinderFolderName?: string;
  isDeleted: boolean;
  isArchived: boolean;
  parentFolderId?: number;
  siteId?: number;
  trialId?: number;
  remarks?: boolean;

  constructor(defaultValues: Partial<OperationsBinderFolder>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }

  clone() {
    return new OperationsBinderFolder(deepCopy(this));
  }
}
