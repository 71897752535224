import { Component, OnInit } from '@angular/core';
import { FaqService } from '../service/faq.service';
import { HelpPriority } from '../model/HelpPriority';
import { Router } from '@angular/router';
import { EncrDecrService } from 'src/app/EncrDecr/encr-decr.service';
import { MenuItem, Message, SelectItem } from 'primeng/api';
import { RequestErrors, makeBreadCrumbItemsHelp } from 'src/app/common/utils';
import { NavMenuService } from 'src/app/layout/components/nav-menu/nav-menu.service';
import { HelpDetails } from '../model/HelpDetails';

@Component({
  selector: 'app-help-request-list',
  templateUrl: './help-request-list.component.html',
  styleUrls: ['./help-request-list.component.scss'],
})
export class HelpRequestListComponent implements OnInit {
  pMenuItems: MenuItem[] = [];
  priorityReason: HelpPriority[];
  faqLogId: number;
  cols = [
    { field: 'helpDetailsId', header: 'Request ID' },
    { field: 'helpDescription', header: 'Details' },
    { field: 'helpPriorityId', header: 'Priority' },
    { field: 'helpReasonName', header: 'Reason' },
  ];
  msgs: Message[] = [];
  siteId = Number(sessionStorage.getItem('siteId'));
  helpDetails: HelpDetails[];
  priorityNameAppDrpdwn: SelectItem[] = [];
  priorityNameDrpdwn: SelectItem[];
  statusAppDrpdwn: SelectItem[];

  constructor(
    private router: Router,
    private faqService: FaqService,
    private encrDecr: EncrDecrService,
    private navMenuService: NavMenuService
  ) {
    this.gethelpPriority();
    this.getHelpDetails();
  }

  ngOnInit() {
    this.navMenuService.trialState$.subscribe((trialDetails) => {
      this.pMenuItems = makeBreadCrumbItemsHelp(false, trialDetails.trialSelected);
      this.pMenuItems.push({
        label: 'My Requests',
      });
    });

    this.priorityNameAppDrpdwn = [];

    this.statusAppDrpdwn = [
      { label: 'Open', value: 'Open' },
      { label: 'Reviewed', value: 'Reviewed' },
      { label: 'Closed', value: 'Closed' },
    ];
  }

  helpTopicsNav() {
    this.router.navigate(['/layout/lido-training/help']);
  }

  addRequestNav() {
    const reqTypeValue = this.encrDecr.encryptData('AddRequest');
    this.router.navigate(['/layout/faq/addrequest'], {
      queryParams: { reqType: reqTypeValue },
    });
  }

  editRequest(helpDetails: HelpDetails) {
    const reqTypeValue =
      helpDetails.phoneNumber !== ''
        ? this.encrDecr.encryptData('CallMe')
        : this.encrDecr.encryptData('AddRequest');
    this.router.navigate(
      ['/layout/faq/editrequest', helpDetails.helpDetailsId],
      {
        queryParams: { reqType: reqTypeValue },
      }
    );
  }

  gethelpPriority() {
    this.faqService.getHelpPriority().subscribe(
      (priorityReason) => {
        this.priorityReason = priorityReason;
        for (const item of this.priorityReason) {
          const priorityNameDrpdwn: SelectItem = {
            label: item.helpPriorityName,
            value: item.helpPriorityName,
          };
          this.priorityNameAppDrpdwn.push(priorityNameDrpdwn);
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  getHelpDetails() {
    this.faqService.getHelpDetailList().subscribe(
      (request) => {
        this.helpDetails = request;
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
