import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Message } from 'primeng/api';
import { ApiResponse } from 'src/app/core/apiresponse';
import { RequestErrors } from 'src/app/common/utils';
import { BinderDocumentType } from 'src/app/documents/model/document-add.model';
import { AuthService } from '../../auth/auth.service';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotificationService } from '../service/notification-service.service';
import { FactLidoUserEvents } from '../model/notifications.model';
import { OperationsBinderService } from 'src/app/operations-binder/service/operationsbinder.service';
import { RegulatoryBinderService } from 'src/app/regulatory-binder/service/regulatorybinder.service';
import { SiteDocumentService } from 'src/app/site-documents/service/sitedocument.service';
import { SubjectDocumentService } from 'src/app/subject/service/subjectdocument.service';

export interface DocumentViewerInterface {
  docPath: string;
  docName: string;
  docExt: string;
}
@Component({
  selector: 'app-workflow-routing-notification',
  templateUrl: './workflow-routing-notification.component.html',
  styleUrls: ['./workflow-routing-notification.component.scss'],
})
export class WorkflowRoutingNotificationComponent implements OnInit {
  @Input() userEvent: FactLidoUserEvents;
  @Input() showApproval = false;
  @Output() displayDocument = new EventEmitter<DocumentViewerInterface>();
  @Output() closeModal = new EventEmitter<boolean>();

  documentType: BinderDocumentType;
  siteName = '';
  trialName = '';
  folderName = '';
  docName = '';
  docExt = '';
  docPath = '';
  siteDocType: BinderDocumentType = BinderDocumentType.sitedocument;
  form: UntypedFormGroup;
  msgs: Message[];
  loadDocumentViewer = false;
  passwordVisible = false;
  isSiteDocument = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private operationsBinderService: OperationsBinderService,
    private regulatoryBinderService: RegulatoryBinderService,
    private siteDocumentService: SiteDocumentService,
    private subjectDocumentService: SubjectDocumentService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.getData();

    this.form = this.formBuilder.group({
      siteName: [this.siteName],
      trialName: [this.trialName],
      opName: [this.folderName],
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  getData() {
    switch (this.userEvent.eventTypeId) {
      case 15:
      case 30:
      case 35:
        this.operationBinder(this.userEvent);
        break;
      case 16:
      case 32:
      case 36:
        this.regulatoryBinder(this.userEvent);
        break;
      case 17:
      case 33:
      case 37:
        this.subjectRecordBinder(this.userEvent);
        break;
      case 18:
      case 34:
      case 38:
        this.isSiteDocument = true;
        this.siteDocBinder(this.userEvent);
        break;
      default:
        break;
    }
  }

  operationBinder(userEvent: FactLidoUserEvents) {
    this.operationsBinderService
      .getWorkflowDetails(userEvent.workFlowRoutingId)
      .subscribe(
        (docData) => {
          this.siteName = docData.siteName;
          this.trialName = docData.trialName;
          this.folderName = docData.operationBinderFolderName;
          this.docName = docData.operationBinderDocsName;
          this.docExt = docData.docType;
          this.documentType = BinderDocumentType.operationsbinder;
          this.docPath = docData.docPath;
          this.form.patchValue({
            trialName: docData.trialName,
            siteName: docData.siteName,
          });
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  regulatoryBinder(userEvent: FactLidoUserEvents) {
    this.regulatoryBinderService
      .getWorkflowDetails(userEvent.workFlowRoutingId)
      .subscribe(
        (docData) => {
          this.siteName = docData.siteName;
          this.trialName = docData.trialName;
          this.documentType = BinderDocumentType.regulatorybinder;
          this.docName = docData.regulatoryBinderDocsName;
          this.docPath = docData.docPath;
          this.docExt = docData.docType;
          this.folderName = docData.regulatoryBinderFolderName;
          this.form.patchValue({
            trialName: docData.trialName,
            siteName: docData.siteName,
          });
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  siteDocBinder(userEvent: FactLidoUserEvents) {
    this.siteDocumentService
      .getWorkflowDetails(userEvent.workFlowRoutingId)
      .subscribe(
        (docData) => {
          this.siteName = docData.siteName;
          this.trialName = '';
          this.documentType = BinderDocumentType.sitedocument;
          this.docName = docData.siteDocumentName;
          this.docPath = docData.docPath;
          this.docExt = docData.docType;
          this.folderName = docData.siteDocumentFolderName;
          this.form.patchValue({
            siteName: docData.siteName,
          });
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  subjectRecordBinder(userEvent: FactLidoUserEvents) {
    this.subjectDocumentService
      .getWorkflowDetails(userEvent.workFlowRoutingId)
      .subscribe(
        (docData) => {
          this.siteName = docData.siteName;
          this.trialName = docData.trialName;
          this.documentType = BinderDocumentType.subjectdocument;
          this.docName = docData.subjectDocumentName;
          this.docPath = docData.docPath;
          this.folderName = docData.subjectRecordsFolderName;
          this.form.patchValue({
            trialName: docData.trialName,
            siteName: docData.siteName,
          });
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  onDocumentClick() {
    // You have to emit with document details
    this.displayDocument.emit({
      docPath: this.docPath,
      docExt: this.docExt,
      docName: this.docName,
    });
  }

  closeModals() {
    this.loadDocumentViewer = false;
  }

  onDismiss() {
    this.closeModal.emit(true);
  }

  onClick(val: number) {
    const isApproved = val === 2;

    if (isApproved) {
      if (
        this.form.controls['username'].invalid ||
        this.form.controls['password'].invalid
      ) {
        this.msgs = [
          {
            severity: 'warn',
            detail:
              'Enter a valid username and password to approve this document',
          },
        ];
        return;
      }
    }

    this.userEvent.eventStatusId = val;
    this.msgs = [];
    if (isApproved === true) {
      // first authenticate
      this.authService
        .authenticateUser(this.form.value.username, this.form.value.password)
        .pipe(
          mergeMap((apiResponse: ApiResponse) => {
            if (apiResponse.success === true) {
              return this.notificationService.updateDocumentNotification(
                this.userEvent
              );
            } else {
              return of<ApiResponse>({
                success: false,
                message:
                  'Authentication was unsuccessful. Reenter the username and password',
              });
            }
          })
        )
        .subscribe(
          (response: ApiResponse) => {
            if (response.success === true) {
              this.closeModal.emit(true);
            } else {
              this.msgs = [
                {
                  severity: 'failure',
                  detail: response.message,
                },
              ];
            }
          },
          (error) => {
            this.msgs = RequestErrors(error).map((x) => {
              return { severity: 'warn', detail: x };
            });
          }
        );
    } else {
      this.notificationService
        .updateDocumentNotification(this.userEvent)
        .subscribe(
          (response: ApiResponse) => {
            if (response.success === true) {
              this.closeModal.emit(true);
            } else {
              this.msgs = [
                {
                  severity: 'failure',
                  detail: 'There was an error rejecting alert',
                },
              ];
            }
          },
          (error) => {
            this.msgs = RequestErrors(error).map((x) => {
              return { severity: 'warn', detail: x };
            });
          }
        );
    }
  }
}
