import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { VendorService } from '../../vendors/service/vendor.service';
import { VendorCategory } from '../../vendors/model/vendorcategory.model';
import { VendorDropdown } from '../../vendors/model/vendornamedropdown.model';
import { EncrDecrService } from 'src/app/EncrDecr/encr-decr.service';
import { VendorUser } from 'src/app/vendors/model/vendoruser.model';
import { VendorUserService } from 'src/app/vendors/service/vendoruser.service';
import { RequestErrors } from 'src/app/common/utils';
import { AppConstants } from 'src/app/common/app.constants';
import { urlValidator } from 'src/app/shared/validators/url-validator';

@Component({
  selector: 'app-add-trial-vendor',
  templateUrl: './add-trial-vendor.component.html',
  styleUrls: ['./add-trial-vendor.component.scss'],
})
export class AddTrialVendorComponent implements OnInit {
  @Output() closeVendornav = new EventEmitter<boolean>();

  vendorCategories: VendorCategory[];
  ssoUrl = '';
  filteredVendors: VendorDropdown[];
  selectedVendor: VendorDropdown;
  form: UntypedFormGroup;
  msgs: Message[] = [];
  siteId: number = parseInt(sessionStorage.getItem('siteId'));
  trialId: number = parseInt(sessionStorage.getItem('trialId'));
  defaultVendorLogo = AppConstants.defaultVendorLogo;
  swaVendor = false;
  buttonSubmit = 'Add';
  passwordVisible = false;
  createCustom = false;

  constructor(
    private vendorService: VendorService,
    private vendorUserService: VendorUserService,
    private formBuilder: UntypedFormBuilder,
    private EncrDecr: EncrDecrService,
    private router: Router
  ) {}

  cancelEditVendor(refresh = false) {
    this.closeVendornav.emit(refresh);
  }

  getVendorCategoryList(): void {
    this.vendorService.vendorCategoryDropdownListforAdd().subscribe(
      (vendorCategories) => {
        const idx = vendorCategories.findIndex(
          (x) => x.categoryName === 'Other'
        );
        const other: VendorCategory = vendorCategories[idx];
        vendorCategories.splice(idx, 1);
        vendorCategories.push(other);
        this.vendorCategories = vendorCategories;
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  createCustomToggle() {
    this.createCustom = !this.createCustom;
    this.form.controls['vendorName'].reset();
    this.form.controls['vendorUrl'].reset();
    this.form.controls['vendorSelect'].reset();
    this.form.controls['vendorName'].setErrors(null);
    this.form.controls['vendorUrl'].setErrors(null);
    this.form.controls['vendorSelect'].setErrors(null);
    if (this.createCustom) {
      this.form.controls['vendorSelect'].clearValidators();
      this.form.controls['vendorName'].addValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]);
      this.form.controls['vendorUrl'].addValidators([
        Validators.required,
        urlValidator,
      ]);
    } else {
      this.form.controls['vendorSelect'].addValidators([Validators.required]);
      this.form.controls['vendorName'].clearValidators();
      this.form.controls['vendorUrl'].clearValidators();
    }
    this.form.updateValueAndValidity();
  }

  onVendorSelect(e) {
    const vendorCategory = this.vendorCategories.find(
      (x) => x.vendorCategoryId === e.value.vendorCategoryId
    );
    this.form.controls.vendorCategoryId.patchValue(vendorCategory);
    this.selectedVendor = this.form.controls.vendorSelect.value;
    this.swaVendor = this.selectedVendor.swaVendor;
  }

  filterVendorDropDown(event) {
    const vendorQuery = event.query;
    this.vendorService.getVendorsForDropdown().subscribe(
      (vendors) => {
        this.filteredVendors = this.filterVendor(vendorQuery, vendors);
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  filterVendor(query, vendors: VendorDropdown[]): VendorDropdown[] {
    const filteredVendor: VendorDropdown[] = [];
    for (let i = 0; i < vendors.length; i++) {
      const vendor = vendors[i];
      if (vendor.vendorName.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filteredVendor.push(vendor);
      }
    }
    return filteredVendor;
  }

  ngOnInit() {
    this.initForm();
    this.getVendorCategoryList();
  }

  initForm() {
    this.form = this.formBuilder.group({
      vendorSelect: ['', Validators.required],
      vendorUserName: [''],
      vendorPassword: [''],
      vendorName: [''],
      vendorUrl: [''],
      vendorCategoryId: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.buttonSubmit = 'Processing..';
    if (this.createCustom) {
      this.createCustomVendor();
    } else {
      this.createVendorUser();
    }
  }

  createCustomVendor() {
    this.ssoUrl = this.form.controls.vendorUrl.value;
    const newVendorUser = new VendorUser({
      trialId: this.trialId,
      siteId: this.siteId,
      vendorCategoryId:
        this.form.controls.vendorCategoryId.value.vendorCategoryId,
      vendorName: this.form.controls.vendorName.value,
      vendorUrl: this.form.controls.vendorUrl.value,
    });
    this.vendorService.createCustomVendor(newVendorUser).subscribe(
      (messageResponse) => {
        this.buttonSubmit = 'Add';
        if (messageResponse.success === true) {
          this.goToVendorSite();
        } else {
          this.msgs = [
            {
              severity: 'error',
              detail: messageResponse.errorMessages[0],
            },
          ];
        }
      },
      (error) => {
        this.buttonSubmit = 'Add';
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  createVendorUser() {
    if (this.swaVendor) {
      this.ssoUrl = this.selectedVendor.ssoAppUrl;
    } else {
      this.ssoUrl = this.selectedVendor.vendorUrl;
    }
    const newVendorUser = new VendorUser({
      vendorId: this.selectedVendor.vendorId,
      trialId: this.trialId,
      siteId: this.siteId,
      vendorUserName: this.swaVendor
        ? this.form.controls.vendorUserName.value
        : '',
      vendorPassword: this.swaVendor
        ? this.form.controls.vendorPassword.value
        : '',
      vendorCategoryId:
        this.form.controls.vendorCategoryId.value.vendorCategoryId,
      vendorAppId: this.selectedVendor.ssoAppId,
    });

    this.vendorUserService.linkVendor(newVendorUser).subscribe(
      (messageResponse) => {
        this.buttonSubmit = 'Add';
        if (messageResponse.success === true) {
          this.goToVendorSite();
        } else {
          this.msgs = [
            {
              severity: 'error',
              detail: messageResponse.errorMessages[0],
            },
          ];
        }
      },
      (error) => {
        this.buttonSubmit = 'Add';
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  goToVendorSite() {
    window.open(this.ssoUrl, '_blank');
    this.cancelEditVendor(true);
  }

  AddRequest() {
    const reqTypeValue = this.EncrDecr.encryptData('AddRequest');
    const helpReason = this.EncrDecr.encryptData(
      'Assistance with Vendor Login'
    );
    this.router.navigate(['/layout/faq/addrequest'], {
      queryParams: {
        reqType: reqTypeValue,
        helpReason: helpReason,
      },
    });
  }
}
