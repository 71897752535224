import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IdleService } from './core/idle.service';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public constructor(
    private idleService: IdleService,
    private router: Router,
    private authService: AuthService
  ) {
    if (environment.production) {
      if (window.location.href.indexOf('http://') !== -1) {
        const tempUrl = window.location.href.replace('http://', 'https://');
        window.location.href = tempUrl;
      }
      if (window.location.href.indexOf('http') === -1) {
        const tempUrl = 'https://' + window.location.href;
        window.location.href = tempUrl;
      }
    }
  }

  ngOnInit(): void {
    console.info(
      'environment.production is ',
      environment.production,
      ' apiUrl is ',
      environment.apiUrl
    );
    this.initialIdleSettings();
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = 30 * 60;
    this.idleService
      .startWatching(idleTimeoutInSeconds)
      .subscribe((isTimeOut: boolean) => {
        if (isTimeOut) {
          this.authService.logout();
          this.router.navigate(
            ['/auth/login', { sInf: 'Session Expired. Please Login Again' }],
            {
              skipLocationChange: true,
            }
          );
        }
      });
  }
}
