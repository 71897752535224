import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AlertsListComponent } from './alerts-list/alerts-list.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { WorkflowRoutingNotificationComponent } from './workflow-routing-notification/workflow-routing-notification.component';

@NgModule({
  declarations: [
    NotificationsListComponent,
    AlertsListComponent,
    WorkflowRoutingNotificationComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    NotificationsListComponent,
    AlertsListComponent,
    WorkflowRoutingNotificationComponent,
  ],
})
export class NotificationsModule {}
