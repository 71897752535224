import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ApiResponse } from '../core/apiresponse';
import { Message } from 'primeng/api';
import { WorkflowService } from '../workflow-routing/services/workflow.service';
import { createWorkflowRoutingList } from '../workflow-routing/model/workflowrouting.model';
import { User } from '../users/model/user.model';
import { BinderDocumentType } from '../documents/model/document-add.model';
import { SiteDocumentTrialListItem } from '../site-documents/model/sitedocumenttriallist.model';
import { RequestErrors } from '../common/utils';

export interface BinderSelectionItem {
  name: string;
  binderName: string;
  value: BinderDocumentType;
}

@Component({
  selector: 'app-document-routing',
  templateUrl: './document-routing.component.html',
  styleUrls: ['./document-routing.component.scss'],
})
export class DocumentRoutingComponent implements OnInit {
  @Output() closeRouteDocNav = new EventEmitter<boolean>();

  @Input() binderDocumentType: BinderDocumentType;
  @Input() sourceFileId: number;
  @Input() sourceFileName: string;
  @Input() sourceFolderId: number;
  @Input() subjectId: number;

  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));
  trialName = sessionStorage.getItem('trialName');
  workFlowUsers: User[] = [];
  siteDocumentsWorkFlow: boolean;
  workFlowType = '';
  form: UntypedFormGroup;
  apires: ApiResponse;
  msgs: Message[] = [];
  btnDisable: boolean;
  sourceBlobName = '';
  trialsSelections: SiteDocumentTrialListItem[];
  selectedDestination: string;
  showTrialDropdown = false;
  isSiteRoute = true;
  treeReady = false;

  constructor(
    private workflowService: WorkflowService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({});
  }

  closeDocumentRouting() {
    this.btnDisable = false;
    this.closeRouteDocNav.emit(false);
  }

  onSubmit() {
    const workFlowRoutingList = createWorkflowRoutingList(
      this.workFlowUsers,
      this.siteId,
      this.trialId,
      this.trialName,
      this.sourceFileId,
      this.binderDocumentType,
      this.sourceFileName
    );

    if (workFlowRoutingList.length === 0) {
      this.msgs = [
        {
          severity: 'error',
          detail: 'Please select at least one user to route the document to.',
        },
      ];
      document.getElementById('docRoutingScrollPanel').scrollTo(0, 0);
      return;
    }

    this.workflowService.addWorkFlowList(workFlowRoutingList).subscribe(
      (messageResponse) => {
        this.apires = messageResponse;
        document.getElementById('docRoutingScrollPanel').scrollTo(0, 0);

        if (this.apires.success === true) {
          this.closeDocumentRouting();
        }
        if (this.apires.success === false) {
          this.msgs = this.apires.errorMessages.map((x) => {
            return {
              severity: 'warn',
              detail: x,
            };
          });
        }
      },
      (error) => {
        const errors = RequestErrors(error);
        this.msgs = errors.map((x) => {
          return {
            severity: 'warn',
            detail: x,
          };
        });
      }
    );
  }

  updatedUserList(userList: User[]) {
    this.workFlowUsers = userList;
  }
}
