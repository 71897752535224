export class Patient {
  patientId?: number;
  patientNumber?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  dateOfBirth?: Date;
  gender?: string;
  email?: string;
  phoneNumber?: number;
  cellPhone?: number;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: number;
  race?: string;
  ethnicity?: string;
  isDeleted: boolean;
  subjectId?: number; // For editing subject/patient
  trialId?: number; // Here for adding a patient to a trial
  siteId?: number; // ""
  subjectStatusId?: number; // ""

  constructor(defaultValues: Partial<Patient>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }
}
