import { Component, OnInit } from '@angular/core';
import { NavMenuService } from './components/nav-menu/nav-menu.service';
import { EncrDecrService } from '../EncrDecr/encr-decr.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppAccessService } from '../core/directives/access.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  trialSelectedName: string;
  isTrialSelected = false;
  isSiteHome: boolean = sessionStorage.getItem('isSiteHome') === 'Yes';
  toggleicon = false;
  showSiteDocs = false;
  numberOfSites = Number(sessionStorage.getItem('numberOfSites'));

  constructor(
    private navMenuService: NavMenuService,
    private encrDecrService: EncrDecrService,
    private router: Router,
    private appAccessService: AppAccessService
  ) {
    this.navMenuService.trialState$.subscribe((trialDetails) => {
      this.isTrialSelected = trialDetails.trialSelected;
      this.trialSelectedName = trialDetails.trialName;
    });
    this.navMenuService.siteHomeChanged$.subscribe(() => {
      this.isSiteHome = sessionStorage.getItem('isSiteHome') === 'Yes';
    });
    this.navMenuService.userTypeChanged$.subscribe(() => {
      this.showSiteDocs = this.appAccessService.hasAccess('Site Docs');
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        if (ev.url === '/layout/sitehome') {
          // user has navigated to sitehome
          this.navMenuService.setIsSiteHome(true);
          this.navMenuService.setTrialState(false);
          this.isSiteHome = true;
        } else if (ev.url === '/layout/home') {
          // user has navigated to home
          this.navMenuService.setIsSiteHome(false);
          this.navMenuService.setTrialState(false);
          this.isSiteHome = false;
        }
      });
  }

  clickSiteHome() {
    if (this.numberOfSites > 1) {
      this.router.navigate(['/layout/sitehome']);
    } else {
      this.router.navigate(['/layout/home']);
    }
  }

  goToTrial() {
    this.router.navigate(['/layout/trialVendors']);
  }

  addRequest(requestType: string) {
    const reqTypeValue = this.encrDecrService.encryptData(requestType);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/layout/faq/addrequest'], {
        queryParams: {
          reqType: reqTypeValue,
        },
      })
    );
  }

  helpHomePage() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/layout/lido-training/help']));
  }
}
