<div class="b_container">

    <div class="rtheader">
        Unlink Vendor <div class="closesidebar" (click)="CancelUnlinkVendorNav()">X</div>
    </div>
    <div class="rtcontainer messages">

        <p-messages [value]="msgs"></p-messages>
        <div class="row">
            <div class="col-md-12">

                <div class="row my-3">
                    <div class="col-sm-12">
                        <ng-container
                            *ngIf="((vendorUser.vendorLogo !== undefined) && (vendorUser.vendorLogo !== '') && (vendorUser.vendorLogo !== null)); else noLogo">
                            <img class="gridlogosize" src="{{vendorUser.vendorLogo | imgurl}}" alt="Vendor Logo" />
                        </ng-container>
                        <ng-template #noLogo>
                            <img class="gridlogosize" src="{{defaultVendorLogo}}" alt="Vendor Logo" />
                        </ng-template>
                    </div>
                </div>

                <div class="alert alert-warning" role="alert">
                    Do you want to unlink vendor <b>{{vendorUser.vendorName}}</b> from Trial <b>{{trialName}}</b>?
                </div>

                <div class="btnnav mt_15">
                    <button (click)="onUnlink()" type="button"
                        [disabled]="subBtnDisable || btnConfirm==='Processing..' " class="btn btn-primary marright">

                        {{btnConfirm}}
                        <i class="fa fa-spinner fa-spin" *ngIf="btnConfirm==='Processing..'"></i>

                    </button>
                    <button type="button" (click)="CancelUnlinkVendorNav(false)" class="btn btn-default">Cancel</button>
                </div>

            </div>

        </div>

    </div>
</div>