<div class="b_container">
  <div class="rtcontainer messages">
    <p-messages [value]="msgs"></p-messages>

    <div *ngIf="displayImage">
      <img [src]="url" alt="image" />
    </div>

    <div *ngIf="displayDoc">
      <ngx-doc-viewer [url]="url" viewer="office" style="width: 100%; height: 85vh"
        disableContent="popout"></ngx-doc-viewer>
    </div>

    <div *ngIf="displayPdf">
      <ngx-doc-viewer [url]="url" viewer="google" style="width: 100%; height: 85vh" disableContent="popout"
        googleCheckContentLoaded="true" googleCheckInterval="2000"></ngx-doc-viewer>
    </div>
  </div>

  <div class="btnnav mt_15">
    <button type="button" (click)="onDownload()" class="btn btn-primary marright">
      Download
    </button>
    <button type="button" (click)="closeDocumentModal()" class="btn btn-default">
      Cancel
    </button>
  </div>
</div>