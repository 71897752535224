import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { TrialVendorsComponent } from './trialvendors/trial-vendors.component';
import { AuthGuard } from './auth/auth-guard.service';
import { ProfileComponent } from './profile/profile.component';
import { SubjectComponent } from './subject/subject.component';
import { UserListComponent } from './users/user-list.component';
import { SiteDocumentsComponent } from './site-documents/site-documents.component';
import { OperationsBinderComponent } from './operations-binder/operations-binder.component';
import { RegulatoryBinderComponent } from './regulatory-binder/regulatory-binder.component';
import { AlertsListComponent } from './notifications/alerts-list/alerts-list.component';
import { LoginComponent } from './auth/login/login.component';
import { DocFlowLogComponent } from './doc-flow-log/doc-flow-log.component';

import { FaqEditRequestComponent } from './FAQ/faqeditrequest.component';
import { SiteHomeComponent } from './home/sitehome.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { SubjectDocumentsComponent } from './subject/subject-documents/subject-documents.component';
import { NotificationsListComponent } from './notifications/notifications-list/notifications-list.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ForgotPasswordEmailComponent } from './auth/forgot-password/forgot-password-email/forgot-password-email.component';
import { ForgotPasswordSecquestionsComponent } from './auth/forgot-password/forgot-password-secquestions/forgot-password-secquestions.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { HelpComponent } from './FAQ/help/help.component';
import { HelpRequestListComponent } from './FAQ/help-request-list/help-request-list.component';
import { ProfileGuard } from './auth/profile-guard.service';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: 'auth/reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth/forgot-password-email',
    component: ForgotPasswordEmailComponent,
  },
  {
    path: 'auth/forgot-password-secquestions',
    component: ForgotPasswordSecquestionsComponent,
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'layout', // should be layout
    component: LayoutComponent,
    canActivate: [AuthGuard],

    children: [
      {
        path: 'profile',
        canActivate: [AuthGuard],
        component: ProfileComponent,
      },
      {
        path: 'sitehome',
        canActivate: [AuthGuard, ProfileGuard],
        component: SiteHomeComponent,
      },
      {
        path: 'home',
        canActivate: [AuthGuard, ProfileGuard],
        component: HomeComponent,
      },
      {
        path: 'trialVendors',
        canActivate: [AuthGuard, ProfileGuard],
        component: TrialVendorsComponent,
      },
      {
        path: 'site-documents',
        canActivate: [AuthGuard, ProfileGuard],
        component: SiteDocumentsComponent,
      },
      {
        path: 'site-documents/:id',
        canActivate: [AuthGuard, ProfileGuard],
        component: SiteDocumentsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'subject',
        canActivate: [AuthGuard, ProfileGuard],
        component: SubjectComponent,
      },
      {
        path: 'subject-documents/:subjectId',
        canActivate: [AuthGuard, ProfileGuard],
        component: SubjectDocumentsComponent,
      },
      {
        path: 'subject-documents/:subjectId/:id',
        canActivate: [AuthGuard, ProfileGuard],
        component: SubjectDocumentsComponent,
      },
      {
        path: 'operations-binder',
        canActivate: [AuthGuard, ProfileGuard],
        component: OperationsBinderComponent,
      },
      {
        path: 'operations-binder/:id',
        canActivate: [AuthGuard, ProfileGuard],
        component: OperationsBinderComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'regulatory-binder',
        canActivate: [AuthGuard, ProfileGuard],
        component: RegulatoryBinderComponent,
      },
      {
        path: 'regulatory-binder/:id',
        canActivate: [AuthGuard, ProfileGuard],
        component: RegulatoryBinderComponent,
      },
      {
        path: 'users/user-list',
        canActivate: [AuthGuard, ProfileGuard],
        component: UserListComponent,
      },
      {
        path: 'terms-of-use',
        canActivate: [AuthGuard, ProfileGuard],
        component: TermsOfUseComponent,
      },
      {
        path: 'alerts-list',
        canActivate: [AuthGuard, ProfileGuard],
        component: AlertsListComponent,
      },
      {
        path: 'alerts-list/:userEventId',
        canActivate: [AuthGuard, ProfileGuard],
        component: AlertsListComponent,
      },
      {
        path: 'notifications-list',
        canActivate: [AuthGuard, ProfileGuard],
        component: NotificationsListComponent,
      },
      {
        path: 'notifications-list/:userEventId',
        canActivate: [AuthGuard, ProfileGuard],
        component: NotificationsListComponent,
      },
      {
        path: 'doc-flow-log',
        canActivate: [AuthGuard, ProfileGuard],
        component: DocFlowLogComponent,
      },
      {
        path: 'lido-training/help',
        canActivate: [AuthGuard, ProfileGuard],
        component: HelpComponent,
      },
      {
        path: 'faq/addrequest',
        canActivate: [AuthGuard, ProfileGuard],
        component: FaqEditRequestComponent,
      },
      {
        path: 'faq/editrequest/:id',
        canActivate: [AuthGuard, ProfileGuard],
        component: FaqEditRequestComponent,
      },
      {
        path: 'faq/helprequestlist',
        canActivate: [AuthGuard, ProfileGuard],
        component: HelpRequestListComponent,
      },
    ],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: '**',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
