<div class="b_container messages">
  <div class="rtheader">
    {{ titleLabel }}
    <div class="closesidebar" (click)="closeEditTrial()">X</div>
  </div>
  <div class="rtcontainer">
    <div *ngIf="editTrialComp">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <p-messages [value]="msgs"></p-messages>
        <div class="row">
          <p-tabView>
            <p-tabPanel header="Edit Details">
              <div class="col-12">
                <div class="row my-3 mt-4 align-items-center">
                  <label class="col-2">Site Name</label>
                  <div class="col-8">
                    <input pInputText class="form-control" type="text" readonly placeholder="{{ siteName }}" />
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <label class="col-2">Trial Name</label>
                  <div class="col-8">
                    <input pInputText class="form-control" type="text" formControlName="trialName"
                      placeholder="Trial name" />
                    <span class="mandatory">*</span>
                  </div>
                  <div class="alert alert-danger" *ngIf="
                      !form.controls['trialName'].valid &&
                      form.controls['trialName'].touched
                    ">
                    Trial name is required and must be at least 3 characters
                    long
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <label class="col-2">Protocol Number</label>
                  <div class="col-8">
                    <input pInputText class="form-control" type="text" formControlName="protocolNumber"
                      placeholder="Protocol Number" />
                    <span class="mandatory">*</span>
                  </div>
                  <div class="alert alert-danger" *ngIf="
                      !form.controls['protocolNumber'].valid &&
                      form.controls['protocolNumber'].touched
                    ">
                    Protocol number is required and must be at least 3
                    characters long
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <label class="col-2">Project Id</label>
                  <div class="col-8">
                    <input pInputText class="form-control" placeholder="Protocol Number" type="text"
                      formControlName="projectId" />
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <label class="col-2">Site Number</label>
                  <div class="col-8">
                    <input pInputText class="form-control" type="text" formControlName="siteNumber"
                      placeholder="Site Number" />
                    <span class="mandatory">*</span>
                  </div>
                  <div class="alert alert-danger" *ngIf="
                      !form.controls['siteNumber'].valid &&
                      form.controls['siteNumber'].touched
                    ">
                    Site number is required and must be at least 3 characters
                    long
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <label class="col-2">Sponsor</label>
                  <div class="col-8 dd95">
                    <p-dropdown dropdownIcon="fa fa-caret-down" [options]="sponsorList" formControlName="sponsorSelect"
                      placeholder="Sponsor" optionLabel="sponsorName">
                      <ng-template let-sponsor pTemplate="item">
                        <div class="logolistnav">
                          <ng-container *ngIf="
                              sponsor?.value.sponsorLogo !== undefined &&
                                sponsor?.value.sponsorLogo !== '' &&
                                sponsor?.value.sponsorLogo !== null;
                              else noLogo
                            ">
                            <img src="{{ sponsor?.value.sponsorLogo | imgurl }}" class="logolist" alt="Sponsor Logo" />
                          </ng-container>
                          <ng-template #noLogo>
                            <img src="{{ defaultSponsorLogo }}" class="logolist" alt="Sponsor Logo" />
                          </ng-template>
                          <div class="logoname">
                            {{ sponsor?.value.sponsorName }}
                          </div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <span class="mandatory">*</span>
                  </div>
                  <div *ngIf="
                      !form.controls['sponsorSelect'].valid &&
                      form.controls['sponsorSelect'].touched
                    " class="alert alert-danger">
                    Sponsor is Required
                  </div>
                </div>
                <div *ngIf="otherSponsor" class="row my-3 align-items-center">
                  <label class="col-2">Sponsor Name</label>
                  <div class="col-8">
                    <input pInputText class="form-control" type="text" formControlName="sponsorName"
                      placeholder="Sponsor Name" />
                    <span class="mandatory">*</span>
                  </div>
                  <div class="alert alert-danger" *ngIf="
                      !form.controls['sponsorName'].valid &&
                      (form.controls['sponsorName'].dirty ||
                        form.controls['sponsorName'].touched)
                    ">
                    Sponsor Name is Required and must be at least 3 characters
                    long
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <label class="col-2">Status</label>
                  <div class="col-8 dd95">
                    <p-dropdown dropdownIcon="fa fa-caret-down" [options]="trialStatusSelections"
                      (onChange)="onSelectTrialStatus($event)" formControlName="statusName" placeholder="Trial Status"
                      optionLabel="statusName"></p-dropdown>
                    <span class="mandatory">*</span>
                  </div>
                  <div *ngIf="
                      form.controls['statusName'].invalid &&
                      form.controls['statusName'].touched
                    " class="alert alert-danger">
                    Trial Status is Required
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="forminfo">
                  <ul>
                    <li>
                      <span class="mandatory">*</span> fields are mandatory
                    </li>
                  </ul>
                </div>
              </div>
              <div class="btnnav">
                <button type="submit" [disabled]="!form.valid || !allowSave" class="btn btn-primary marright">
                  Update
                </button>
                <!-- if trial is archived they can't do anything -->
                <button type="button" [disabled]="trial?.trialStatusId === 5" class="btn btn-primary marright"
                  (click)="showAddTrialUserList()">
                  Add Trial Users
                </button>
                <button type="button" (click)="closeEditTrial()" class="btn btn-default">
                  Cancel
                </button>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Edit Trial Users">
              <div class="col mt-4 mb-5">
                <app-edit-trialusers [trial]="trial" [originalTrialUsers]="trialUsers"
                  (sendTrialUsers)="saveTrialUsers($event)"
                  (removeTrialUser)="removeTrialUser($event)"></app-edit-trialusers>
              </div>
              <div class="btnnav">
                <button type="submit" [disabled]="!form.valid || isClosed" class="btn btn-primary marright">
                  Update
                </button>
                <button type="button" [disabled]="isClosed" class="btn btn-primary marright"
                  (click)="showAddTrialUserList()">
                  Add Trial Users
                </button>
                <button type="button" (click)="closeEditTrial()" class="btn btn-default">
                  Cancel
                </button>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </form>
    </div>

    <div *ngIf="loadAddUserLanding">
      <app-add-trialuser-list *ngIf="loadAddUserLanding" [trial]="trial" [needPi]="needPi"
        (closeAddUserModal)="closeModals($event)">
      </app-add-trialuser-list>
    </div>
  </div>

  <p-dialog header="Confirmation" key="confirmStatus" [closable]="false" appendTo="body" [visible]="showConfirmDialog"
    [modal]="false" [style]="{ width: '400px' }" [baseZIndex]="10000">
    <p>{{ confirmTitleLabel }}</p>
    <p-footer>
      <div class="btnnav">
        <button type="button" (click)="confirmTrialStatus(true)" class="btn btn-primary marright">
          Yes
        </button>
        <button type="button" (click)="confirmTrialStatus(false)" class="btn btn-default">
          No
        </button>
      </div>
    </p-footer>
  </p-dialog>
  <p-confirmDialog #cd>
    <div class="btnnav">
      <button class="btn btn-primary marright" type="button" (click)="cd.accept()">
        Yes<fa name="check"></fa>
      </button>
      <button class="btn btn-default" type="button" (click)="cd.reject()">
        No<fa name="times"></fa>
      </button>
    </div>
  </p-confirmDialog>
</div>