<div class="b_container">
    <div class="rtheader">
        {{pageTitle}}
        <div class="closesidebar" (click)="closeModal()">X</div>
    </div>

    <div class="rtcontainer messages">
        <p-messages [value]="msgs"></p-messages>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12">
                    <div class="row my-3 align-items-center">
                        <label for="patientNumber" class="col-2 col-form-label">Patient Number</label>
                        <div class="col-8">
                            <input pInputText class="form-control" formControlName="patientNumber" type="text"
                                placeholder="Patient Number" />
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="firstName" class="col-2 col-form-label">First Name</label>
                        <div class="col-8">
                            <p><input pInputText class="form-control" formControlName="firstName" type="text"
                                    placeholder="First Name" /></p>
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['firstName'].valid && form.controls['firstName'].dirty">
                                First Name must be between 3 and 50 characters long
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="lastName" class="col-2 col-form-label">Last Name</label>
                        <div class="col-8">
                            <p><input pInputText class="form-control" formControlName="lastName" type="text"
                                    placeholder="Last Name" /></p>
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['lastName'].valid && form.controls['lastName'].dirty">
                                Last Name must be between 3 and 50 characters long
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="displayName" class="col-2 col-form-label">Display Name</label>
                        <div class="col-8">
                            <input pInputText class="form-control" formControlName="displayName" type="text"
                                placeholder="Display Name" />
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['displayName'].valid && form.controls['displayName'].dirty">
                                Last Name must be between 3 and 100 characters long
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="dob" class="col-2 col-form-label">Date of Birth</label>
                        <div class="col-8 caltb">
                            <p-calendar formControlName="dateOfBirth" [readonlyInput]="false" [showIcon]="true"
                                [monthNavigator]="true" [yearNavigator]="true" [yearRange]="dateOfBirthYearRange">
                            </p-calendar>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="gender" class="col-2 col-form-label">Gender</label>
                        <div class="col-8 dd95">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="genderChoices"
                                formControlName="gender" placeholder="Gender" optionLabel="label"></p-dropdown>
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['gender'].valid && form.controls['gender'].dirty">
                                A selection is required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="subjectStatusName" class="col-2 col-form-label">Status</label>
                        <div class="col-8 dd95">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="statusChoices"
                                placeholder="Subject Status" autoDisplayFirst="false" formControlName="subjectStatus"
                                optionLabel="subjectStatusName"></p-dropdown>

                            <div class="alert alert-danger"
                                *ngIf="!form.controls['subjectStatus'].valid && form.controls['subjectStatus'].dirty">
                                A selection is required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="race" class="col-2 col-form-label">Race</label>
                        <div class="col-8 dd95">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="raceChoices" optionLabel="label"
                                formControlName="race" placeholder="Race"></p-dropdown>
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['race'].valid && form.controls['race'].dirty">
                                A selection is required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="staticEmail" class="col-2 col-form-label">Ethnicity</label>
                        <div class="col-8 dd95">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="ethnicityChoices" optionLabel="label"
                                formControlName="ethnicity" placeholder="Ethnicity"></p-dropdown>
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['ethnicity'].valid && form.controls['ethnicity'].dirty">
                                A selection is required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="email" class="col-2 col-form-label">Email Address</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="email" placeholder="Email Address"
                                formControlName="email" />
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['email'].valid && form.controls['email'].dirty">
                                Enter a valid email address
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="address1" class="col-2 col-form-label">Address</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="text" placeholder="Address"
                                formControlName="address1">
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['address1'].valid && form.controls['address1'].dirty">
                                Address is required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="address2" class="col-2 col-form-label">Address 2</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="text" placeholder="Address line 2"
                                formControlName="address2">
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="city" class="col-2 col-form-label">City</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="text" placeholder="City"
                                formControlName="city">
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['city'].valid && form.controls['city'].dirty">
                                City is required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="state" class="col-2 col-form-label">State</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="text" placeholder="State"
                                formControlName="state">
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['state'].valid && form.controls['state'].dirty">
                                State is required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="state" class="col-2 col-form-label">Zip Code</label>
                        <div class="col-8">
                            <p-inputMask mask="99999" placeholder="Zip Code" formControlName="zipCode"></p-inputMask>
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['zipCode'].valid && form.controls['zipCode'].dirty">
                                Zip code is required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="country" class="col-2 col-form-label">Country</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="text" placeholder="Country"
                                formControlName="country">
                        </div>
                    </div>


                    <div class="row my-3 align-items-center">
                        <label for="phoneNumber" class="col-2 col-form-label">Phone Number</label>
                        <div class="col-8 maskinput">
                            <p-inputMask mask="(999) 999-9999" placeholder="PhoneNumber"
                                formControlName="phoneNumber"></p-inputMask>
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['phoneNumber'].valid && form.controls['phoneNumber'].dirty">
                                Phone Number is Required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label for="cellPhone" class="col-2 col-form-label">Cell Phone</label>
                        <div class="col-8 maskinput">
                            <p-inputMask mask="(999) 999-9999" placeholder="Cell Phone"
                                formControlName="cellPhone"></p-inputMask>
                            <div class="alert alert-danger"
                                *ngIf="!form.controls['cellPhone'].valid && form.controls['cellPhone'].dirty">
                                Cell Phone Number is Required
                            </div>
                        </div>
                    </div>



                    <div class="btnnav mt_15">
                        <button type="submit" [disabled]="!form.valid"
                            class="btn btn-primary marright">{{btnCreate}}</button>
                        <button type="button" (click)="closeModal()" class="btn btn-default">Cancel</button>
                    </div>

                </div>

                <div class="col-4">
                    <div class="forminfo">
                        <ul>
                            <li><span class="mandatory">*</span> fields are mandatory</li>
                        </ul>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>