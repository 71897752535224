import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { TrialVendorsComponent } from './trial-vendors.component';
import { AddTrialVendorComponent } from './add-trial-vendor/add-trial-vendor.component';
import { EditTrialVendorComponent } from './edit-trial-vendor/edit-trial-vendor.component';
import { UnlinkTrialVendorComponent } from './unlink-trial-vendor/unlink-trial-vendor.component';
import { OktaConfirmationComponent } from './okta-confirmation/okta-confirmation.component';
import { TrialModule } from '../trial/trial.module';
@NgModule({
  imports: [CommonModule, SharedModule, TrialModule],
  declarations: [
    TrialVendorsComponent,
    AddTrialVendorComponent,
    EditTrialVendorComponent,
    UnlinkTrialVendorComponent,
    EditTrialVendorComponent,
    OktaConfirmationComponent,
  ],
  exports: [
    TrialVendorsComponent,
    AddTrialVendorComponent,
    EditTrialVendorComponent,
    UnlinkTrialVendorComponent,
    EditTrialVendorComponent,
    OktaConfirmationComponent,
  ],
})
export class TrialVendorsModule {}
