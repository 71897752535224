import { deepCopy } from 'src/app/common/utils';

export class SubjectRecordsFolder {
  subjectRecordsFolderId?: number;
  subjectRecordsFolderName?: string;
  isDeleted: boolean;
  isArchived: boolean;
  parentFolderId?: number;
  siteId?: number;
  trialId?: number;
  subjectId?: number;
  remarks?: boolean;

  constructor(defaultValues: Partial<SubjectRecordsFolder>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }

  clone() {
    return new SubjectRecordsFolder(deepCopy(this));
  }
}
