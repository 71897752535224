import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'trialrole' })
export class TrailRoleInitialPipe implements PipeTransform {
  transform(target: string) {
    if (target === undefined || target === null) {
      return target;
    }

    return target
      .split(' ')
      .map((n) => n[0])
      .join('');
  }
}
