import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Message } from 'primeng/api';
import { ApiResponse } from 'src/app/core/apiresponse';

import { RequestErrors } from 'src/app/common/utils';
import { Patient } from '../model/patient.model';
import { PatientService } from '../service/patient.service';
import { SubjectService } from '../service/subject.service';
import { SubjectStatusDropDown } from '../model/subjectstatus-dropdown.model';
@Component({
  selector: 'app-edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['./edit-patient.component.scss'],
})
export class EditPatientComponent implements OnInit {
  @Output() closeEditPatient = new EventEmitter<boolean>();
  @Input() patient: Patient;
  trialId = +sessionStorage.getItem('trialId');
  siteId = +sessionStorage.getItem('siteId');
  addingNew = false;
  pageTitle = 'Edit Subject';
  btnCreate = '';

  form: UntypedFormGroup;
  statusChoices: SubjectStatusDropDown[];
  genderChoices = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Other', value: 'Other' },
  ];
  raceChoices = [
    { label: 'Caucasian', value: 'Caucasian' },
    { label: 'African American', value: 'African American' },
    { label: 'Asian', value: 'Asian' },
    {
      label: 'American Indian / Pacific Islander',
      value: 'American Indian / Pacific Islander',
    },
    { label: 'Other', value: 'Other' },
    { label: 'Not Specified', value: 'Not Specified' },
  ];

  ethnicityChoices = [
    { label: 'Hispanic', value: 'Hispanic' },
    { label: 'Non Hispanic', value: 'Non Hispanic' },
  ];

  apires: ApiResponse;
  msgs: Message[] = [];

  maxYearDate: Date;
  dateOfBirthYearRange = '';
  displaydateOfBirth: string;
  dateOfBirthVal: Date;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private patientService: PatientService,
    private subjectService: SubjectService
  ) {}

  closeModal(refresh = false) {
    this.closeEditPatient.emit(refresh);
  }

  onSubmit() {
    this.msgs = [];
    const newPatient = new Patient(this.patient);
    if (this.addingNew) {
      newPatient.isDeleted = false;
      newPatient.trialId = this.trialId;
      newPatient.siteId = this.siteId;
    }
    newPatient.patientNumber = this.form.controls.patientNumber.value;
    newPatient.firstName = this.form.controls.firstName.value;
    newPatient.lastName = this.form.controls.lastName.value;
    newPatient.displayName = this.form.controls.displayName.value;
    newPatient.dateOfBirth = this.form.controls.dateOfBirth.value;
    newPatient.gender = this.form.controls.gender.value.value;
    newPatient.subjectStatusId =
      this.form.controls.subjectStatus.value.subjectStatusId;
    newPatient.race = this.form.controls.race.value.value;
    newPatient.ethnicity = this.form.controls.ethnicity.value.value;
    newPatient.email = this.form.controls.email.value;
    newPatient.phoneNumber = this.form.controls.phoneNumber.value;
    newPatient.cellPhone = this.form.controls.cellPhone.value;
    newPatient.address1 = this.form.controls.address1.value;
    newPatient.address2 = this.form.controls.address2.value;
    newPatient.city = this.form.controls.city.value;
    newPatient.state = this.form.controls.state.value;
    newPatient.zipCode = this.form.controls.zipCode.value;
    newPatient.country = this.form.controls.country.value;

    if (this.addingNew) {
      this.patientService.createPatient(newPatient).subscribe(
        (messageResponse) => {
          if (messageResponse.success === true) {
            this.closeModal(true);
          } else {
            this.btnCreate = 'Create';
            this.msgs = [
              {
                severity: 'error',
                detail: messageResponse.errorMessages[0],
              },
            ];
          }
        },
        (error) => {
          this.btnCreate = 'Create';
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
    } else {
      this.patientService.updatePatient(newPatient).subscribe(
        (messageResponse) => {
          if (messageResponse.success === true) {
            this.closeModal(true);
          } else {
            this.btnCreate = 'Create';
            this.msgs = [
              {
                severity: 'error',
                detail: messageResponse.errorMessages[0],
              },
            ];
          }
        },
        (error) => {
          this.btnCreate = 'Create';
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
    }
  }

  patchForm(): void {
    const subjectStatus = this.statusChoices.find(
      (x) => x.subjectStatusId === this.patient.subjectStatusId
    );
    const gender = this.genderChoices.find(
      (x) => x.label === this.patient.gender
    );
    const race = this.raceChoices.find((x) => x.label === this.patient.race);
    const ethnicity = this.ethnicityChoices.find(
      (x) => x.label === this.patient.ethnicity
    );
    const dateOfBirth: Date = new Date(this.patient.dateOfBirth);
    this.form.patchValue({
      patientNumber: this.patient.patientNumber,
      firstName: this.patient.firstName,
      lastName: this.patient.lastName,
      displayName: this.patient.displayName,
      gender: gender,
      subjectStatus: subjectStatus,
      race: race,
      ethnicity: ethnicity,
      email: this.patient.email,
      address1: this.patient.address1,
      address2: this.patient.address2,
      city: this.patient.city,
      state: this.patient.state,
      zipCode: this.patient.zipCode,
      country: this.patient.country,
      phoneNumber: this.patient.phoneNumber,
      cellPhone: this.patient.cellPhone,
    });

    this.form.patchValue({
      dateOfBirth: dateOfBirth,
    });
  }

  ngOnInit() {
    this.maxYearDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 12)
    );

    this.dateOfBirthYearRange =
      (new Date().getFullYear() - 100).toString() +
      ':' +
      new Date().getFullYear();
    this.initForm();
    this.addingNew = this.patient.patientId === undefined;
    this.btnCreate = !this.addingNew ? 'Update' : 'Create';
    this.pageTitle = !this.addingNew ? 'Edit Subject' : 'Add Subject';
    this.getData();
  }

  initForm() {
    this.form = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      displayName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      patientNumber: [''],
      dateOfBirth: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      subjectStatus: ['', [Validators.required]],
      race: ['', [Validators.required]],
      ethnicity: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      address1: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      address2: [''],
      city: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      state: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
        ],
      ],
      country: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      phoneNumber: [''],
      cellPhone: [''],
    });
  }

  /*service*/
  getData() {
    this.subjectService.getSubjectStatusDropDown().subscribe((data) => {
      this.statusChoices = data;
      if (!this.addingNew) {
        this.patchForm();
      }
    });
  }
}
