import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { SubjectComponent } from './subject.component';
import { EditPatientComponent } from './edit-patient/edit-patient.component';
import { DocumentRoutingModule } from '../document-routing/document-routing.module';
import { WorkFlowRoutingModule } from '../workflow-routing/workflow-routing.module';
import { SubjectDocumentsComponent } from './subject-documents/subject-documents.component';
import { DocumentsModule } from '../documents/documents.module';
import { UnlinkTrialSubjectComponent } from './unlink-trial-subject/unlink-trial-subject.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DocumentsModule,
    DocumentRoutingModule,
    WorkFlowRoutingModule,
  ],
  declarations: [
    SubjectComponent,
    SubjectDocumentsComponent,
    EditPatientComponent,
    UnlinkTrialSubjectComponent,
  ],
  exports: [
    SubjectComponent,
    SubjectDocumentsComponent,
    EditPatientComponent,
    UnlinkTrialSubjectComponent,
  ],
})
export class SubjectModule {}
