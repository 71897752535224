<div class="b_container pgmsgs">
    <div class="popuphd" style="text-align: left">Add Trial Users </div>

    <div>
        <app-add-trialusers [trial]="trial" [users]="users" [needPi]="needPi"
            (sendTrialUsers)="saveTrialUsers($event)"></app-add-trialusers>
        <div class="btnnav">
            <button (click)="closeModal(true)" [disabled]="this.trialUsers.length === 0"
                class="btn btn-primary marright">
                Update
            </button>
            <button type="button" (click)="closeModal(false)" class="btn btn-default">Cancel</button>
        </div>

    </div>