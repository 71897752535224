import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ApiResponse } from 'src/app/core/apiresponse';
import { Message } from 'primeng/api';
import { VendorService } from '../../vendors/service/vendor.service';

import { VendorCategory } from '../../vendors/model/vendorcategory.model';
import { VendorUser } from 'src/app/vendors/model/vendoruser.model';
import { EncrDecrService } from 'src/app/EncrDecr/encr-decr.service';
import { mergeMap } from 'rxjs/operators';
import { VendorUserService } from 'src/app/vendors/service/vendoruser.service';
import { RequestErrors } from 'src/app/common/utils';
import { urlValidator } from 'src/app/shared/validators/url-validator';

// Used by alerts-list, edit-trial-vendor
@Component({
  selector: 'app-edit-trial-vendor',
  templateUrl: './edit-trial-vendor.component.html',
  styleUrls: ['./edit-trial-vendor.component.scss'],
})
export class EditTrialVendorComponent implements OnChanges {
  @Input() vendorId: number;
  @Input() trialId: number;
  @Output() closeEditTrialVendorDetailsModal = new EventEmitter<boolean>();

  siteId = Number(sessionStorage.getItem('siteId'));
  openVendor = false;
  vendorUser: VendorUser;
  swaVendor = false;
  editCustom = false;
  changeCredentials = false;
  vendorCategories: VendorCategory[];
  form: UntypedFormGroup;
  passwordVisible = false;
  apires: ApiResponse;
  msgs: Message[] = [];
  checked: string;
  subBtndisable = false;
  btnUpdate = 'Update';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private vendorUserService: VendorUserService,
    private vendorService: VendorService,
    private EncrDecr: EncrDecrService,
    private router: Router
  ) {}

  ngOnChanges() {
    this.initForm();
    this.getVendorUser();
  }

  initForm() {
    this.form = this.formBuilder.group({
      vendorName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      vendorUrl: ['', [Validators.required, urlValidator]],
      changePassword: [''],
      vendorCategoryId: ['', [Validators.required]],
      vendorUserName: [''],
      vendorPassword: [''],
    });

    this.form.controls.changePassword.valueChanges.subscribe((val) => {
      this.changeCredentials = val === true;
      if (this.changeCredentials) {
        this.form.controls.vendorPassword.setValidators([
          Validators.required,
          Validators.minLength(4),
        ]);
        this.form.controls.vendorUserName.setValidators([
          Validators.required,
          Validators.minLength(4),
        ]);
      } else {
        this.form.controls.vendorPassword.clearValidators();
      }
      this.form.updateValueAndValidity();
    });
  }

  patchForm() {
    const vendorCategory = this.vendorCategories.find(
      (x) => x.vendorCategoryId === this.vendorUser.vendorCategoryId
    );
    this.form.patchValue({
      vendorCategoryId: vendorCategory,
      vendorName: this.vendorUser.vendorName,
      vendorUrl: this.vendorUser.vendorUrl,
    });
    if (this.vendorUser.swaVendor || this.vendorUser.vendorType === 1) {
      this.form.controls['vendorName'].disable();
      this.form.controls['vendorUrl'].disable();
    }
  }

  onCancel() {
    this.closeEditTrialVendorDetailsModal.emit(false);
  }

  onSubmit() {
    this.btnUpdate = 'Processing..';
    this.subBtndisable = true;
    const newVendorUser = new VendorUser(this.vendorUser);
    newVendorUser.vendorCategoryId =
      this.form.controls.vendorCategoryId.value.vendorCategoryId;
    if (this.changeCredentials) {
      newVendorUser.vendorPassword = this.form.controls.vendorPassword.value;
      newVendorUser.vendorUserName = this.form.controls.vendorUserName.value;
    }
    if (!this.vendorUser.swaVendor && this.vendorUser.vendorType === 2) {
      newVendorUser.vendorName = this.form.controls.vendorName.value;
      newVendorUser.vendorUrl = this.form.controls.vendorUrl.value;
    }
    this.vendorUserService.editVendorUser(newVendorUser).subscribe(
      (apiRes) => {
        this.apires = apiRes;
        if (this.apires.success === true) {
          this.subBtndisable = false;
          this.btnUpdate = 'Update';
          if (this.changeCredentials === true) {
            window.open(this.vendorUser.ssoAppUrl, '_blank');
          }
          this.closeEditTrialVendorDetailsModal.emit(true);
        }
        if (this.apires.success === false) {
          this.msgs = [
            {
              severity: 'error',
              detail: this.apires.errorMessages[0],
            },
          ];
        }
      },
      (error) => {
        this.subBtndisable = true;
        this.btnUpdate = 'Update';
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  getVendorUser() {
    this.vendorUserService
      .getVendorUser(this.vendorId, this.siteId, this.trialId)
      .pipe(
        mergeMap((vendorUser) => {
          this.vendorUser = vendorUser;
          this.swaVendor = this.vendorUser.swaVendor === false;
          return this.vendorService.vendorCategoryDropdownListforEdit();
        })
      )
      .subscribe(
        (vendorCategories: VendorCategory[]) => {
          const idx = vendorCategories.findIndex(
            (x) => x.categoryName === 'Other'
          );
          const other: VendorCategory = vendorCategories[idx];
          vendorCategories.splice(idx, 1);
          vendorCategories.push(other);
          this.vendorCategories = vendorCategories;
          this.patchForm();
        },
        (error) => {
          const errors = RequestErrors(error);
          this.msgs = errors.map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  AddRequest() {
    const reqTypeValue = this.EncrDecr.encryptData('AddRequest');
    const helpReason = this.EncrDecr.encryptData(
      'Assistance with Vendor Login'
    );
    this.router.navigate(['/layout/faq/addrequest'], {
      queryParams: {
        reqType: reqTypeValue,
        helpReason: helpReason,
      },
    });
  }
}
