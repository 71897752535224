<div class="b_container messages">

    <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
    <p-messages [value]="msgs"></p-messages>

    <div class="row">
        <div class="col-12 mb-2">
            <button id="addtrial" type="button" class="btn btn-primary marright" appAccess="Add - Edit Trial"
                (click)="getTrialSubscription()">Add
                New Trial</button>
            <button id="archivetoggle" type="button" class="btn btn-primary marright" appAccess="Archived Trial List"
                (click)="toggleShowAllTrials()">{{showAllTrialsLabel}}</button>
        </div>

    </div>

    <div class="contentnav">
        <div class="row">
            <div class="col-md-12 trialview">
                <p-dataView #dv [value]="trials" [layout]="initialView" filterBy="trialName" [sortField]="sortField"
                    [sortOrder]="sortOrder" [emptyMessage]="emptyRecordMsg">

                    <p-header>

                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="sortOptions" [(ngModel)]="sortKey"
                                placeholder="Sort By" (onChange)="onSortChange($event)"></p-dropdown>


                            <div class="d-flex flex-row column-gap-3 justify-content-end align-items-center">
                                <div class="wdsrch p-inputgroup">
                                    <input class="form-control" type="text" pInputText placeholder="Trial Name"
                                        (keyup)="dv.filter($event.target.value)" />
                                    <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
                                </div>

                                <p-dataViewLayoutOptions (click)="onLayoutChange($event, dv)"></p-dataViewLayoutOptions>
                            </div>
                        </div>


                        <div *ngIf="currentView === 'list'" class="d-flex flex-row listheader align-items-center">
                            <div class="col-sm-3">Sponsor</div>
                            <div class="col-sm-3">Trial Name</div>
                            <div class="col-sm-3">Primary Invesigator</div>
                            <div class="col-sm-2">Status</div>
                            <div class="col-sm-1 text-center">Actions</div>
                        </div>
                    </p-header>

                    <ng-template let-trial pTemplate="gridItem">
                        <div class="p-g-12 p-md-4 p-lg-4 gnav trialhomegrid">

                            <p-panel [style]="{'text-align':'center'}" class="gridpad">
                                <p-header [pTooltip]="trial.trialName" tooltipPosition="right">
                                    {{ trial.trialName | truncate:20 }}
                                </p-header>
                                <div id="trial{{trial.trialId}}" (click)="goToTrial(trial)" appAccess="Vendors"
                                    class="d-flex trialhomecontainer cursorpointer">
                                    <div class="col align-items-center tvendor_logo" id="divTrialLogo">
                                        <ng-container
                                            *ngIf="((trial.sponsorLogo !== undefined) && (trial.sponsorLogo !== '') && (trial.sponsorLogo !== null)); else noLogo">
                                            <img src="{{trial.sponsorLogo | imgurl}}" class="logo-cardsize"
                                                alt="Sponsor Logo" />
                                        </ng-container>
                                        <ng-template #noLogo>
                                            <img src="{{defaultSponsorLogo}}" class="logo-cardsize"
                                                alt="Sponsor Logo" />
                                        </ng-template>
                                        <div id="divTrialId">
                                            {{trial.protocolNumber}} / {{trial.siteNumber}}</div>
                                        <div [ngClass]="{
                                        'status-success':trial.trialStatusId===2,
                                        'status-secondary':(trial.trialStatusId===6 || trial.trialStatusId===7),
                                        'status-danger':trial.trialStatusId===5
                                        }">
                                            {{trial.trialStatusName}}</div>


                                        <div>{{trial.piUserName}}</div>
                                    </div>
                                </div>
                                <div class="gfbtnnav">
                                    <button id="edittrial{{trial.trialId}}" alt="Edit trial"
                                        appAccessHide="Add - Edit Trial" (click)="openEditTrial(trial)" class="editbtn">
                                        <fa name="pencil" pTooltip="Edit Trial" tooltipPosition="top"></fa>
                                    </button>
                                </div>
                            </p-panel>

                        </div>
                    </ng-template>

                    <ng-template let-trial pTemplate="listItem">
                        <div class="p-lg-12">
                            <div id="trial{{trial.trialId}}" role="button" class="d-flex flex-row align-items-center"
                                (click)=goToTrial(trial)>

                                <div class="col-sm-3">
                                    <ng-container
                                        *ngIf="((trial.sponsorLogo !== undefined) && (trial.sponsorLogo !== '') && (trial.sponsorLogo !== null)); else noLogo">
                                        <img class="logo-listsize" appAccess="Vendors"
                                            src="{{trial.sponsorLogo  | imgurl}}" alt="Sponsor Logo" />
                                    </ng-container>
                                    <ng-template #noLogo>
                                        <img class="logo-listsize" src="{{defaultSponsorLogo}}" alt="Sponsor Logo" />
                                    </ng-template>
                                </div>
                                <div class="col-sm-3">{{trial.trialName}}</div>
                                <div class="col-sm-3">{{trial.piUserName}}</div>
                                <div class="col-sm-2">

                                    {{trial.trialStatusName}}
                                </div>
                                <div class="col-sm-1 text-center">

                                    <button id="edittrial" alt="Edit trial" appAccessHide="Add - Edit Trial"
                                        (click)="$event.stopPropagation(); openEditTrial(trial)" class="listeditbtn">
                                        <fa name="pencil" pTooltip="Edit Trial" tooltipPosition="top"></fa>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-dataView>
            </div>
        </div>
    </div>

    <p-sidebar *ngIf="loadAddTrial" [visible]="loadAddTrial" [dismissible]="false" position="right" [baseZIndex]="10000"
        styleClass="p-sidebar-md">
        <app-add-trial (closeTrialnav)="closeModals($event)"></app-add-trial>
    </p-sidebar>

    <p-sidebar *ngIf="loadEditTrial" [visible]="loadEditTrial" [dismissible]="false" position="right"
        [baseZIndex]="10000" styleClass="p-sidebar-md">
        <app-edit-trial [trialId]="tid" (closeModal)="closeModals($event)">
        </app-edit-trial>
    </p-sidebar>

    <p-dialog header="Trial Subscription Alert" [visible]="displayTrialSubscriptionDialog" appendTo="body"
        [modal]="true" [closable]="false" [closeOnEscape]="true" [dismissableMask]="true"
        [style]="{width: '350px', minWidth: '200px'}" [maximizable]="false" [baseZIndex]="10000">
        <div class="spacer3"></div>
        <div class="form-group">
            <p>
                Your Freemium Subscription for 2 Trials has ended. Please <a id="addrequest" (click)="AddRequest()"
                    class="himg cursorpointer alink"><b>click
                        here</b> </a> to upgrade to a paid subscription.
            </p>
        </div>
        <div class="btnnav">
            <button id="cancel" type="button" (click)="closeTrialSubscription()" class="btn btn-primary">Cancel</button>
        </div>
    </p-dialog>
</div>