import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Message } from 'primeng/api';

import { AuthService } from '../auth.service';

import { AuthUser } from '../../users/model/authuser.model';
import { OktaSignIn } from '@okta/okta-signin-widget';
import { AppConstants } from 'src/app/common/app.constants';
import { RequestErrors } from 'src/app/common/utils';
import { EnvService } from 'src/app/env-service/env.service';
import { NavMenuService } from 'src/app/layout/components/nav-menu/nav-menu.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  widget = new OktaSignIn({
    issuer: environment.oktaConfig.issuer,
    clientId: environment.oktaConfig.clientId,
    redirectUri: environment.oktaConfig.redirectUri,
    helpLinks: {
      forgotPassword: `${environment.redirectUrl}/#/auth/forgot-password`,
    },
    features: {
      registration: false,
      rememberMe: true,
      router: false,
    },
  });

  pSso: boolean = AppConstants.Psso;
  _pSso: boolean;
  // -- ---------------------------SSO Declartion End------------------------------- -->
  public rememberMe: boolean;
  msgs: Message[];
  smsgs: Message[];

  submitted = false;
  authUser: AuthUser;

  returnUrl: string;
  redirectUrl: string;
  loading: boolean;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private envService: EnvService,
    private router: Router,
    private navMenuService: NavMenuService
  ) {}

  getYear() {
    return new Date().getUTCFullYear();
  }
  rememberUser(e) {
    this.rememberMe = e ? true : false;
  }

  renderWidget() {
    this.widget.showSignIn({ el: '#sign-in-widget' }).then(
      (res) => {
        this.lidoLogin(res);
      },
      (err) => {
        throw err;
      }
    );
  }

  lidoLogin(res) {
    const email = res.tokens.idToken.claims.email;
    this.authService.login(email, 'abc').subscribe(
      (data) => {
        this.loading = false;
        this.authUser = data;

        this.authService.setAuthUserSessionStorage(
          this.authUser,
          res.oktaUserId,
          this.rememberMe
        );

        this.navMenuService.setTrialState(false);
        if (
          this.authUser.isSignupVerified === false ||
          this.authUser.isProfileVerified === false
        ) {
          this.router.navigateByUrl('/layout/profile');
        } else {
          if (data.numberOfSites > 1) {
            this.navMenuService.setIsSiteHome(true);
            this.router.navigate(['/layout/sitehome']);
          } else {
            this.router.navigate(['/layout/home']);
          }
        }
      },
      (error) => {
        this.loading = false;
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
        setTimeout(() => {
          this.authService.logout();
        }, 7000);
      }
    );
  }

  ngOnInit() {
    sessionStorage.clear();
    this.loading = false;
    this._pSso = this.envService.Psso;
    sessionStorage.setItem('commonUrl', this.envService.apiUrl);
    const sessionInfo = this.route.snapshot.paramMap.get('sInf');
    if (sessionInfo !== undefined && sessionInfo !== null) {
      this.msgs = [
        {
          severity: 'warn',
          detail: sessionInfo,
        },
      ];
    }
    this.renderWidget();
  }
  ngOnDestroy() {
    this.widget.remove();
  }
}
