<p-dialog *ngIf="showConfirmRemoveUser" header="Remove Trial User" appendTo="body" [closable]="true"
    [visible]="showConfirmRemoveUser" showHeader="true" [modal]="false" [style]="{ width: '400px' }"
    [baseZIndex]="10000">
    <p>Remove {{selectedTrialUser.displayName}} from this trial?</p>
    <p-footer>
        <div class="btnnav">
            <button type="submit" (click)="closeConfirmRemoveTrialUser(true)" class="btn btn-primary marright">
                Yes
            </button>
            <button type="button" (click)="closeConfirmRemoveTrialUser(false)" class="btn btn-default">
                No
            </button>
        </div>
    </p-footer>
</p-dialog>

<div class="datatable">
    <p-table #dt [columns]="cols" [value]="trialUsers" dataKey="userId" editMode="row">
        <ng-template pTemplate="caption">

            <div class="wdsrch">
                Search Existing Site User
                <div class="p-inputgroup mb-3">
                    <input type="text" class="form-control" pInputText placeholder="Search"
                        (input)="dt.filterGlobal($event.target.value, 'contains')">
                    <span class="p-inputgroup-addon" id="basic-addon2"><i class="fa fa-search"></i></span>
                </div>
            </div>
            <div *ngIf="needPi" style="text-align: left;">
                A trial must have a principal investigator.
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns; let i = index;">
                    {{col.header}}
                </th>
                <th style="text-align: center;">Action</th>
                <th style="text-align: center;">Remove</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex" let-user>
            <tr [pEditableRow]="user">
                <td>
                    {{user.displayName}}
                </td>
                <td>
                    {{user.userTypeName}}
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="dd95" *ngIf="user.userTypeName === 'External User'; else notExternal">
                                <p-dropdown dropdownIcon="fa fa-caret-down" [options]="externalUserRoles"
                                    required="true" placeholder="Trial Role" dataKey="value" optionLabel="label"
                                    [(ngModel)]="user.userRole" [style]="{'width':'100%'}"></p-dropdown>
                            </div>
                            <ng-template #notExternal>
                                <div class="dd95">
                                    <p-dropdown dropdownIcon="fa fa-caret-down" [options]="userRoleItems"
                                        required="true" placeholder="Trial Role" dataKey="value" optionLabel="label"
                                        [(ngModel)]="user.userRole" [style]="{'width':'100%'}"></p-dropdown>
                                </div>

                            </ng-template>

                        </ng-template>
                        <ng-template pTemplate="output">
                            {{user.userRoleName}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="text-align:center">
                    <button *ngIf="!editing" type="button"
                        [disabled]="trial?.trialStatusId === 5 || trial?.trialStatusId === 7" pInitEditableRow
                        class="btn btn-inline-edit" (click)="onRowEditInit(user, ri)"><i id="menubutton"
                            class="pi pi-pencil"></i></button>
                    <div class="edit-buttons">
                        <button *ngIf="editing" type="button" [disabled]="user.userRole.value === 0" pSaveEditableRow
                            class="btn btn-inline-save" style="margin-right: .5em" (click)="onRowEditSave(user)"><i
                                id="menubutton" class="pi pi-check"></i></button>
                        <button *ngIf="editing" type="button" pCancelEditableRow class="btn btn-inline-cancel"
                            (click)="onRowEditCancel(ri)"><i id="menubutton" class="pi pi-times"></i></button>
                    </div>
                </td>
                <td style="text-align:center"><button type="button"
                        [disabled]="trial?.trialStatusId === 5 || trial?.trialStatusId === 7"
                        class="btn btn-inline-cancel" (click)="openRemoveUserFromSite(user)"><i id="menubutton"
                            class="pi pi-trash"></i></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <div style="height: 70px;" class="my-5">
            </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            {{emptyRecordMsg}}
        </ng-template>
    </p-table>
</div>