import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UserService } from './service/user.service';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';
import { ApiResponse } from '../core/apiresponse';
import { User } from './model/user.model';
import { AppConstants } from '../common/app.constants';
import { ResetPassword } from '../auth/forgot-password/model/resetpassword.model';
import { ForgotPasswordService } from '../auth/forgot-password/service/forgotpassword.service';
import {
  RequestErrors,
  makeBreadCrumbs,
  password_generator,
} from '../common/utils';
import { Menu } from 'primeng/menu';
import { UserPermissionService } from './service/userpermission.service';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [ConfirmationService],
})
export class UserListComponent implements OnInit, AfterViewInit {
  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));
  userId = Number(sessionStorage.getItem('userId'));
  RecsPerPage = AppConstants.dataListRecsPerPage;
  pageLinksPerPage = AppConstants.dataListPagerLinks;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  @ViewChild('menu') menu: Menu;
  @HostListener('click', ['$event'])
  documentClick(event): void {
    if (event.target.id !== 'menubutton') {
      this.menu.hide();
    }
  }

  pMenuItems: MenuItem[] = [];

  users: User[];
  user: User = null;
  selectedUser: User = null;
  cols = [
    { field: 'email', header: 'Email Address' },
    { field: 'displayName', header: 'Display Name' },
    { field: 'userStatusName', header: 'Login Status' },
    { field: 'userTypeName', header: 'User Type' },
  ];
  msgs: Message[] = [];
  apires: ApiResponse;

  showAddUser = false;
  showSelectUser = false;
  canDisableSelectUser: boolean;
  items = [];

  constructor(
    private userService: UserService,
    private userPermissionService: UserPermissionService,
    private forgotPasswordService: ForgotPasswordService,
    private confirmationService: ConfirmationService,
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit() {
    this.elementRef.nativeElement
      .querySelector('.b_container')
      .addEventListener('click', this.onClick.bind(this));
  }

  onClick(event) {
    if (event.target.id !== 'menubutton') {
      this.menu.hide();
    }
  }

  onShowMenu($event, user: User) {
    this.selectedUser = user;
    this.getMenuItems();
    this.menu.show($event);
  }

  getMenuItems() {
    if (this.selectedUser.userStatusId === 5) {
      // this user is blocked
      this.items = [
        {
          label: 'Remove User',
          command: () => {
            this.openRemoveUserFromSite();
          },
        },
      ];
    } else {
      this.items = [
        {
          label: 'Edit',
          command: () => {
            this.openEditUser();
          },
        },
        {
          label: 'Reset Password',
          command: () => {
            this.openResetPassword();
          },
        },
        {
          label: 'Remove User',
          command: () => {
            this.openRemoveUserFromSite();
          },
        },
      ];
    }
  }

  openAddUser() {
    this.user = new User({});
    this.showAddUser = true;
  }

  openSelectUser() {
    this.showSelectUser = true;
  }

  openResetPassword() {
    this.confirmationService.confirm({
      message: `Reset password for ${this.selectedUser.displayName}?`,
      header: 'Reset Password',
      accept: () => {
        this.msgs = [
          {
            severity: 'info',
            summary: 'Confirmed',
            detail: 'User will receive reset password email',
          },
        ];
        this.onResetPassword();
      },
    });
  }

  onResetPassword() {
    const newPassword = password_generator(8);
    const resetPassword: ResetPassword = {
      currentPassword: this.selectedUser.password,
      changedPassword: newPassword,
      sendEmail: true,
      userId: this.selectedUser.userId.toString(),
      requestType: 1,
    };
    this.forgotPasswordService.resetPassword(resetPassword).subscribe(
      (messageResponse) => {
        this.apires = messageResponse;
        this.msgs = [
          {
            severity: messageResponse.success === true ? 'success' : 'error',
            detail: this.apires.message,
          },
        ];
        this.selectedUser = null;
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return {
            severity: 'warn',
            detail: x,
          };
        });
      }
    );
  }

  openRemoveUserFromSite() {
    this.confirmationService.confirm({
      message: `Are you sure you want to remove ${this.selectedUser.displayName}`,
      header: 'Remove User',
      accept: () => {
        this.onRemoveUser();
      },
    });
  }

  onRemoveUser() {
    this.userPermissionService
      .removeUserFromSite(this.selectedUser.userId, this.siteId)
      .subscribe(
        (messageResponse) => {
          this.apires = messageResponse;
          if (this.apires.success === true) {
            this.selectedUser = null;
            this.closeModals(true);
          }
          if (this.apires.success === false) {
            this.msgs = [
              {
                severity: 'error',
                detail: this.apires.errorMessages[0],
              },
            ];
          }
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return {
              severity: 'warn',
              detail: x,
            };
          });
        }
      );
  }

  openEditUser() {
    this.user = new User(this.selectedUser);
    this.showAddUser = true;
  }

  ngOnInit() {
    this.pMenuItems = makeBreadCrumbs(false, 'Users');
    this.getUserlist();
  }

  getUserlist(): void {
    this.userService.getUsersForSite(this.siteId).subscribe(
      (usersFromService) => {
        this.users = usersFromService.filter((x) => x.userId !== this.userId);
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return {
            severity: 'warn',
            detail: x,
          };
        });
      }
    );
  }

  closeModals(refresh = false) {
    this.showAddUser = false;
    this.showSelectUser = false;
    if (refresh) {
      this.getUserlist();
    }
  }
}
