<div class="b_container">

    <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>

    <div class="contentnav messages">
        <p-messages [value]="msgs"></p-messages>
        <div class="datatable">
            <p-table [loading]="loading" #dt
                [globalFilterFields]="['userEventName','eventTypeName','requestFromUserName','eventStatusName']"
                [pageLinks]="pageLinksPerPage" [value]="userEvents" filterBy="createdOn,userEventName"
                [sortField]="sortField" [sortOrder]="sortOrder">
                <ng-template pTemplate="caption">
                    <div class="row column-gap-3 justify-content-between align-items-center">
                        <div class="wdsrch p-inputgroup">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="sortOptions" [(ngModel)]="sortKey"
                                placeholder="Sort By" (onChange)="onSortChange($event)"></p-dropdown>
                        </div>
                        <div class="wdsrch p-inputgroup">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" pInputText placeholder="Search"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')">
                                <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Alert Title</th>
                        <th>Alert Type</th>
                        <th>Originator</th>
                        <th>Status</th>
                        <th>Created On</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-userEvent>
                    <tr>
                        <td>
                            <a (click)="onEventClick(userEvent)">{{userEvent.userEventName}}</a>
                        </td>
                        <td>{{userEvent.eventTypeName}}</td>
                        <td>{{userEvent.requestFromUserName}}</td>
                        <td>{{userEvent.eventStatusName}}</td>
                        <td>{{userEvent.createdOn | date:'MM/dd/yyyy'}}</td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    {{emptyRecordMsg}}
                </ng-template>
            </p-table>
        </div>
    </div>

    <p-sidebar *ngIf="loadWorkflowRoutingNotification" [visible]="loadWorkflowRoutingNotification" [dismissible]="false"
        position="right" [baseZIndex]="10000" styleClass="p-sidebar-md">
        <div class="rtheader" [pTooltip]="workFlowTitle" tooltipPosition="right">
            {{workFlowTitle | truncate: 35}}
            <div class="closesidebar" (click)="closeModal(false)">X</div>
        </div>
        <app-workflow-routing-notification [userEvent]="selectedUserEvent" [showApproval]="true"
            (closeModal)="closeModal($event)" (displayDocument)="openDocumentViewer($event)">
        </app-workflow-routing-notification>
    </p-sidebar>

    <p-dialog #pdialog *ngIf="loadDocumentViewer" [style]="{width: '75%'}" [visible]="loadDocumentViewer" [modal]="true"
        [draggable]="false" [closable]="false" [baseZIndex]="10000">
        <p-header>
            {{ docName }}
        </p-header>
        <app-document-viewer [fileExtension]="docExt" [fileName]="docName" [docPath]="docPath"
            (closeModal)="closeDocumentViewer()"></app-document-viewer>
    </p-dialog>

</div>