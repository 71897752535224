import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBinderDocTypeString } from 'src/app/common/docutils';
import { ApiResponse } from 'src/app/core/apiresponse';
import { BinderDocumentType } from 'src/app/documents/model/document-add.model';
import { DocumentUpload } from 'src/app/documents/model/upload-document.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  uploadLogo(filename: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<ApiResponse>(
      `${environment.apiUrl}/api/document/UploadLogo/${filename}`,
      formData
    );
  }

  uploadDocument(documentUpload: DocumentUpload) {
    let headers = new HttpHeaders()
      .append('sid', documentUpload.siteId.toString())
      .append('fname', documentUpload.fileName)
      .append('docType', getBinderDocTypeString(documentUpload.documentType));

    if (
      documentUpload.folderId !== null &&
      documentUpload.folderId !== undefined
    ) {
      headers = headers.append('fid', documentUpload.folderId.toString());
    }

    if (documentUpload.documentType !== BinderDocumentType.sitedocument) {
      headers = headers.append('tid', documentUpload.trialId.toString());
    }

    if (documentUpload.documentType === BinderDocumentType.subjectdocument) {
      headers = headers.append(
        'subjectid',
        documentUpload.subjectId.toString()
      );
    }

    const uploadUrl = `${environment.apiUrl}/api/document/UploadDocumentFile`;
    const formData = new FormData();
    formData.append('file', documentUpload.file);

    return this.http.post<ApiResponse>(uploadUrl, formData, {
      headers: headers,
    });
  }

  getBlobUrl(filename: string, filepath: string, docType: string) {
    const url = `${environment.apiUrl}/api/document/GetDocumentUrl`;
    const headers = new HttpHeaders()
      .append('fname', filename)
      .append('fpath', filepath)
      .append('fext', docType);

    return this.http.get<ApiResponse>(url, {
      headers: headers,
    });
  }

  downloadFile(filename: string, filepath: string, docType: string) {
    const url = `${environment.apiUrl}/api/document/DownloadDocumentFile`;
    const headers = new HttpHeaders()
      .append('fname', filename)
      .append('fpath', filepath)
      .append('fext', docType);
    return this.http.get(url, {
      headers: headers,
      responseType: 'blob',
    });
  }
}
