import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/core/apiresponse';
import { AppsettingsService } from 'src/app/core/appsettings.service';
import { VendorUser } from '../model/vendoruser.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VendorUserService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  // Used by editvendor
  getVendorUser(
    vendorId: number,
    siteId: number,
    trialId: number
  ): Observable<VendorUser> {
    return this.http.get<VendorUser>(
      this.settings.baseUrl +
        'api/vendoruser/vendorId/' +
        vendorId +
        '/siteId/' +
        siteId +
        '/trialId/' +
        trialId
    );
  }

  // Used by linkVendor
  linkVendor(vendorUser: VendorUser) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/vendoruser',
      vendorUser,
      this.httpOptions
    );
  }

  editVendorUser(vendorUser: VendorUser) {
    return this.http.put<ApiResponse>(
      `${this.settings.baseUrl}api/vendoruser/${vendorUser.vendorUserId}`,
      vendorUser,
      this.httpOptions
    );
  }

  // Used to delete a vendoruser
  unlinkVendor(vendorUserId: number) {
    return this.http.delete<ApiResponse>(
      `${this.settings.baseUrl}api/vendoruser/${vendorUserId}`,
      this.httpOptions
    );
  }
}
