<div class="b_container">
    <div class="rtheader">
        {{headerLabel}}
        <div class="closesidebar" (click)="closeFolderModal()">X</div>
    </div>
    <div class="rtcontainer messages">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <p-messages [value]="msgs"></p-messages>

            <div class="row">
                <div class="col-md-12">
                    <div class="row my-3 align-items-center">
                        <label class="col-2">Folder Name</label>
                        <div class="col-8">
                            <input pInputText class="form-control" formControlName="folderName" type="text"
                                placeholder="Folder name" />
                            <span class="mandatory">*</span>
                        </div>
                        <div *ngIf="form.controls['folderName'].invalid && form.controls['folderName'].dirty"
                            class="alert alert-danger">
                            Folder Name is Required and must be at least 3 characters long
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="forminfo">
                        <ul>
                            <li><span class="mandatory">*</span> fields are mandatory</li>
                        </ul>
                    </div>
                </div>
                <div class="btnnav mt_15">
                    <button type="submit" [disabled]="!form.valid" class="btn btn-primary marright">Create</button>
                    <button type="button" (click)="closeFolderModal()" class="btn btn-default">Cancel</button>
                </div>



            </div>
        </form>
    </div>
</div>