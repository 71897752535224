import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SiteDocumentsComponent } from './site-documents.component';
import { SharedModule } from '../shared/shared.module';
import { DocumentRoutingModule } from '../document-routing/document-routing.module';
import { DocumentsModule } from '../documents/documents.module';

@NgModule({
  imports: [CommonModule, SharedModule, DocumentsModule, DocumentRoutingModule],
  declarations: [SiteDocumentsComponent],
  exports: [SiteDocumentsComponent],
})
export class SiteDocumentsModule {}
