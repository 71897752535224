import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ApiResponse } from '../../core/apiresponse';
import { Message } from 'primeng/api';
import { AppConstants } from '../../common/app.constants';
import { VendorUserService } from 'src/app/vendors/service/vendoruser.service';
import { RequestErrors } from 'src/app/common/utils';
import { VendorUser } from 'src/app/vendors/model/vendoruser.model';

@Component({
  selector: 'app-unlink-trial-vendor',
  templateUrl: './unlink-trial-vendor.component.html',
  styleUrls: ['./unlink-trial-vendor.component.scss'],
})
export class UnlinkTrialVendorComponent implements OnInit {
  tName: string;
  apires: ApiResponse;
  msgs: Message[] = [];
  defaultVendorLogo = AppConstants.defaultVendorLogo;
  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));
  trialName: string = sessionStorage.getItem('trialName');
  vendorUser: VendorUser;

  @Output() closeUnlinkVendornav = new EventEmitter<boolean>();

  @Input() vendorId: number;

  unlinkVendorForm: UntypedFormGroup;
  subBtnDisable: boolean;
  btnConfirm: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private vendorUserService: VendorUserService
  ) {}

  CancelUnlinkVendorNav(refresh = false) {
    this.closeUnlinkVendornav.emit(refresh);
  }

  onUnlink() {
    this.btnConfirm = 'Processing..';

    this.vendorUserService.unlinkVendor(this.vendorUser.vendorUserId).subscribe(
      (messageResponse) => {
        this.apires = messageResponse;
        this.btnConfirm = 'Confirm';
        if (this.apires.success === true) {
          this.CancelUnlinkVendorNav(true);
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            detail: this.apires.errorMessages[0],
          });
          this.subBtnDisable = false;
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
  ngOnInit() {
    this.getData();
    this.btnConfirm = 'Confirm';
    this.subBtnDisable = false;
    this.unlinkVendorForm = this.formBuilder.group({
      vendorName: [''],
    });

    this.tName = sessionStorage.getItem('trialName');
  }

  getData() {
    this.vendorUserService
      .getVendorUser(this.vendorId, this.siteId, this.trialId)
      .subscribe(
        (vendorUser) => {
          this.vendorUser = vendorUser;
        },
        (error) => {
          const errors = RequestErrors(error);
          this.msgs = errors.map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }
}
