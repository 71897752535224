import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';

import { AppsettingsService } from '../core/appsettings.service';
import { AuthUser } from '../users/model/authuser.model';
import { EncrDecrService } from '../EncrDecr/encr-decr.service';
import { environment } from 'src/environments/environment';
import { UserSite } from '../users/model/user-site.model';
import { ApiResponse } from '../core/apiresponse';
import { getCurrentUserRole, getCurrentUserType } from '../common/utils';
import { NavMenuService } from '../layout/components/nav-menu/nav-menu.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  redirectUrl: string = environment.oktaConfig.redirectUri;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  oktaAuth = new OktaAuth({
    issuer: environment.oktaConfig.issuer,
    clientId: environment.oktaConfig.clientId,
    redirectUri: environment.oktaConfig.redirectUri,
  });

  constructor(
    private settings: AppsettingsService,
    private http: HttpClient,
    private encrdcrService: EncrDecrService,
    private navMenuService: NavMenuService
  ) {}

  login(username: string, password: string): Observable<AuthUser> {
    return this.http.post<AuthUser>(
      this.settings.baseUrl + 'api/auth/requesttokenPsso',
      JSON.stringify({ username: username, password: password }),
      this.httpOptions
    );
  }

  // Called whenever a user changes site or trial to get
  // their current credentials, user type, and role
  regenerateTokenOnSiteChange(siteChange: UserSite) {
    return this.http.post<AuthUser>(
      this.settings.baseUrl + 'api/auth/RegenerateTokenOnSiteChange',
      siteChange,
      this.httpOptions
    );
  }

  // Note: NOT for logging users in - just for authenticating user when sigining a document
  // Will only authenticate the currently logged in user
  authenticateUser(username: string, password: string) {
    const body = {
      userName: username,
      password: password,
    };

    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/auth/Authenticate',
      body,
      this.httpOptions
    );
  }

  async logout(): Promise<void> {
    sessionStorage.clear();
    this.isLoggedIn = false;
    await this.oktaAuth.signOut();
  }

  setAuthUserSessionStorage(
    authUser: AuthUser,
    oktaUserId?: string,
    rememberMe = false
  ) {
    this.isLoggedIn = true;
    sessionStorage.clear();
    sessionStorage.setItem(
      'currentUser',
      JSON.stringify(authUser.securityToken)
    );
    sessionStorage.setItem('displayName', authUser.displayName);
    sessionStorage.setItem('siteId', authUser.siteId.toString());
    sessionStorage.setItem('trialId', authUser.trialId.toString());
    console.log(
      'setting siteId/trialid to ',
      authUser.siteId,
      '-',
      authUser.trialId
    );
    sessionStorage.setItem('siteName', authUser.siteName);
    sessionStorage.setItem('userId', authUser.userId.toString());
    sessionStorage.setItem('userTypeId', authUser.userTypeId.toString());
    sessionStorage.setItem('userRoleId', authUser.userRoleId.toString());
    console.log('user is type', getCurrentUserType());
    this.navMenuService.changeUserType(authUser.userTypeId);
    console.log('user is role', getCurrentUserRole());
    sessionStorage.setItem('numberOfSites', authUser.numberOfSites.toString());
    sessionStorage.setItem(
      'isSiteAdmin',
      authUser.userTypeId === 4 ? 'true' : 'false'
    );
    sessionStorage.setItem(
      'extUser',
      authUser.userTypeId === 2 ? 'true' : 'false'
    );
    sessionStorage.setItem(
      'sgv',
      this.encrdcrService.encryptData(
        authUser.isSignupVerified === true ? 'true' : 'false'
      )
    );
    sessionStorage.setItem(
      'isSignupVerified',
      authUser.isSignupVerified === true ? 'true' : 'false'
    );
    sessionStorage.setItem(
      'isProfileVerified',
      authUser.isProfileVerified === true ? 'true' : 'false'
    );
    sessionStorage.setItem(
      'tokenUserId',
      this.encrdcrService.encryptData(authUser.userId)
    );

    // If this isn't passed it is just a site change
    if (oktaUserId !== '') {
      sessionStorage.setItem('SsoUserId', oktaUserId);
    }
    if (rememberMe && rememberMe === true) {
      localStorage.setItem('username', authUser.userName);
    }
  }
}
