import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-doc-type-icon',
  templateUrl: './doc-type-icon.component.html',
  styleUrls: ['./doc-type-icon.component.scss'],
})
export class DocTypeIconComponent implements OnInit {
  @Input() documentType = '';
  @Input() size = '100'; // supposed to be number of px
  @Input() color = '';
  @Input() background = '';

  documentIcon = `assets/images/common/document-icon.png`;
  folderIcon = `assets/images/common/folder-icon.png`;

  displayType = 'pdf';
  imageTypes = ['jpg', 'gif', 'png', 'jpeg'];

  ngOnInit(): void {
    this.documentType = this.documentType.toLowerCase();
    if (this.imageTypes.includes(this.documentType)) {
      this.displayType = 'img';
    } else if (this.documentType === 'pdf') {
      this.displayType = 'pdf';
    } else if (this.documentType === 'doc' || this.documentType === 'docx') {
      this.displayType = 'doc';
    } else if (this.documentType === 'xls' || this.documentType === 'xlsx') {
      this.displayType = 'xls';
    } else if (this.documentType === 'ppt' || this.documentType === 'pptx') {
      this.displayType = 'xls';
    } else if (this.documentType === 'folder') {
      this.displayType = 'folder';
    }
  }
}
