import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppsettingsService } from '../../core/appsettings.service';
import { ApiResponse } from '../../core/apiresponse';

import { Subject } from '../model/subject.model';
import { SubjectStatusDropDown } from '../model/subjectstatus-dropdown.model';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  // Used in the subjectscomponent
  getSubjectsForTrial(siteId: number, trialId: number): Observable<Subject[]> {
    return this.http.get<Subject[]>(
      this.settings.baseUrl + `api/subject/${siteId}/${trialId}`
    );
  }

  // Get a subject for editing
  getBySubjectId(subjectId: number): Observable<Subject> {
    return this.http.get<Subject>(
      this.settings.baseUrl + 'api/subject/' + subjectId,
      this.httpOptions
    );
  }

  // Get Items for the status dropdown
  getSubjectStatusDropDown(): Observable<SubjectStatusDropDown[]> {
    return this.http.get<SubjectStatusDropDown[]>(
      this.settings.baseUrl + 'api/subjectstatus'
    );
  }

  // Add a patient to a trial
  addSubject(subject: Subject) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/subject/',
      subject,
      this.httpOptions
    );
  }

  // Remove a patient from a trial
  deleteSubject(subjectId: number) {
    return this.http.delete<ApiResponse>(
      this.settings.baseUrl + `api/subject/${subjectId}`,
      this.httpOptions
    );
  }

  updateSubject(subject: Subject) {
    return this.http.put<ApiResponse>(
      this.settings.baseUrl + 'api/subject/',
      subject,
      this.httpOptions
    );
  }
}
