import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { User } from '../model/user.model';
import { UserService } from '../service/user.service';
import { UserType } from '../model/usertype.model';
import { Message } from 'primeng/api';
import { UserPermissionDTO } from '../model/userpermission.dto';
import { ApiResponse } from 'src/app/core/apiresponse';
import { AppConstants } from 'src/app/common/app.constants';
import { RequestErrors } from 'src/app/common/utils';
import { UserPermissionService } from '../service/userpermission.service';

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
})
export class SelectUserComponent implements OnInit {
  @Output() closeSelectUser = new EventEmitter<boolean>();
  @Input() siteUsers: User[];
  users: User[];

  siteId = Number(sessionStorage.getItem('siteId'));
  siteName = sessionStorage.getItem('siteName');
  RecsPerPage = AppConstants.dataListRecsPerPage;
  pageLinksPerPage = AppConstants.dataListPagerLinks;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  selectedUsers: User[] = [];
  cols: Column[] = [
    { field: 'userId', header: 'User Id' },
    { field: 'displayName', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'userStatusName', header: 'Status' },
  ];
  userTypeSelections: UserType[];
  selectedUserType: UserType = null;
  msgs: Message[] = [];

  apires: ApiResponse;
  btnCreateShow = false;

  constructor(
    private userService: UserService,
    private userPermissionService: UserPermissionService
  ) {}

  closeModal(refresh = false) {
    this.closeSelectUser.emit(refresh);
  }

  onAddUsers() {
    this.msgs = [];
    if (this.selectedUserType === null) {
      this.msgs.push({
        severity: 'error',
        detail: 'Please select a user type',
      });
      return;
    }
    if (this.selectedUsers.length === 0) {
      this.msgs.push({
        severity: 'error',
        detail: 'Please select at least one user',
      });
      return;
    }

    const userPermissions: UserPermissionDTO[] = [];
    this.selectedUsers.forEach((x) => {
      userPermissions.push({
        siteId: this.siteId,
        siteName: this.siteName,
        userTypeId: this.selectedUserType.userTypeId,
        userTypeName: this.selectedUserType.userTypeName,
        userId: x.userId,
      });
    });
    this.userPermissionService.addUsersToSite(userPermissions).subscribe(
      (messageResponse) => {
        this.apires = messageResponse;
        if (this.apires.success === true) {
          this.closeModal(true);
        } else {
          this.msgs = [
            {
              severity: 'error',
              detail: this.apires.errorMessages[0],
            },
          ];
        }
      },
      (error) => {
        const errors = RequestErrors(error);
        this.msgs = errors.map((x) => {
          return {
            severity: 'warn',
            detail: x,
          };
        });
      }
    );
  }

  ngOnInit() {
    this.getUserTypes();
    this.getUserlist();
  }

  getUserTypes() {
    this.userService.getUserTypes().subscribe(
      (res) => {
        this.userTypeSelections = res.filter((x) => x.userTypeId > 1);
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  getUserlist(): void {
    this.userService.getUsersForAddToSite(this.siteId).subscribe(
      (users) => {
        this.users = users.map((x) => {
          x.isChecked = false;
          return x;
        });
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
