import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { RequestErrors } from 'src/app/common/utils';
import { Folder } from '../model/folder.model';
import { OperationsBinderService } from 'src/app/operations-binder/service/operationsbinder.service';
import { Message } from 'primeng/api';
import { OperationsBinderFolder } from 'src/app/operations-binder/model/operationsbinderfolder.model';
import { BinderDocumentType } from '../model/document-add.model';
import { SiteDocumentService } from 'src/app/site-documents/service/sitedocument.service';
import { SiteDocumentFolder } from 'src/app/site-documents/model/sitedocumentfolder.model';
import { RegulatoryBinderService } from 'src/app/regulatory-binder/service/regulatorybinder.service';
import { RegulatoryBinderFolder } from 'src/app/regulatory-binder/model/regulatorybinderfolder.model';
import { SubjectRecordsFolder } from 'src/app/subject/model/subjectdocumentfolder.model';
import { SubjectDocumentService } from 'src/app/subject/service/subjectdocument.service';
import { getBinderLabel } from 'src/app/common/docutils';

// TODO: Check that the folder name doesn't already exist
@Component({
  selector: 'app-add-folder',
  templateUrl: './add-folder.component.html',
  styleUrls: ['./add-folder.component.scss'],
})
export class AddFolderComponent implements OnInit {
  @Input() folder: Folder;
  @Output() closeModal = new EventEmitter<boolean>();
  form: UntypedFormGroup;
  headerLabel = 'Add Folder';
  msgs: Message[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private operationsBinderService: OperationsBinderService,
    private siteDocumentService: SiteDocumentService,
    private regulatoryBinderService: RegulatoryBinderService,
    private subjectDocumentService: SubjectDocumentService
  ) {}

  closeFolderModal(refresh = false) {
    this.closeModal.emit(refresh);
  }

  onSubmit() {
    this.msgs = [];
    if (this.folder.folderType === BinderDocumentType.operationsbinder) {
      this.addOperationsBinderFolder();
    } else if (this.folder.folderType === BinderDocumentType.sitedocument) {
      this.addSiteDocumentFolder();
    } else if (this.folder.folderType === BinderDocumentType.subjectdocument) {
      this.addSubjectRecordsFolder();
    } else if (this.folder.folderType === BinderDocumentType.regulatorybinder) {
      this.addRegulatoryBinderFolder();
    }
  }

  addSiteDocumentFolder() {
    const newFolder = new SiteDocumentFolder({
      siteDocumentFolderName: this.form.controls.folderName.value,
      isDeleted: false,
      parentFolderId: this.folder.parentFolderId,
      isArchived: false,
      siteId: this.folder.siteId,
    });
    this.siteDocumentService.addFolder(newFolder).subscribe(
      () => {
        this.closeFolderModal(true);
      },
      (error) => {
        const errors = RequestErrors(error);
        for (const msg of errors) {
          this.msgs.push({ severity: 'warn', detail: msg });
        }
      }
    );
  }

  addOperationsBinderFolder() {
    const newFolder = new OperationsBinderFolder({
      operationBinderFolderName: this.form.controls.folderName.value,
      isDeleted: false,
      parentFolderId: this.folder.parentFolderId,
      isArchived: false,
      siteId: this.folder.siteId,
      trialId: this.folder.trialId,
    });
    this.operationsBinderService.addFolder(newFolder).subscribe(
      () => {
        this.closeFolderModal(true);
      },
      (error) => {
        const errors = RequestErrors(error);
        for (const msg of errors) {
          this.msgs.push({ severity: 'warn', detail: msg });
        }
      }
    );
  }

  addSubjectRecordsFolder() {
    const newFolder = new SubjectRecordsFolder({
      subjectRecordsFolderName: this.form.controls.folderName.value,
      isDeleted: false,
      parentFolderId: this.folder.parentFolderId,
      isArchived: false,
      siteId: this.folder.siteId,
      trialId: this.folder.trialId,
      subjectId: this.folder.subjectId,
    });
    this.subjectDocumentService.addFolder(newFolder).subscribe(
      () => {
        this.closeFolderModal(true);
      },
      (error) => {
        const errors = RequestErrors(error);
        for (const msg of errors) {
          this.msgs.push({ severity: 'warn', detail: msg });
        }
      }
    );
  }

  addRegulatoryBinderFolder() {
    const newFolder = new RegulatoryBinderFolder({
      regulatoryBinderFolderName: this.form.controls.folderName.value,
      isDeleted: false,
      parentFolderId: this.folder.parentFolderId,
      isArchived: false,
      siteId: this.folder.siteId,
      trialId: this.folder.trialId,
    });
    this.regulatoryBinderService.addFolder(newFolder).subscribe(
      () => {
        this.closeFolderModal(true);
      },
      (error) => {
        const errors = RequestErrors(error);
        for (const msg of errors) {
          this.msgs.push({ severity: 'warn', detail: msg });
        }
      }
    );
  }

  ngOnInit() {
    const binderLabel = getBinderLabel(this.folder.folderType);
    this.headerLabel =
      this.folder.parentFolderName !== null
        ? `Add Subfolder - ${this.folder.parentFolderName}`
        : `Add Subfolder - ${binderLabel}`;

    this.form = this.formBuilder.group({
      folderName: ['', [Validators.required, Validators.minLength(3)]],
    });
  }
}
