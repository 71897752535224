<div class="b_container messages">
  <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
  <p-confirmDialog #cd>
    <div class="btnnav">
      <button class="btn btn-primary marright" type="button" (click)="cd.accept()">Yes<fa name="check"></fa>
      </button>
      <button class="btn btn-default" type="button" (click)="cd.reject()">No<fa name="times"></fa></button>
    </div>
  </p-confirmDialog>
  <p-messages [value]="msgs"></p-messages>
  <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>


  <div class="contentnav">

    <div class="datatable">

      <p-table #dt sortField="statusName" styleClass="p-datatable-lg" sortOrder="1" [columns]="cols" [value]="users">
        <ng-template pTemplate="caption">

          <div class="row">
            <div class="col-12 mb-2">
              <button type="button" class="btn btn-primary marright" (click)="openAddUser()">Add
                New User</button>
              <button type="button" class="btn btn-primary marright" (click)="openSelectUser()">Add
                Existing Users</button>
            </div>
          </div>
          <div class="wdsrch">
            Search Current Site Users
            <div class="p-inputgroup">
              <input class="form-control" type="text" pInputText placeholder="User Name"
                (input)="dt.filterGlobal($event.target.value, 'contains')" />
              <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
            </div>

          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns; let i = index;" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th>Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td>
              {{user.email}}
            </td>
            <td>
              {{user.displayName}}
            </td>
            <td>
              {{user.userStatusName}}
            </td>
            <td>
              {{user.userTypeName}}
            </td>
            <td><button class="edituser" (click)="onShowMenu($event, user)" appAccess="Site User Management"><i
                  id="menubutton" class="pi pi-ellipsis-v"></i></button></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          {{emptyRecordMsg}}
        </ng-template>

      </p-table>

    </div>
  </div>

  <p-sidebar *ngIf="showAddUser" [visible]="showAddUser" position="right" [blockScroll]="true" [dismissible]="false"
    [baseZIndex]="10000" styleClass="p-sidebar-md" blockScroll="false">
    <app-edit-user [user]="user" (closeAddUsernav)="closeModals($event)"></app-edit-user>
  </p-sidebar>

  <p-sidebar *ngIf="showSelectUser" [visible]="showSelectUser" position="right" [blockScroll]="true"
    [dismissible]="false" [baseZIndex]="10000" styleClass="p-sidebar-md" blockScroll="false">
    <app-select-user [siteUsers]="users" (closeSelectUser)="closeModals($event)"></app-select-user>
  </p-sidebar>

</div>