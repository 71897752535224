export class RegulatoryBinderFolder {
  regulatoryBinderFolderId?: number;
  regulatoryBinderFolderName?: string;
  isDeleted: boolean;
  isArchived: boolean;
  parentFolderId?: number;
  siteId?: number;
  trialId?: number;
  remarks?: boolean;

  constructor(defaultValues: Partial<RegulatoryBinderFolder>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }
}
