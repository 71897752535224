export class SiteDocumentFolder {
  siteDocumentFolderId?: number;
  siteDocumentFolderName?: string;
  isDeleted?: boolean;
  isArchived: boolean;
  parentFolderId?: number;
  siteId?: number;
  remarks?: boolean;

  constructor(defaultValues: Partial<SiteDocumentFolder>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }
}
