import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { RegulatoryBinderComponent } from './regulatory-binder.component';
import { DocumentRoutingModule } from '../document-routing/document-routing.module';
import { DocumentsModule } from '../documents/documents.module';

@NgModule({
  imports: [CommonModule, SharedModule, DocumentRoutingModule, DocumentsModule],
  declarations: [RegulatoryBinderComponent],
})
export class RegulatoryBinderModule {}
