<a href="#" id="alertmenu" class="dropdown-toggle" appcustomdropdown data-bs-toggle="dropdown" aria-haspopup="true"
  aria-expanded="false">
  <fa name="flag"></fa>
  <span class="badge badge-danger">{{itemCount}}</span>
  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="alertmenu">
    <div class="drpheader">
      INFORMATIONAL ALERTS
    </div>
    <div class="drpbody">
      <a (click)="onEventClick(item)" *ngFor="let item of items">
        {{item.userEventName}}
        <p>{{item.createdOn}}</p>
      </a>
    </div>
    <div class="drpfooter">
      <a (click)="onEventClick()">Show More Informational Alerts</a>
    </div>
  </div>
</a>