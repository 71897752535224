import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from '../../core/appsettings.service';
import { Observable } from 'rxjs';
import { HelpPriority } from '../model/HelpPriority';
import { HelpReason } from '../model/HelpReason';
import { HelpDetails } from '../model/HelpDetails';
import { HelpStatus } from '../model/HelpStatus';
import { FaqLibrary } from '../model/faqlibrary';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  getTopicDropDown(): Observable<FaqLibrary[]> {
    return this.http.get<FaqLibrary[]>(
      this.settings.baseUrl + 'api/FaqLibrary/GetTopicList'
    );
  }

  getFeatureDetails(): Observable<FaqLibrary[]> {
    return this.http.get<FaqLibrary[]>(
      this.settings.baseUrl + 'api/FaqLibrary/GetFeatureDetail'
    );
  }

  getHelpPriority(): Observable<HelpPriority[]> {
    return this.http.get<HelpPriority[]>(
      this.settings.baseUrl + 'api/FaqLibrary/GetHelpPriority/'
    );
  }

  getHelpReason(): Observable<HelpReason[]> {
    return this.http.get<HelpReason[]>(
      this.settings.baseUrl + 'api/FaqLibrary/GetHelpReason/'
    );
  }

  InsertHelpDetails(dto: HelpDetails) {
    return this.http.post(
      this.settings.baseUrl + 'api/FaqLibrary/InsertHelpDetails',
      dto
    );
  }

  getHelpDetailList(): Observable<HelpDetails[]> {
    return this.http.get<HelpDetails[]>(
      this.settings.baseUrl + 'api/FaqLibrary/GetHelpDetail'
    );
  }

  getHelpDetail(helpid: number): Observable<HelpDetails> {
    return this.http.get<HelpDetails>(
      this.settings.baseUrl + 'api/FaqLibrary/EditHelpDetail/' + helpid
    );
  }

  getHelpStatus(): Observable<HelpStatus[]> {
    return this.http.get<HelpStatus[]>(
      this.settings.baseUrl + 'api/FaqLibrary/GetHelpStatus/'
    );
  }

  updateHelpDetails(dto: HelpDetails) {
    return this.http.put(
      this.settings.baseUrl + 'api/FaqLibrary/UpdateHelpDetails',
      dto
    );
  }
}
