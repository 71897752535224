import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Message } from 'primeng/api';
import { ApiResponse } from '../../core/apiresponse';

import { UserService } from '../service/user.service';
import { User } from '../model/user.model';
import { UserType } from '../model/usertype.model';
import { RequestErrors } from 'src/app/common/utils';
import { UserPermissionService } from '../service/userpermission.service';
import { UserPermissionDTO } from '../model/userpermission.dto';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  @Input() user: User;
  @Output() closeAddUsernav = new EventEmitter<boolean>();

  userTypeSelections: UserType[];

  addingNew = false;
  pageTitle = 'Edit User';
  selectedUserType = [];
  form: UntypedFormGroup;
  siteName = sessionStorage.getItem('siteName');
  siteId = Number(sessionStorage.getItem('siteId'));
  msgs: Message[] = [];
  newUserType: UserType;
  apires: ApiResponse;

  showMainUserNav: boolean;
  loading = false;
  btnCreate: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private userPermissionService: UserPermissionService
  ) {}

  closeForm(refresh = false) {
    this.closeAddUsernav.emit(refresh);
  }

  onSubmit() {
    this.loading = true;
    this.btnCreate = 'Processing..';

    const user = new User(this.user);
    this.newUserType = this.form.controls.userTypeId.value;
    if (this.addingNew) {
      user.email = this.form.controls.email.value;
      user.userName = 'default';
      user.isSignupVerified = false;
      user.isProfileVerified = false;
      user.userStatusId = 1;
      user.isSiteAdmin = false;
      user.isExternalUser = false;
      user.siteId = this.siteId; // For adding user permissions
      user.siteName = this.siteName;
    }

    // Evaluate whether you need to save on edit
    const needToSaveUser =
      !this.addingNew &&
      (user.shortName !== this.form.controls.shortName.value ||
        user.displayName !== this.form.controls.displayName.value);
    const needToSaveUserType =
      !this.addingNew && this.user.userTypeId !== this.newUserType.userTypeId;

    user.shortName = this.form.controls.shortName.value;
    user.displayName = this.form.controls.displayName.value;
    user.userTypeId = this.newUserType.userTypeId;
    if (user.userTypeId === 4) {
      user.isSiteAdmin = true;
      user.isExternalUser = false;
    } else if (user.userTypeId === 2) {
      user.isExternalUser = true;
      user.isSiteAdmin = false;
    } else if (user.userTypeId === 3) {
      user.isExternalUser = false;
      user.isSiteAdmin = false;
    }

    if (this.addingNew) {
      this.userService.createUser(user).subscribe(
        (messageResponse) => {
          this.apires = messageResponse;
          if (this.apires.success === true) {
            this.closeForm(true);
          } else {
            this.loading = false;
            this.btnCreate = 'Create';
            this.msgs = [
              {
                severity: 'error',
                detail: this.apires.errorMessages[0],
              },
            ];
          }
        },
        (error) => {
          this.loading = false;
          this.btnCreate = 'Create';
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
    } else {
      // See if you need to save the user, or change the user type, or both

      const saves = [];
      if (needToSaveUser) {
        saves.push(this.userService.editUser(this.user.userId, user));
      }
      if (needToSaveUserType) {
        const userPermissions: UserPermissionDTO = {
          siteId: this.siteId,
          userId: this.user.userId,
          userTypeId: this.newUserType.userTypeId,
        };
        saves.push(this.userPermissionService.changeUserType(userPermissions));
      }

      forkJoin(saves).subscribe(
        (apiResponses: ApiResponse[]) => {
          this.msgs = [];
          let gotError = false;
          apiResponses.forEach((apiResponse) => {
            if (apiResponse.success === false) {
              gotError = true;
              const errorMessages = apiResponse.errorMessages.map((x) => {
                return { severity: 'warn', detail: x };
              });
              this.msgs = this.msgs.concat(errorMessages);
            } else {
              this.msgs.push({
                severity: 'success',
                detail: apiResponse.message,
              });
            }
          });
          if (!gotError) {
            this.closeForm(true);
          }
        },
        (error) => {
          this.loading = false;
          this.btnCreate = 'Update';
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
    }
  }

  ngOnInit() {
    this.initForm();
    this.addingNew = this.user.userId === undefined;
    this.btnCreate = !this.addingNew ? 'Update' : 'Create';
    this.pageTitle = !this.addingNew ? 'Edit User' : 'Add New User';
    this.showMainUserNav = true;
    this.getUserTypes();
  }

  initForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      shortName: ['', [Validators.required, Validators.minLength(3)]],
      displayName: ['', [Validators.required, Validators.minLength(3)]],
      userTypeId: ['', Validators.required],
    });
  }

  patchForm() {
    const userType = this.userTypeSelections.find(
      (x) => x.userTypeId === this.user.userTypeId
    );
    this.form.patchValue({
      email: this.user.email,
      shortName: this.user.shortName,
      displayName: this.user.displayName,
      userTypeId: userType,
    });

    if (!this.addingNew) {
      this.form.controls.email.disable();
    }
  }

  getUserTypes() {
    this.userService.getUserTypes().subscribe(
      (res) => {
        this.userTypeSelections = res.filter((x) => x.userTypeId !== 1);
        if (!this.addingNew) {
          this.patchForm();
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
