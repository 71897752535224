<header>
  <div class="fplogonav">
    <img class="logo" src="/assets/images/common/logo.svg">
  </div>
</header>

<div class="container fpcontainer messages">

  <div class="fpemailnav" *ngIf="showEmailNav">
    <form [formGroup]="verifyEmailForm" (ngSubmit)="onSubmit()">
      <div class="progress">
        <div class="progress-bar" role="progressbar" style="width:40%" aria-valuenow="40" aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">Identify</div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">Verify</div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">Complete</div>
      </div>

      <p-messages [value]="msgs"></p-messages>

      <div class="fpmainnav">
        <div class="hd">Enter your email address</div>
        <div class="formnav">
          <input type="email" pInputText class="form-control" formControlName="email" placeholder="Email address" />
          <div class="alert alert-danger"
            *ngIf="!verifyEmailForm.controls['email'].valid && verifyEmailForm.controls['email'].touched">
            Enter a valid Email Address
          </div>
        </div>
        <div class="btnnav">
          <button type="submit" [disabled]="!verifyEmailForm.valid" class="btn btn-primary marright">Continue</button>
          <button type="button" (click)="cancelForgotPassword()" class="btn btn-default">Cancel</button>
        </div>
      </div>
    </form>
  </div>



  <div class="fpotpnav" *ngIf="showOtpNav">
    <form [formGroup]="verifyOTPForm" (ngSubmit)="onSubmitOTP()">
      <div class="progress">
        <div class="progress-bar" role="progressbar" style="width:70%" aria-valuenow="70" aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">Identify</div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">Verify</div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">Complete</div>
      </div>

      <div class="msgnav">
        <p-messages [value]="msgs"></p-messages>
      </div>

      <div class="fpmainnav">
        <div class="hd">Enter one time password</div>
        <div class="formnav">
          <input type="text" pInputText class="form-control" formControlName="otp" placeholder="OTP" minlength="6"
            maxlength="6" pattern="^[0-9]{6,6}$" />
          <a class="resendotp" [hidden]="otpAttempts === 0" *ngIf="apires.success === false || resendOtpAttempts <= 0"
            (click)="resendOTP()">Resend OTP?</a>
          <input type="hidden" pInputText class="form-control" formControlName="uId" />
          <div class="alert alert-danger"
            *ngIf="!verifyOTPForm.controls['otp'].valid && verifyOTPForm.controls['otp'].touched">
            OTP is Required
          </div>
        </div>
        <div class="btnnav">
          <button type="submit" [disabled]="!verifyOTPForm.valid || resendOtpAttempts <= 0 || otpAttempts <= 0 "
            class="btn btn-primary marright">Continue</button>
          <button type="button" (click)="cancelForgotPassword()" class="btn btn-default">Cancel</button>
        </div>
      </div>
    </form>
  </div>


</div>

<footer class="footer">
  <div class="container-fluid text-right">
    <div class="d-flex flex-row justify-content-end">
      {{getYear()}} &copy; | <a href="http://lido.io" target="_blank">Clinical Trial Operating System</a>
    </div>
  </div>
</footer>