<div class="b_container">

    <div class="rtheader">
        {{ headerLabel }}
        <div class="closesidebar" (click)="closeModal()">X</div>
    </div>
    <div class="rtcontainer messages">

        <div>
            <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
                <p-messages [value]="msgs" id="scrollPanel"></p-messages>
                <div class="row">
                    <div class="col-md-12">

                        <div *ngIf="addDocumentData.inSubFolder && addDocumentData.folderList !== undefined; else displayFolder"
                            class="row my-3">
                            <div class="col-sm-12 dd95">
                                <p-dropdown dropdownIcon="fa fa-caret-down" [options]="folderOptions"
                                    formControlName="folderId" optionLabel="folderName"
                                    placeholder="Select"></p-dropdown>
                                <div class="alert alert-danger"
                                    *ngIf="!uploadForm.controls['folderId'].valid && uploadForm.controls['folderId'].dirty">
                                    Select the Folder
                                </div>
                            </div>
                        </div>
                        <ng-template #displayFolder>
                            <div class="row my-3">
                                <div class="col-sm-12">Folder Name: {{folderNameLabel}}</div>
                            </div>
                        </ng-template>
                        <div class="row my-3">
                            <div class="col-sm-12 fu">
                                <p-fileUpload #fileUpload (onClear)="onFileClear()" (onRemove)="onFileClear()"
                                    [showUploadButton]="false" maxFileSize="10485760" (onSelect)="onFileSelect($event)"
                                    chooseLabel="Choose" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                    invalidFileTypeMessageSummary="{0}: Invalid File Type"
                                    invalidFileTypeMessageDetail=" " customUpload="true">
                                    <ng-template pTemplate="content">
                                        <div class="droptarget">Drag and drop files here</div>
                                    </ng-template>
                                </p-fileUpload>
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">

                            <label class="col-2">File Name</label>
                            <div class="col-8">
                                <input pInputText id="fileName" class="form-control" type="text"
                                    formControlName="fileName" placeholder="File Name" />
                                <span class="mandatory">*</span>
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!uploadForm.controls['fileName'].valid && uploadForm.controls['fileName'].value !== ''">
                                File Name is Required and must be more than 3 characters and less than 40 characters.
                            </div>
                        </div>

                        <div *ngIf="showRoutingCheckbox" class="btnnav mt_15">
                            <input type="checkbox" [checked]="showWorkflowRouting"
                                (change)="RouteCheckBoxMethod($event)"
                                [disabled]="!gotFile || !uploadForm.controls['fileName'].valid" />
                            <span class="container">Route to LynK User?</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="forminfo">
                            <ul>
                                <li>
                                    <span class="mandatory">*</span> fields are mandatory
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div *ngIf="showWorkflowRouting">
                            <app-workflow-routing [binderDocumentType]="addDocumentData.documentType"
                                (updatedUserList)="updatedUserList($event)"></app-workflow-routing>
                        </div>
                        <div class="spacer"></div>
                        <div class="btnnav mt_15">
                            <button [disabled]="!gotFile || !uploadForm.valid"
                                class="btn btn-primary marright">Upload</button><i class="fa fa-spinner fa-spin"
                                *ngIf="showSpinner === true"></i>
                            <button type="button" (click)="closeModal()" class="btn btn-default">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>