import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AddFolderComponent } from './add-folder/add-folder.component';
import { AddDocumentsComponent } from './add-docs/add-docs.component';
import { WorkFlowRoutingModule } from '../workflow-routing/workflow-routing.module';

@NgModule({
  imports: [CommonModule, SharedModule, WorkFlowRoutingModule],
  declarations: [AddFolderComponent, AddDocumentsComponent],
  exports: [AddFolderComponent, AddDocumentsComponent],
})
export class DocumentsModule {}
