import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppsettingsService } from '../../core/appsettings.service';
import { ApiResponse } from '../../core/apiresponse';
import { Observable } from 'rxjs';

import { User } from '../model/user.model';
import { UserRole } from '../model/userrole.model';
import { UserType } from '../model/usertype.model';
import { SiteHome } from 'src/app/home/model/sitehome.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  // Used to get all sites for the logged in user
  getSiteHome(): Observable<SiteHome[]> {
    return this.http.get<SiteHome[]>(
      this.settings.baseUrl + 'api/user/GetSiteHome'
    );
  }

  // Get all users on a site
  getUsersForSite(siteId: number): Observable<User[]> {
    return this.http.get<User[]>(
      this.settings.baseUrl + 'api/user/SiteId/' + siteId
    );
  }

  // Get all db users who aren't on siteId site for add to site
  getUsersForAddToSite(siteId: number): Observable<User[]> {
    return this.http.get<User[]>(
      this.settings.baseUrl + 'api/user/UsersForAddToSite/SiteId/' + siteId
    );
  }

  // For editing site to allow user to change the site admin
  getAllActiveUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.settings.baseUrl + 'api/user');
  }

  // Used by addtrial to select the pi
  getUserDetailsforTrials(siteId: number): Observable<User[]> {
    return this.http.get<User[]>(
      this.settings.baseUrl +
        'api/user/GetUserDetailsForTrials/SiteId/' +
        siteId
    );
  }

  // Used by add-trialuser-list to get all site users who aren't already
  // part of trialid trial
  getNonPIUserDetailsforTrials(
    siteId: number,
    trialId: number
  ): Observable<User[]> {
    return this.http.get<User[]>(
      this.settings.baseUrl +
        'api/user/GetNonPIUserDetailsForTrials/SiteId/' +
        siteId +
        '/TrialId/' +
        trialId
    );
  }

  getWorkFlowRoutingUserListForTrials(
    siteId: number,
    trialId: number
  ): Observable<User[]> {
    return this.http.get<User[]>(
      this.settings.baseUrl +
        'api/user/GetWorkFlowRoutingUserListForTrials/SiteId/' +
        siteId +
        '/TrialId/' +
        trialId
    );
  }

  // This one is for when the site admin sends a password reset request
  resetPassword(resetPassword: User) {
    return this.http.patch<ApiResponse>(
      this.settings.baseUrl + 'api/user/ResetPassword',
      resetPassword,
      this.httpOptions
    );
  }

  // Used by edit profile
  getByUserId(userId: number): Observable<User> {
    return this.http.get<User>(this.settings.baseUrl + 'api/user/' + userId);
  }

  // Used to save changes to user
  editUser(userId: number, edituser: User) {
    return this.http.put<ApiResponse>(
      `${this.settings.baseUrl}api/user/${userId}`,
      edituser,
      this.httpOptions
    );
  }

  // Used to create a new user
  createUser(newuser: User) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/user',
      newuser,
      this.httpOptions
    );
  }

  // Used to get all user types
  getUserTypes(): Observable<UserType[]> {
    return this.http.get<UserType[]>(this.settings.baseUrl + 'api/usertype');
  }

  // Used to get all user roles
  getUserRoles(filterDeleted = false): Observable<UserRole[]> {
    const parm = filterDeleted ? 1 : 0;
    return this.http.get<UserRole[]>(
      this.settings.baseUrl + `api/UserRole/UserRoleDropdown/${parm}`
    );
  }
}
