import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppsettingsService } from '../../core/appsettings.service';
import { ApiResponse } from '../../core/apiresponse';

import { SiteDocument } from '../model/sitedocument.model';
import { SiteDocumentFolder } from '../model/sitedocumentfolder.model';
import { DocumentUpdate } from 'src/app/documents/model/document-update.model';
import { DocumentListRequest } from 'src/app/documents/model/document-request.model';
import { SiteDocumentTrialListItem } from '../model/sitedocumenttriallist.model';

@Injectable({
  providedIn: 'root',
})
export class SiteDocumentService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, private settings: AppsettingsService) {}

  addFolder(addCustomFolder: SiteDocumentFolder) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/sitedocumentfolder',
      addCustomFolder,
      this.httpOptions
    );
  }

  updateDocumentItem(documentUpdate: DocumentUpdate) {
    return this.http.patch<ApiResponse>(
      this.settings.baseUrl + 'api/sitedocument/update',
      documentUpdate,
      this.httpOptions
    );
  }

  getDocumentList(
    documentRequest: DocumentListRequest
  ): Observable<SiteDocument[]> {
    return this.http.post<SiteDocument[]>(
      this.settings.baseUrl + 'api/sitedocument/GetDocList',
      documentRequest,
      this.httpOptions
    );
  }

  getWorkflowDetails(workflowId: number): Observable<SiteDocument> {
    return this.http.get<SiteDocument>(
      this.settings.baseUrl +
        'api/sitedocument/GetWorkflowDetails/' +
        workflowId
    );
  }

  getSiteTrialList(siteId: number) {
    return this.http.get<SiteDocumentTrialListItem[]>(
      this.settings.baseUrl +
        'api/SiteDocumentFolder/GetSiteDocumentTrialsList/sid/' +
        siteId
    );
  }
}
