import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { User } from '../users/model/user.model';
import { UserService } from '../users/service/user.service';
import { Message } from 'primeng/api';
import { WorkFlowRouting } from './model/workflowrouting.model';
import { AppConstants } from '../common/app.constants';
import { RequestErrors, getCurrentUserRole } from '../common/utils';
import { BinderDocumentType } from '../documents/model/document-add.model';
import { TrialUserService } from '../trial/service/trialuser.service';

// Presents a list of users to route to
export interface RouteUser {
  displayName: string;
  email: string;
  userRole: string;
}

interface Column {
  field: string;
  header: string;
}
@Component({
  selector: 'app-workflow-routing',
  templateUrl: './workflow-routing.component.html',
  styleUrls: ['./workflow-routing.component.scss'],
})
export class WorkflowRoutingComponent implements OnInit {
  @Output() updatedUserList = new EventEmitter<User[]>();
  @Input() binderDocumentType: BinderDocumentType;
  public WorkFlowRouting: WorkFlowRouting;
  trialId = parseInt(sessionStorage.getItem('trialId'));
  siteId = parseInt(sessionStorage.getItem('siteId'));

  siteDocumentType = false;
  users: User[];
  colsSiteDocs: Column[];
  colsTrialDocs: Column[];
  msgs: Message[];
  radioVal: number;
  radioVal2: number;
  userId: number;
  RecsPerPage: number;
  pageLinksPerPage: number;
  emptyRecordMsg: string;
  userTrialRole: string = undefined;

  constructor(
    private userService: UserService,
    private trialUserService: TrialUserService
  ) {}

  ngOnInit() {
    this.RecsPerPage = AppConstants.trialUserListRecsPerPage;
    this.pageLinksPerPage = AppConstants.trialUserListPagerLinks;
    this.emptyRecordMsg = AppConstants.emptyRecordMsg;
    this.userTrialRole = getCurrentUserRole();

    this.getUsers();
    if (this.binderDocumentType === BinderDocumentType.sitedocument) {
      this.siteDocumentType = true;
      this.colsTrialDocs = [
        { field: 'displayName', header: 'Name' },
        { field: 'userTypeName', header: 'User Type' },
      ];
    } else {
      this.colsTrialDocs = [
        { field: 'displayName', header: 'Name' },
        { field: 'userRoleName', header: 'Trial User Type' },
      ];
    }
  }

  onClickCheckbox(user: User) {
    // If this is an external user, pretend like they are clicking
    // the alert only radio button
    /*     if (this.userTrialRole === 'External User') {
      this.onClickNotifyRadio(user);
    } */

    const selectedUser: User = this.users.find(
      (item) => item.userId === user.userId
    );
    // Already selected
    if (selectedUser.isWorkFlowUserSelected === true) {
      selectedUser.isWorkFlowUserSelected = false;
      selectedUser.isWorkFlowApproval = false;
      selectedUser.isWorkFlowNotification = false;
    } else {
      selectedUser.isWorkFlowUserSelected = true;
      selectedUser.isWorkFlowApproval = true;
      selectedUser.isWorkFlowNotification = false;
    }

    this.updatedUserList.emit(this.users);
  }

  onClickApproveRadio(user: User) {
    const selectedUser = this.users.find((item) => item.userId === user.userId);

    if (selectedUser.isWorkFlowApproval === true) {
      selectedUser.isWorkFlowApproval = false;
      selectedUser.isWorkFlowUserSelected = false;
      selectedUser.isWorkFlowNotification = false;
    }
    {
      selectedUser.isWorkFlowUserSelected = true;
      selectedUser.isWorkFlowNotification = false;
      selectedUser.isWorkFlowApproval = true;
    }
    this.updatedUserList.emit(this.users);
  }

  onClickNotifyRadio(user: User) {
    const selectedUser = this.users.find((item) => item.userId === user.userId);
    if (selectedUser.isWorkFlowNotification === true) {
      selectedUser.isWorkFlowUserSelected = false;
      selectedUser.isWorkFlowNotification = false;
      selectedUser.isWorkFlowApproval = false;
    } else {
      selectedUser.isWorkFlowUserSelected = true;
      selectedUser.isWorkFlowNotification = true;
      selectedUser.isWorkFlowApproval = false;
    }

    this.updatedUserList.emit(this.users);
  }

  getUsers() {
    if (this.binderDocumentType !== BinderDocumentType.sitedocument) {
      this.getTrialUsers();
    } else {
      this.getSiteUsers();
    }
  }

  getSiteUsers() {
    this.userService.getUsersForSite(this.siteId).subscribe((siteUsers) => {
      this.users = siteUsers
        .map((x) => {
          const user = new User({});
          user.userId = x.userId;
          user.email = x.email;
          user.displayName = x.displayName;
          user.userTypeName = x.userTypeName;
          user.userTypeId = x.userTypeId;
          return user;
        })
        .filter((x) => x.userTypeId !== 2);
    });
  }

  getTrialUsers() {
    this.trialUserService
      .getTrialUsers(this.trialId, this.siteId, true)
      .subscribe(
        (trialUsers) => {
          this.users = trialUsers
            .map((x) => {
              const user = new User({});
              user.userId = x.userId;
              user.email = x.email;
              user.displayName = x.displayName;
              user.userRoleName = x.userRoleName;
              user.UserRoleId = x.userRoleId;
              user.userTypeName = x.userTypeName;
              user.userTypeId = x.userTypeId;
              return user;
            })
            .filter((x) => x.userTypeId !== 2);
        },
        (error) => {
          const errors = RequestErrors(error);
          this.msgs = errors.map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }
}
