import { BinderDocumentType } from '../../documents/model/document-add.model';
import { User } from '../../users/model/user.model';

export class WorkFlowRouting {
  trialId?: number;
  trialName?: string;
  siteId?: number;
  RoutedToUserId?: number;
  documentName?: string;
  operationBinderDocsId?: number;
  regulatoryBinderDocsId?: number;
  subjectDocumentId?: number;
  siteDocumentId?: number;
  approvalRequired?: boolean;
}

export function createWorkflowRoutingList(
  workFlowUsers: User[],
  siteId: number,
  trialId: number,
  trialName: string,
  sourceFileId: number,
  binderDocumentType: BinderDocumentType,
  documentName: string
) {
  const users = [];
  if (workFlowUsers === undefined) {
    return [];
  }
  workFlowUsers.forEach((user) => {
    if (!user.isWorkFlowUserSelected) return;
    users.push({
      RoutedToUserId: user.userId,
      approvalRequired: user.isWorkFlowApproval,
    });
  });
  const workFlowRoutingList: WorkFlowRouting[] = [];
  users.forEach((user) => {
    workFlowRoutingList.push({
      RoutedToUserId: user.RoutedToUserId,
      operationBinderDocsId:
        binderDocumentType === BinderDocumentType.operationsbinder
          ? sourceFileId
          : null,
      regulatoryBinderDocsId:
        binderDocumentType === BinderDocumentType.regulatorybinder
          ? sourceFileId
          : null,
      subjectDocumentId:
        binderDocumentType === BinderDocumentType.subjectdocument
          ? sourceFileId
          : null,
      siteDocumentId:
        binderDocumentType === BinderDocumentType.sitedocument
          ? sourceFileId
          : null,
      siteId: siteId,
      trialId: trialId,
      trialName: trialName,
      approvalRequired: user.approvalRequired,
      documentName: documentName,
    });
  });
  return workFlowRoutingList;
}
