<header>
    <div class="fplogonav">
        <a routerLink="/login"> <img class="logo" src="/assets/images/common/logo.svg"></a>
    </div>
</header>

<div class="container fpcontainer">
    <div class="progress">
        <div class="progress-bar" role="progressbar" style="width:25%" aria-valuenow="25" aria-valuemin="0"
            aria-valuemax="100"></div>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">Identify</div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">Verify</div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">Complete</div>
    </div>

    <div class="msgnav">
    </div>

    <div class="fpmainnav">
        <div class="hd">Retrieve your password using</div>
        <div class="linknav">
            <ul>
                <li><a routerLink="/auth/forgot-password-email">OTP ( A One-Time Password will be sent to your email
                        address.)</a></li>
                <li><a routerLink="/auth/forgot-password-secquestions">Security Questions</a></li>
            </ul>
        </div>
        <div class="btnnav">
            <button type="button" (click)="cancelForgotPassword()" class="btn btn-default">Cancel</button>
        </div>
    </div>


</div>

<footer class="footer">
    <div class="container-fluid text-right">
        <div class="d-flex flex-row justify-content-end">
            {{getYear()}} &copy; | <a href="http://lido.io" target="_blank">Clinical Trial Operating System</a>
        </div>
    </div>
</footer>