export const environment = {
  production: true,
  apiUrl: 'https://lynkprod.azurewebsites.net/services',
  redirectUrl: 'https://app.lido.io',
  imageContainer: 'https://lynkimages.blob.core.windows.net',
  oktaConfig: {
    issuer: 'https://lido-io.okta.com',
    clientId: '0oa2f8jsoEUHQUGNN4x6',
    redirectUri: 'https://app.lido.io',
  },
};
