import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppsettingsService } from '../../core/appsettings.service';
import { Observable } from 'rxjs';
import { SecurityQuestion } from '../model/securityquestion.model';
import { SecurityQuestionDTO } from '../model/securityquestionDto.model';

@Injectable({
  providedIn: 'root',
})
export class SecurityQuestionService {
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  getSecurityQuestion(): Observable<SecurityQuestion[]> {
    return this.http.get<SecurityQuestion[]>(
      this.settings.baseUrl + 'api/securityquestion'
    );
  }
  securityQuestionDropDown(): Observable<SecurityQuestionDTO[]> {
    return this.http.get<SecurityQuestionDTO[]>(
      this.settings.baseUrl + 'api/securityquestion/SecurityQuestionDropDown'
    );
  }
}
