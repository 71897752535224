export class TrialUser {
  trialUserId?: number;
  trialId?: number;
  trialName?: string;
  displayName?: string;
  userId?: number;
  siteId?: number;
  userRoleId?: number;
  userRoleName?: string;
  userTypeName?: string;
  email?: string;
  userTypeId?: number;
  isIRBapproved?: boolean;
  IsGCPTrainingCompleted?: boolean;
  PreviousPiUserId?: number;
  PreviousPiRoleId?: number;
  allowAccessToArchivedTrial?: boolean;
  IsUserAddedAtArchivedStatus?: boolean;

  constructor(defaultValues: Partial<TrialUser>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }
}
