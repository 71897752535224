import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem, ConfirmationService, Message } from 'primeng/api';

import { OperationsBinderService } from './service/operationsbinder.service';
import { OperationsBinderDocument } from './model/operationsbinderdocument.model';
import { ApiResponse } from 'src/app/core/apiresponse';
import { AppConstants } from 'src/app/common/app.constants';
import { EncrDecrService } from 'src/app/EncrDecr/encr-decr.service';
import { Folder } from 'src/app/documents/model/folder.model';
import {
  AddDocument,
  BinderDocumentType,
} from '../documents/model/document-add.model';
import {
  RequestErrors,
  getCurrentUserRole,
  makeBreadCrumbsDocuments,
} from 'src/app/common/utils';
import { Menu } from 'primeng/menu';
import { AppAccessService } from 'src/app/core/directives/access.service';
import { Dialog } from 'primeng/dialog';
import { DocumentListRequest } from 'src/app/documents/model/document-request.model';
import { DocumentUpdate } from 'src/app/documents/model/document-update.model';
import {
  createDocumentBreadCrumbs,
  sortDocumentList,
} from '../common/docutils';

@Component({
  selector: 'app-operations-binder',
  templateUrl: './operations-binder.component.html',
  styleUrls: ['./operations-binder.component.scss'],
  providers: [ConfirmationService],
})
export class OperationsBinderComponent implements OnInit {
  @ViewChild('menu') menu: Menu;
  @HostListener('click', ['$event'])
  documentClick(event): void {
    if (event.target.id !== 'menubutton') {
      this.menu.hide();
    }
  }

  dotMenuActions: MenuItem[]; // for the popup menu
  pMenuItems: MenuItem[] = [];

  siteId = parseInt(sessionStorage.getItem('siteId'));
  trialId = parseInt(sessionStorage.getItem('trialId'));
  trialArchived = false;
  atRoot = true;
  loadAddDocument = false;
  loadAddFolder = false;
  loadDocumentViewer = false;
  loadDocumentRouting = false;
  folderId: number = null;
  folderIsArchived = false;
  parentIsArchived = false;
  folderName = '';
  apires: ApiResponse;
  // All documents and subfolders of this folder
  documentList: OperationsBinderDocument[];
  selectedDocument: OperationsBinderDocument;
  documentType = BinderDocumentType.operationsbinder;
  componentRoot = `/layout/operations-binder`;
  folder: Folder;
  addDocumentData: AddDocument;
  sortOptions = [
    { label: 'Newest Item', value: { field: 'modifiedOn', order: 1 } },
    { label: 'Oldest Item', value: { field: 'modifiedOn', order: -1 } },
    { label: 'Item Name A to Z', value: { field: 'name', order: 1 } },
    { label: 'Item Name Z to A', value: { field: 'name', order: -1 } },
  ];

  sortKey: string;
  sortField: string;
  sortOrder: number;
  msgs: Message[] = [];
  showArchivedFolders = false;
  currentView = AppConstants.documentFlowView;
  recsPerPage = AppConstants.docListRecsPerPage;
  pageLinksPerPage = AppConstants.docListPagerLinks;
  emptyRecordMsg = AppConstants.emptyRecordMsg;

  userRole = '';

  archivedFoldersLabel = 'Show Archived Items';
  url: string;
  fileName: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private documentService: OperationsBinderService,
    private confirmationService: ConfirmationService,
    private encrDecrService: EncrDecrService,
    private appAccessService: AppAccessService
  ) {}

  onLayoutChange($event, dv) {
    if (dv.layout === 'list') {
      this.recsPerPage = AppConstants.docListRecsPerPage;
      this.currentView = 'list';
    } else {
      this.recsPerPage = 10; //AppConstants.docGridRecsPerPage;
      this.currentView = 'grid';
    }
  }

  confirmAction(action: string) {
    // This is either archive or delete
    const item =
      this.selectedDocument.isDocument === true
        ? this.selectedDocument.operationBinderDocsName
        : this.selectedDocument.operationBinderFolderName;
    let actionLabel;
    if (action === 'deleteToggle') {
      actionLabel = 'delete';
    } else {
      actionLabel =
        this.selectedDocument.isArchived === true ? 'unarchive' : 'archive';
    }
    const confirmMessage = `Are you sure that you want to ${actionLabel} ${item}`;
    this.confirmationService.confirm({
      message: confirmMessage,
      header: 'Confirmation',
      accept: () => this.updateDocument(actionLabel),
    });
  }

  docFlowLog() {
    this.router.navigate(['/layout/doc-flow-log'], {
      queryParams: {
        documentType: this.encrDecrService.encryptData(this.documentType),
      },
    });
  }

  updateDocument(action: string) {
    const id = this.selectedDocument.isDocument
      ? this.selectedDocument.operationBinderDocsId
      : this.selectedDocument.operationBinderFolderId;
    const documentUpdate: DocumentUpdate = {
      itemId: id,
      actionType: action,
      isDocument: this.selectedDocument.isDocument,
      documentType: this.documentType,
    };

    this.documentService.updateDocumentItem(documentUpdate).subscribe(
      (messageResponse) => {
        if (messageResponse.success === true) {
          this.refresh();
        } else {
          this.msgs = [
            { severity: 'error', detail: this.apires.errorMessages[0] },
          ];
        }
      },
      (error) => {
        const errors = RequestErrors(error);
        this.msgs = errors.map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  onShowMenu($event, document: OperationsBinderDocument) {
    // External users can add folders & docs and route for notification only,
    // they can't archive or delete
    this.selectedDocument = document;
    const labelType = this.selectedDocument.isDocument ? 'Document' : 'Folder';
    this.dotMenuActions = [];
    if (
      this.selectedDocument.isArchived === false &&
      !this.selectedDocument.isDocument
    ) {
      this.dotMenuActions.push({
        label: 'Add Document',
        icon: 'fa fa-plus',
        visible: this.appAccessService.hasAccess('Add/Edit Docs'),
        command: () =>
          this.openAddDocument(this.selectedDocument.operationBinderFolderId),
      });
      this.dotMenuActions.push({
        label: 'Add Subfolder',
        icon: 'fa fa-plus',
        visible: this.appAccessService.hasAccess('Add/Edit Docs'),
        command: () =>
          this.openAddFolder(this.selectedDocument.operationBinderFolderId),
      });
    }

    if (this.userRole !== 'External User') {
      if (
        !this.showArchivedFolders ||
        (this.showArchivedFolders && !this.parentIsArchived)
      ) {
        this.dotMenuActions.push({
          label:
            this.selectedDocument.isArchived === true
              ? 'Unarchive ' + labelType
              : 'Archive ' + labelType,
          icon: 'fa fa-eye-slash',
          visible: this.appAccessService.hasAccess('Add/Edit Docs'),
          command: () => this.confirmAction('archiveToggle'),
        });
      }

      this.dotMenuActions.push({
        label: 'Delete ' + labelType,
        icon: 'fa fa-trash',
        visible: this.appAccessService.hasAccess('Add/Edit Docs'),
        command: () => this.confirmAction('deleteToggle'),
      });
    }

    if (
      this.selectedDocument.isDocument &&
      this.selectedDocument.isArchived === false &&
      this.userRole !== undefined
    ) {
      this.dotMenuActions.push({
        label: 'Route',
        icon: 'fa fa-share-square',
        visible: this.appAccessService.hasAccess('Add/Edit Docs'),
        command: () => (this.loadDocumentRouting = true),
      });
    }
    this.menu.show($event);
  }

  closeModals(refresh = false) {
    this.loadAddDocument = false;
    this.loadAddFolder = false;
    this.loadDocumentViewer = false;
    this.loadDocumentRouting = false;
    if (refresh) {
      this.getData();
    }
  }

  openAddDocument(folderId?: number) {
    // If they click on the Add doc button at the top and atRoot, folderId is null
    // if they click on the add doc button at the top and not atRoot, folderId is whatever folder you're on
    // If they click on the dots menu to add a doc to a folder, use that folderId
    let paramFolderId = null;
    let paramFolderName = null;
    let inSubfolder = false;

    if (folderId !== undefined) {
      inSubfolder = true;
      paramFolderId = folderId;
      paramFolderName = this.selectedDocument.operationBinderFolderName;
    } else if (this.atRoot === false) {
      inSubfolder = true;
      paramFolderId = this.folderId;
      paramFolderName = this.folderName;
    }

    this.addDocumentData = {
      documentType: this.documentType,
      inSubFolder: inSubfolder,
      folderId: paramFolderId,
      folderName: paramFolderName, // this will be null for root
      siteId: this.siteId,
      trialId: this.trialId,
    };
    this.loadAddDocument = true;
  }

  openAddFolder(folderId?: number) {
    let paramFolderId = null;
    let paramFolderName = null;
    let isSubFolder = false;
    if (folderId !== undefined) {
      paramFolderId = this.selectedDocument.operationBinderFolderId;
      paramFolderName = this.selectedDocument.operationBinderFolderName;
      isSubFolder = true;
    } else if (this.atRoot === false) {
      paramFolderId = this.folderId;
      paramFolderName = this.folderName;
      isSubFolder = true;
    }
    this.folder = new Folder({
      folderType: this.documentType,
      isDeleted: false,
      isArchived: false,
      isSubFolder: isSubFolder, // False if you're adding at root
      parentFolderId: paramFolderId,
      parentFolderName: paramFolderName,
      siteId: this.siteId,
      trialId: this.trialId,
    });
    this.loadAddFolder = true;
  }

  refresh() {
    this.getData();
  }

  // Using this to reroute to self to go to a child folder
  onDocClick(document: OperationsBinderDocument) {
    if (document.isDocument === true) {
      this.selectedDocument = document;
      this.loadDocumentViewer = true;
    } else {
      let folderpath = this.atRoot ? '' : sessionStorage.getItem('folderpath');
      folderpath =
        folderpath +
        `/${document.operationBinderFolderId}:${document.operationBinderFolderName}:${document.isArchived}`;
      sessionStorage.setItem('folderpath', folderpath);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(
          [`${this.componentRoot}`, document.operationBinderFolderId],
          {
            queryParams: {
              fName: document.operationBinderFolderName,
              showArchived: this.showArchivedFolders ? 'true' : 'false',
            },
          }
        )
      );
    }
  }

  showDialogMaximized(dialog: Dialog) {
    dialog.maximize();
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.userRole = getCurrentUserRole();

    const trialStatusId = sessionStorage.getItem('trialStatusId');
    this.trialArchived =
      trialStatusId === '5' || trialStatusId === '7' ? true : false;
    this.pMenuItems = makeBreadCrumbsDocuments(this.documentType);
    this.route.queryParams.subscribe((queryParams) => {
      const folderIdStr = this.route.snapshot.paramMap.get('id');
      if (folderIdStr !== null) {
        const folderId = parseInt(folderIdStr);
        const docInfo = createDocumentBreadCrumbs(
          folderId,
          queryParams,
          this.componentRoot
        );
        this.folderId = folderId;
        this.atRoot = docInfo === null;
        this.folderName = docInfo.folderName;

        this.pMenuItems = this.pMenuItems.concat(docInfo.menuItems);
        this.showArchivedFolders = docInfo.showArchivedFolders;
        this.parentIsArchived = docInfo.parentIsArchived;
        this.archivedFoldersLabel = this.showArchivedFolders
          ? 'Hide Archived Items'
          : 'Show Archived Items';
      } else {
        sessionStorage.removeItem('folderpath');
      }
      this.getData();
    });
  }

  toggleShowArchived() {
    this.showArchivedFolders = !this.showArchivedFolders;
    this.archivedFoldersLabel = this.showArchivedFolders
      ? 'Hide Archived Items'
      : 'Show Archived Items';
    this.getData();
  }

  onSortChange(event) {
    this.sortField = event.value.field;
    this.sortOrder = event.value.order;
    this.documentList = sortDocumentList(
      this.documentList,
      this.documentType,
      this.sortField,
      this.sortOrder
    );
  }

  getData() {
    const request: DocumentListRequest = {
      documentType: this.documentType,
      siteId: this.siteId,
      trialId: this.trialId,
      showArchived: this.showArchivedFolders,
      folderId: this.atRoot ? null : this.folderId,
    };
    this.documentService.getDocumentList(request).subscribe(
      (data) => {
        this.documentList = data.map((x) => {
          const record = { ...x };
          record.filterBy = x.isDocument
            ? x.operationBinderDocsName
            : x.operationBinderFolderName;
          return record;
        });
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
