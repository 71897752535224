import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppsettingsService } from '../../core/appsettings.service';

import { ApiResponse } from '../../core/apiresponse';
import { Observable } from 'rxjs';
import { TrialUser } from 'src/app/trial/model/trialuser.model';

@Injectable({
  providedIn: 'root',
})
export class TrialUserService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  // Used by edit-pi-user to to select a new pi and trialuser-list to
  // see all current trial users
  getTrialUsers(
    trialId: number,
    siteId: number,
    includePi = false
  ): Observable<TrialUser[]> {
    return this.http.get<TrialUser[]>(
      `${this.settings.baseUrl}api/TrialUser/trialId/${trialId}/siteId/${siteId}/${includePi}`
    );
  }

  addTrialUser(TrialUser: TrialUser) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/TrialUser/AddTrialUser',
      TrialUser,
      this.httpOptions
    );
  }

  editTrialUser(trialUsers: TrialUser[]) {
    return this.http.put<ApiResponse>(
      this.settings.baseUrl + `api/TrialUser`,
      trialUsers,
      this.httpOptions
    );
  }

  removeTrialUser(TrialUserId: number) {
    return this.http.delete<ApiResponse>(
      `${this.settings.baseUrl}api/TrialUser/${TrialUserId}`,
      this.httpOptions
    );
  }
}
