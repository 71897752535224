import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from '../../core/appsettings.service';
import { DocumentFlowLogData } from '../Model/documentflowlogdata.model';
import { BinderDocumentType } from '../../documents/model/document-add.model';
import { AppConstants } from 'src/app/common/app.constants';
@Injectable({
  providedIn: 'root',
})
export class DocFlowLogService {
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  getDocFlowLogData(
    documentType: BinderDocumentType,
    siteId: number,
    trialId?: number,
    subjectId?: number
  ) {
    let url = '';
    switch (documentType) {
      case BinderDocumentType.operationsbinder:
        url = `${AppConstants.OperationBinderDocFlowUrl}/siteId/${siteId}/trialId/${trialId}`;
        break;
      case BinderDocumentType.sitedocument:
        url = `${AppConstants.SiteDocumentDocFlowUrl}/siteId/${siteId}`;
        break;
      case BinderDocumentType.regulatorybinder:
        url = `${AppConstants.RegulatoryBinderDocFlowUrl}/siteId/${siteId}/trialId/${trialId}`;
        break;
      case BinderDocumentType.subjectdocument:
        url = `${AppConstants.SubjectRecordDocFlowUrl}/siteId/${siteId}/trialId/${trialId}/subjectId/${subjectId}`;
        break;
    }

    return this.http.get<DocumentFlowLogData[]>(url);
  }
}
