import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppsettingsService } from 'src/app/core/appsettings.service';
import { FactLidoUserEvents } from '../model/notifications.model';
import { ApiResponse } from 'src/app/core/apiresponse';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  getHeaderNotificationList(
    eventType: string,
    siteId: number,
    numberOfAlerts = 5
  ): Observable<FactLidoUserEvents[]> {
    return this.http.get<FactLidoUserEvents[]>(
      `${this.settings.baseUrl}api/FactLidoUserEvents/GetHeaderNotificationList/${eventType}/siteId/${siteId}/${numberOfAlerts}`
    );
  }

  getUserNotifications(
    eventType: string,
    siteId: number
  ): Observable<FactLidoUserEvents[]> {
    return this.http.get<FactLidoUserEvents[]>(
      this.settings.baseUrl +
        'api/FactLidoUserEvents/GetUserEvents/' +
        eventType +
        '/siteId/' +
        siteId
    );
  }

  updateDocumentNotification(factLidoEvent: FactLidoUserEvents) {
    return this.http.put<ApiResponse>(
      this.settings.baseUrl +
        'api/FactLidoUserEvents/UpdateDocumentNotification',
      factLidoEvent,
      this.httpOptions
    );
  }

  markAlertsAsViewed(updateIds: string) {
    const body = JSON.stringify(updateIds);
    return this.http.patch<ApiResponse>(
      this.settings.baseUrl + 'api/FactLidoUserEvents/MarkAlertsAsViewed',
      body,
      this.httpOptions
    );
  }
}
