import { AbstractControl, ValidationErrors } from '@angular/forms';

export function urlValidator({
  value,
}: AbstractControl): null | ValidationErrors {
  try {
    new URL(value);
    return null;
  } catch {
    return { pattern: true };
  }
}
