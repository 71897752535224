import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppsettingsService } from 'src/app/core/appsettings.service';
import { WorkFlowRouting } from '../model/workflowrouting.model';

import { ApiResponse } from 'src/app/core/apiresponse';
import { CopyAndRoute } from 'src/app/document-routing/model/copy-and-route.model';
import { BinderDocumentType } from 'src/app/documents/model/document-add.model';
import { AppConstants } from 'src/app/common/app.constants';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  getTreeFolderList(
    binderDocumentType: BinderDocumentType,
    siteId: number,
    trialId?: number
  ) {
    let url: string;
    switch (binderDocumentType) {
      case BinderDocumentType.operationsbinder:
        url = `${AppConstants.OperationBinderDocFolderListUrl}${siteId}/trialId/${trialId}`;
        break;
      case BinderDocumentType.sitedocument:
        url = `${AppConstants.SiteDocFolderListUrl}${siteId}`;
        break;
      case BinderDocumentType.regulatorybinder:
        url = `${AppConstants.RegulatoryBinderDocFolderListUrl}${siteId}/trialId/${trialId}`;
        break;
      case BinderDocumentType.subjectdocument:
        url = `${AppConstants.SubjectDocFolderListUrl}${siteId}/trialId/${trialId}`;
        break;
    }

    return this.http.get<TreeNode[]>(url, {});
  }

  copyAndRoute(url: string, dto: CopyAndRoute) {
    return this.http.post(url, dto);
  }

  addWorkFlowList(workFlowList: WorkFlowRouting[] | WorkFlowRouting) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/WorkFlowRouting/AddWorkFlowRouting',
      workFlowList,
      this.httpOptions
    );
  }

  // kinda cheating - apiresponse id is 1 if there is an open action, 0 if not
  checkOpenAction(docType: string, documentId: number) {
    return this.http.get<ApiResponse>(
      this.settings.baseUrl +
        `api/WorkFlowRouting/CheckOpenAction/${docType}/${documentId}`,
      this.httpOptions
    );
  }
}
