import { Component, OnInit } from '@angular/core';
import { MenuItem, Message } from 'primeng/api';
import { AppConstants } from '../../common/app.constants';
import { RequestErrors, makeBreadCrumbs } from '../../common/utils';
import { ActivatedRoute } from '@angular/router';
import { FactLidoUserEvents } from '../model/notifications.model';
import { NotificationService } from '../service/notification-service.service';
@Component({
  selector: 'app-alerts-list',
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss'],
})
export class AlertsListComponent implements OnInit {
  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));
  loadDocumentViewer = false;
  loadWorkflowRoutingNotification = false;
  userEventId = 0;
  userEvents: FactLidoUserEvents[];
  selectedUserEvent: FactLidoUserEvents = null;
  selectedUserEvents: FactLidoUserEvents[] = [];
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  recordsPerPage = AppConstants.alertsListRecsPerPage;
  pageLinksPerPage = AppConstants.alertListPagerLinks;

  pMenuItems: MenuItem[] = [];
  sortOptions = [
    { label: 'Newest', value: '!createdOn' },
    { label: 'Oldest', value: 'createdOn' },
    { label: 'Title', value: 'userEventName' },
  ];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  cols = [
    { field: 'requestFromUserName', header: 'Originator' },
    { field: 'eventStatusName', header: 'Status' },
  ];

  msgs: Message[];
  docName: string;
  docPath: string;
  docExt: string;
  workFlowTitle: string;
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) {}

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  getData() {
    this.notificationService
      .getUserNotifications('Alert', this.siteId)
      .subscribe(
        (data) => {
          this.userEvents = data;
          this.loading = false;
          if (this.userEventId !== 0) {
            const userEvent = this.userEvents.find(
              (x) => x.userEventId === this.userEventId
            );
            if (userEvent !== undefined) {
              this.onEventClick(userEvent);
            }
          }
        },
        (error) => {
          this.loading = false;
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  ngOnInit() {
    this.pMenuItems = makeBreadCrumbs(false, 'Informational Alerts');
    this.route.paramMap.subscribe((param) => {
      this.userEventId = Number(param.get('userEventId'));
      this.getData();
    });
  }

  onEventClick(userEvent: FactLidoUserEvents) {
    this.workFlowTitle = userEvent.userEventName;
    this.selectedUserEvent = userEvent;
    this.loadWorkflowRoutingNotification = true;
  }

  openDocumentViewer($event) {
    this.loadWorkflowRoutingNotification = false;
    this.docPath = $event.docPath;
    this.docName = $event.docName;
    this.docExt = $event.docExt;
    this.loadDocumentViewer = true;
  }

  closeDocumentViewer() {
    this.loadDocumentViewer = false;
    this.loadWorkflowRoutingNotification = true;
  }

  closeModals(update = false) {
    this.loadWorkflowRoutingNotification = false;
    this.loadDocumentViewer = false;
    this.selectedUserEvents = [this.selectedUserEvent];
    if (update === true) {
      this.onDismissEvents();
    }
  }

  onDismissEvents() {
    const idString = this.selectedUserEvents
      .map((x) => x.userEventId)
      .join(',');

    this.notificationService.markAlertsAsViewed(idString).subscribe(
      () => {
        this.selectedUserEvent = null;
        this.selectedUserEvents = [];
        this.getData();
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
