import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RequestErrors } from 'src/app/common/utils';
import { Message } from 'primeng/api';
import { saveAs } from 'file-saver';
import { UploadService } from 'src/app/shared/upload/upload.service';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {
  @Input() fileExtension: string;
  @Input() fileName: string;
  @Input() docPath: string;
  @Output() closeModal = new EventEmitter<boolean>();

  url = '';
  headerLabel = 'View Document';
  msgs: Message[] = [];
  displayDoc = false;
  displayImage = false;
  displayPdf = false;

  constructor(private uploadService: UploadService) {}

  closeDocumentModal() {
    this.closeModal.emit(false);
  }

  ngOnInit() {
    this.getFileUrl();
  }

  getFileUrl() {
    this.uploadService
      .getBlobUrl(this.fileName, this.docPath, this.fileExtension)
      .subscribe(
        (response) => {
          this.url = response.message;
          this.getDocTypeDisplay();
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  onDownload() {
    this.uploadService
      .downloadFile(this.fileName, this.docPath, this.fileExtension)
      .subscribe(
        (downloadFile) => {
          const file = new Blob([downloadFile], {});
          saveAs(file, this.fileName + '.' + this.fileExtension);
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  getDocTypeDisplay() {
    const fileExtension = this.fileExtension.toLowerCase().substring(0, 3);
    switch (fileExtension) {
      case 'doc':
      case 'xls':
      case 'ppt':
        this.displayDoc = true;
        break;
      case 'jpg':
      case 'png':
      case 'gif':
        this.displayImage = true;
        break;
      default:
        this.displayPdf = true;
    }
  }
}
