<div class="b_container messages">
    <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>

    <div class="contentnav">
        <div class="row">

            <div class="col-12 sitehomeview">
                <p-messages [value]="msgs"></p-messages>
                <p-dataView #dv [value]="sites" [layout]="initialView" filterBy="siteName" [sortField]="sortField"
                    [sortOrder]="sortOrder">
                    <p-header>

                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <p-dropdown id="sitehomedropdown" dropdownIcon="fa fa-caret-down" [options]="sortOptions"
                                [(ngModel)]="sortKey" placeholder="Sort By"
                                (onChange)="onSortChange($event)"></p-dropdown>


                            <div class="d-flex flex-row column-gap-3 justify-content-end align-items-center">
                                <div class="wdsrch p-inputgroup">
                                    <input id="sitesearchinput" class="form-control" type="text" pInputText
                                        placeholder="Site Name" (keyup)="dv.filter($event.target.value)" />
                                    <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
                                </div>

                                <p-dataViewLayoutOptions (click)="onLayoutChange($event, dv)"></p-dataViewLayoutOptions>
                            </div>
                        </div>

                        <div *ngIf="currentView === 'list'" class="d-flex flex-row listheader align-items-center">
                            <div class="col-sm-2">Site</div>
                            <div class="col-sm-3">Name</div>
                            <div class="col-sm-3">Site Administrator</div>
                            <div class="col-sm-2">Email</div>
                            <div class="col-sm-2 text-center">Freemium Site</div>
                        </div>

                    </p-header>

                    <ng-template let-site pTemplate="gridItem">
                        <div id="sitehomegriditem" class="p-g-12 p-md-4 p-lg-4 gnav trialhomegrid">
                            <p-panel [style]="{'text-align':'center'}" [toggleable]="false"
                                expandIcon="fa fa-caret-down" collapseIcon="fa fa-caret-up" class="gridpad dfolder">
                                <p-header [pTooltip]="site.siteName" tooltipPosition="right">
                                    {{ site.siteName | truncate:20 }}
                                </p-header>
                                <div class="d-flex trialhomecontainer cursorpointer" appAccess="Home"
                                    (click)="onOpenHome(site)" id="site{{site.siteId}}">
                                    <div class="col align-items-center tvendor_logo" id="divTrialLogo">
                                        <ng-container
                                            *ngIf="((site.companyLogo !== undefined) && (site.companyLogo !== '') && (site.companyLogo !== null)); else noLogo">
                                            <img src="{{site.companyLogo | imgurl}}" class="logo-cardsize"
                                                alt="Site Logo" />
                                        </ng-container>
                                        <ng-template #noLogo>
                                            <img src="{{defaultSiteLogo}}" class="logo-cardsize" alt="Site Logo" />
                                        </ng-template>
                                        <div>
                                            {{site.siteName}}
                                        </div>
                                    </div>
                                </div>
                            </p-panel>
                        </div>
                    </ng-template>

                    <ng-template let-site pTemplate="listItem">

                        <div id="sitehomelistitem" class="p-lg-12">
                            <div class="d-flex flex-row align-items-center" role="button" id="site{{site.siteId}}"
                                (click)="onOpenHome(site)">
                                <div class="col-sm-2">
                                    <ng-container
                                        *ngIf="((site.companyLogo !== undefined) && (site.companyLogo !== '') && (site.companyLogo !== null)); else noLogo">
                                        <img src="{{site.companyLogo | imgurl}}" class="logo-listsize"
                                            alt="Site Logo" />
                                    </ng-container>
                                    <ng-template #noLogo>
                                        <img src="{{defaultSiteLogo}}" class="logo-listsize" alt="Site Logo" />
                                    </ng-template>
                                </div>
                                <div class="col-sm-3">
                                    {{site.siteName}}
                                </div>
                                <div class="col-sm-3">
                                    {{site.siteAdminDisplayName}}
                                </div>
                                <div class="col-sm-2">
                                    {{site.siteAdminEmail}}
                                </div>
                                <div class="col-sm-2 text-center">
                                    <ng-container *ngIf="site.freemium === true; else notFreemium">Yes</ng-container>
                                    <ng-template #notFreemium>
                                        No
                                    </ng-template>

                                </div>

                            </div>
                        </div>

                    </ng-template>
                </p-dataView>
            </div>
        </div>
    </div>

</div>