import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '../common/app.constants';
import { DocFlowLogService } from './service/doc-flow-log.service';
import { DocumentFlowLogData } from './Model/documentflowlogdata.model';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { EncrDecrService } from '../EncrDecr/encr-decr.service';
import { BinderDocumentType } from '../documents/model/document-add.model';
import { MenuItem, Message } from 'primeng/api';
import { RequestErrors, makeBreadCrumbsDocuments } from '../common/utils';
import { getBinderLabel } from '../common/docutils';

@Component({
  selector: 'app-doc-flow-log',
  templateUrl: './doc-flow-log.component.html',
  styleUrls: ['./doc-flow-log.component.scss'],
})
export class DocFlowLogComponent implements OnInit {
  @ViewChild('dt') dt: Table;
  loading = true;
  trialName = sessionStorage.getItem('trialName');
  siteName = sessionStorage.getItem('siteName');
  trialId = parseInt(sessionStorage.getItem('trialId'));
  siteId = parseInt(sessionStorage.getItem('siteId'));
  msgs: Message[];
  pMenuItems: MenuItem[] = [];

  documentType: BinderDocumentType;
  cols = [
    { field: 'documentName', header: 'Name Document' },
    { field: 'docName', header: 'docName' },
    { field: 'routedFromDisplayName', header: 'Router' },
    { field: 'routedOnDate', header: 'Routed On' },
    { field: 'routedToDisplayName', header: 'Routee' },
    { field: 'signatureOption', header: 'Signature' },
    { field: 'approvedDate', header: 'Approval Date' },
  ];

  dataViewRecsPerPage = AppConstants.gridViewRecsPerPage;
  folderName: string;
  pageLinksPerPage = AppConstants.dataViewPagerLinks;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  documentFlowLogData: DocumentFlowLogData[];
  selectedDocument: DocumentFlowLogData;
  loadDocumentViewer = false;

  sortOptions = [
    { label: 'Name A to Z', value: 'docName' },
    { label: 'Name Z to A', value: '!docName' },
    { label: 'Routed By', value: 'routedFromDisplayName' },
    { label: 'Routed To', value: 'routedToDisplayName' },
    { label: 'Status', value: 'eventStatusName' },
  ];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  binderDocType: BinderDocumentType = this.encrDecr.decryptData(
    this.route.snapshot.queryParamMap.get('documentType')
  );

  subjectId: number = this.encrDecr.decryptData(
    this.route.snapshot.queryParamMap.get('subjectId')
  );

  constructor(
    private docFlowLogService: DocFlowLogService,
    private route: ActivatedRoute,
    private encrDecr: EncrDecrService
  ) {}

  getData() {
    this.docFlowLogService
      .getDocFlowLogData(
        this.binderDocType,
        this.siteId,
        this.trialId,
        this.binderDocType === BinderDocumentType.subjectdocument
          ? this.subjectId
          : undefined
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.documentFlowLogData = data;
        },
        (error) => {
          this.loading = false;
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  onDocClick(documentFlowLogData: DocumentFlowLogData) {
    this.selectedDocument = documentFlowLogData;
    this.loadDocumentViewer = true;
  }

  closeModals() {
    this.loadDocumentViewer = false;
  }

  ngOnInit() {
    this.pMenuItems = makeBreadCrumbsDocuments(this.binderDocType);
    this.pMenuItems.push({
      label: 'Doc Flow Log',
    });
    this.getData();
  }
}
