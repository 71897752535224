import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { User } from 'src/app/users/model/user.model';
import { AppConstants } from 'src/app/common/app.constants';
import { Message } from 'primeng/api';
import { RequestErrors } from 'src/app/common/utils';
import { Trial } from 'src/app/trial/model/trial.model';
import { UserRole } from 'src/app/users/model/userrole.model';
import { TrialUser } from 'src/app/trial/model/trialuser.model';
import { UserService } from 'src/app/users/service/user.service';

export interface TrialUserAdd {
  trialId?: number;
  displayName?: string;
  email?: string;
  userId?: number;
  siteId?: number;
  userTypeName?: string;
  userRole?: UserRoleItem;
  userRoleName?: string;
  allowAccessToArchivedTrial?: boolean;
  IsUserAddedAtArchivedStatus?: boolean;
}

export interface UserRoleItem {
  label: string;
  value: number;
}
@Component({
  selector: 'app-add-trialusers',
  templateUrl: './add-trialusers.component.html',
  styleUrls: ['./add-trialusers.component.scss'],
})
export class AddTrialUsersComponent implements OnChanges {
  @Output() sendTrialUsers = new EventEmitter<TrialUser[]>();
  @Input() trial: Trial;
  @Input() users: User[];
  @Input() needPi = false;

  RecsPerPage = 6;
  pageLinksPerPage = 6;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  editAddUserList = true;
  msgs: Message[] = [];
  havePi = false;
  selectUserTaskComp = false;
  refreshOnCancel = false;
  cols = [
    { field: 'displayName', header: 'Name' },
    { field: 'userTypeName', header: 'User Type' },
    { field: 'userRole', header: 'Trial Role' },
  ];

  userRoles: UserRole[];
  externalUserRole: UserRoleItem;
  externalUserRoles: UserRoleItem[]; // List of one item that can be selected for an external user
  defaultUserRole: UserRoleItem;
  userRoleItems: UserRoleItem[]; // List of items that can be selected for a non external user
  piUserRole: UserRoleItem;
  trialUsers: TrialUserAdd[] = []; // List of component specific trial user objects which haven't been selected
  clonedTrialUsers: TrialUserAdd[] = []; // Holds the component specific trial user objects which are being edited
  savedTrialUsers: TrialUserAdd[] = []; // List of component specific objects which have been selected
  currentTrialUsers: TrialUser[] = []; // The current list of trial users to send to the parent component

  constructor(private userService: UserService) {}

  showAddUserListNav() {
    this.editAddUserList = true;
    this.selectUserTaskComp = false;
  }

  ngOnChanges() {
    if (this.users !== undefined) {
      this.getUserRoles();
    }
  }

  getUserRoles() {
    this.userService.getUserRoles().subscribe(
      (data: UserRole[]) => {
        this.userRoles = data;
        this.getData();
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  getData() {
    // Filter out unneeded roles
    this.userRoleItems = this.userRoles
      .filter((x) => {
        return x.userRoleName !== 'External User';
      })
      .map((x) => {
        return {
          label: x.userRoleName,
          value: x.userRoleId,
        };
      });
    if (!this.needPi) {
      this.userRoleItems = this.userRoleItems.filter((x) => x.value !== 4);
    }
    this.externalUserRoles = this.userRoles
      .filter((x) => x.userRoleName === 'External User')
      .map((x) => {
        return {
          label: x.userRoleName,
          value: x.userRoleId,
        };
      });

    this.defaultUserRole = {
      label: 'Trial Role',
      value: 0,
    };
    this.piUserRole = this.userRoleItems.find((x) => x.value === 4);
    this.trialUsers = this.users.map((x) => {
      return {
        userId: x.userId,
        trialId: this.trial.trialId,
        siteId: this.trial.siteId,
        displayName: x.displayName,
        userTypeName: x.userTypeName,
        email: x.email,
        allowAccessToArchivedTrial: true,
        userRole:
          x.userTypeId === 2 ? this.externalUserRoles[0] : this.defaultUserRole,
        userRoleName:
          x.userTypeId === 2
            ? this.externalUserRoles[0].label
            : this.defaultUserRole.label,
        IsUserAddedAtArchivedStatus: this.trial.trialStatusName === 'Archived',
      };
    });
    const sorted = this.trialUsers.sort((a, b) =>
      a.displayName.localeCompare(b.displayName, undefined, {
        caseFirst: 'upper',
      })
    );
    this.trialUsers = [...sorted];
  }

  onRemoveTrialUser(trialUser: TrialUser) {
    let idx = this.savedTrialUsers.findIndex(
      (x) => x.userId === trialUser.userId
    );
    this.trialUsers.push(trialUser);
    const sorted = this.trialUsers.sort((a, b) =>
      a.displayName.localeCompare(b.displayName, undefined, {
        caseFirst: 'upper',
      })
    );
    this.trialUsers = [...sorted];
    this.savedTrialUsers.splice(idx, 1);
    if (this.needPi) {
      this.checkForPi();
    }
    idx = this.currentTrialUsers.findIndex(
      (x) => x.userId === trialUser.userId
    );
    this.currentTrialUsers.splice(idx, 1);
    this.sendTrialUsers.emit(this.currentTrialUsers);
  }

  checkForPi() {
    let idx = this.savedTrialUsers.findIndex((x) => x.userRole.value === 4);
    this.havePi = idx !== -1;
    idx = this.userRoleItems.findIndex((x) => x.value === 4);
    if (this.havePi) {
      if (idx !== -1) {
        this.userRoleItems.splice(idx, 1);
      }
    } else {
      if (idx === -1) {
        this.userRoleItems.push(this.piUserRole);
      }
    }
  }

  onRowEditInit(trialUser: TrialUserAdd, index: number) {
    if (this.needPi) {
      this.checkForPi();
    }
    this.clonedTrialUsers[index] = { ...trialUser };
  }

  onRowEditSave(trialUser: TrialUserAdd) {
    const newTrialUser = new TrialUser({});
    newTrialUser.userId = trialUser.userId;
    newTrialUser.trialId = this.trial.trialId;
    newTrialUser.siteId = this.trial.siteId;
    newTrialUser.displayName = trialUser.displayName;
    newTrialUser.userRoleId = trialUser.userRole.value;
    newTrialUser.userRoleName = trialUser.userRole.label;
    trialUser.userRoleName = trialUser.userRole.label;
    this.savedTrialUsers.push(trialUser);
    this.currentTrialUsers.push(newTrialUser);
    const idx = this.trialUsers.findIndex((x) => x.userId === trialUser.userId);
    this.trialUsers.splice(idx, 1);
    this.sendTrialUsers.emit(this.currentTrialUsers);
  }

  onRowEditCancel(index: number) {
    this.trialUsers[index] = this.clonedTrialUsers[index];
    delete this.clonedTrialUsers[index];
  }
}
