import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppsettingsService } from '../../core/appsettings.service';
import { RegulatoryBinderFolder } from '../model/regulatorybinderfolder.model';
import { RegulatoryBinderDocument } from '../model/regulatorybinderdocument.model';
import { ApiResponse } from 'src/app/core/apiresponse';
import { CopyAndRoute } from 'src/app/document-routing/model/copy-and-route.model';
import { DocumentListRequest } from 'src/app/documents/model/document-request.model';
import { DocumentUpdate } from 'src/app/documents/model/document-update.model';
@Injectable({
  providedIn: 'root',
})
export class RegulatoryBinderService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  addFolder(addCustomFolder: RegulatoryBinderFolder) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/regulatorybinderfolder',
      addCustomFolder,
      this.httpOptions
    );
  }

  updateDocumentItem(documentUpdate: DocumentUpdate) {
    return this.http.patch<ApiResponse>(
      this.settings.baseUrl + 'api/regulatorybinderdocs/update',
      documentUpdate,
      this.httpOptions
    );
  }

  getDocumentList(
    documentRequest: DocumentListRequest
  ): Observable<RegulatoryBinderDocument[]> {
    return this.http.post<RegulatoryBinderDocument[]>(
      this.settings.baseUrl + 'api/regulatorybinderdocs/GetDocList',
      documentRequest,
      this.httpOptions
    );
  }

  getWorkflowDetails(workflowId: number): Observable<RegulatoryBinderDocument> {
    return this.http.get<RegulatoryBinderDocument>(
      this.settings.baseUrl +
        'api/regulatorybinderdocs/GetWorkflowDetails/' +
        workflowId
    );
  }

  copyAndRoute(dto: CopyAndRoute) {
    return this.http.post(
      this.settings.baseUrl + 'api/regulatorybinderdocs/CopyAndRoute',
      dto
    );
  }
}
