import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Message } from 'primeng/api';
import { FactLidoUserEvents } from '../model/notifications.model';
import { NotificationService } from '../service/notification-service.service';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/common/app.constants';
import { RequestErrors, makeBreadCrumbs } from 'src/app/common/utils';
import { NavMenuService } from 'src/app/layout/components/nav-menu/nav-menu.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  loadWorkflowRoutingNotification = false;
  loadDocumentViewer = false;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  recordsPerPage = AppConstants.alertsListRecsPerPage;
  pageLinksPerPage = AppConstants.alertListPagerLinks;

  pMenuItems: MenuItem[] = [];

  sortOptions = [
    { label: 'Newest', value: '!createdOn' },
    { label: 'Oldest', value: 'createdOn' },
    { label: 'Title', value: 'userEventName' },
  ];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  cols = [
    { field: 'userEventName', header: 'Alert Title' },
    { field: 'eventTypeName', header: 'Alert Type' },
    { field: 'requestFromUserName', header: 'Originator' },
    { field: 'eventStatusName', header: 'Status' },
    { field: 'createdOn', header: 'Created On' },
  ];
  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));
  loading = true;
  userEvents: FactLidoUserEvents[];
  selectedUserEvent: FactLidoUserEvents;
  msgs: Message[];
  docName: string;
  docPath: string;
  docExt: string;
  workFlowTitle: string;
  userEventId: number;

  constructor(
    private route: ActivatedRoute,
    private navMenuService: NavMenuService,
    private notificationService: NotificationService
  ) {}

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  getData() {
    this.notificationService
      .getUserNotifications('Notification', this.siteId)
      .subscribe(
        (data) => {
          this.userEvents = data;
          this.loading = false;
          if (this.userEventId !== 0) {
            const userEvent = this.userEvents.find(
              (x) => x.userEventId === this.userEventId
            );
            if (userEvent !== undefined) {
              this.onEventClick(userEvent);
            }
          }
        },
        (error) => {
          this.loading = false;
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  ngOnInit() {
    this.pMenuItems = makeBreadCrumbs(false, 'Actionable Alerts');
    this.route.paramMap.subscribe((param) => {
      this.userEventId = Number(param.get('userEventId'));
      this.getData();
    });
  }

  onEventClick(userEvent: FactLidoUserEvents) {
    this.selectedUserEvent = userEvent;
    this.workFlowTitle = userEvent.userEventName;
    this.loadWorkflowRoutingNotification = true;
  }

  openDocumentViewer($event) {
    this.loadWorkflowRoutingNotification = false;
    this.docPath = $event.docPath;
    this.docName = $event.docName;
    this.docExt = $event.docExt;
    this.loadDocumentViewer = true;
  }

  closeDocumentViewer() {
    this.loadDocumentViewer = false;
    this.loadWorkflowRoutingNotification = true;
  }

  closeModal(refresh: boolean) {
    // If refresh is true, tell the notification component to update the count
    this.loadWorkflowRoutingNotification = false;
    if (refresh === true) {
      this.navMenuService.notifyAlertsOfSiteChange();
      this.getData();
    }
  }
}
