import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppsettingsService } from '../../core/appsettings.service';
import { ApiResponse } from 'src/app/core/apiresponse';
import { DocumentListRequest } from 'src/app/documents/model/document-request.model';
import { DocumentUpdate } from 'src/app/documents/model/document-update.model';
import { SubjectRecordsFolder } from '../model/subjectdocumentfolder.model';
import { SubjectDocument } from '../model/subjectdocument.model';

@Injectable({
  providedIn: 'root',
})
export class SubjectDocumentService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  addFolder(addCustomFolder: SubjectRecordsFolder) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/subjectrecordsfolder',
      addCustomFolder,
      this.httpOptions
    );
  }

  updateDocumentItem(documentUpdate: DocumentUpdate) {
    return this.http.patch<ApiResponse>(
      this.settings.baseUrl + 'api/subjectdocument/update',
      documentUpdate,
      this.httpOptions
    );
  }

  getDocumentList(
    documentRequest: DocumentListRequest
  ): Observable<SubjectDocument[]> {
    return this.http.post<SubjectDocument[]>(
      this.settings.baseUrl + 'api/subjectdocument/GetDocList',
      documentRequest,
      this.httpOptions
    );
  }

  // Used by alerts-list
  getWorkflowDetails(workflowId: number): Observable<SubjectDocument> {
    return this.http.get<SubjectDocument>(
      this.settings.baseUrl +
        'api/subjectdocument/GetWorkflowDetails/' +
        workflowId
    );
  }
}
