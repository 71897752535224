import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Vendor } from '../vendors/model/vendor.model';
import { NavMenuService } from '../layout/components/nav-menu/nav-menu.service';
import { TrialService } from '../trial/service/trial.service';
import { AppConstants } from '../common/app.constants';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Trial } from '../trial/model/trial.model';
import { forkJoin } from 'rxjs';
import { MenuItem, Message } from 'primeng/api';
import {
  RequestErrors,
  getCurrentUserRole,
  makeBreadCrumbs,
} from '../common/utils';
import { UserSite } from '../users/model/user-site.model';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-trial-vendors',
  templateUrl: './trial-vendors.component.html',
  styleUrls: ['./trial-vendors.component.scss'],
})
export class TrialVendorsComponent implements OnInit {
  @ViewChild('goUp') contentPage: ElementRef;
  vendors: Vendor[];
  trial: Trial;
  trialArchived = false;
  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));
  trialName: string;
  isSiteAdmin: boolean =
    sessionStorage.getItem('isSiteAdmin') === 'true' ? true : false;
  isExternalUser = sessionStorage.getItem('extUser');
  initialView = 'grid';
  currentView = 'grid';
  selectedVendor: Vendor;
  loadEditTrialVendor = false;
  loadAddTrialVendor = false;
  loadUnlinkTrialVendor = false;
  loadEditTrialHome = false;
  allowEdits = false;
  msgs: Message[] = [];

  sortOptions = [
    { label: 'Name A to Z', value: 'vendorName' },
    { label: 'Name Z to A', value: '!vendorName' },
  ];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  defaultVendorLogo = AppConstants.defaultVendorLogo;
  loadOktaConfirmComponent = false;
  url: SafeResourceUrl = null;
  display = true;

  secToken: string;
  tempFns: string;

  pMenuItems: MenuItem[] = [];

  isBcLink = false;
  todayDate = new Date();
  RecsPerPage = AppConstants.gridViewRecsPerPage;
  pageLinksPerPage = AppConstants.dataViewPagerLinks;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  OktaConfirm = false;
  oktaBtnClickType: string;
  vendorUrl: string;
  ssoAppStatus: string;
  ssoAppUrl: string;
  userTrialRole: string;

  constructor(
    private router: Router,
    private navService: NavMenuService,
    private trialService: TrialService,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {
    sessionStorage.setItem('Oktaplugin', '');
  }

  onLayoutChange($event, dv) {
    if (dv.layout === 'list') {
      this.currentView = 'list';
    } else {
      this.currentView = 'grid';
    }
  }

  openOkta() {
    this.loadOktaConfirmComponent = true;
    this.OktaConfirm = true;
  }

  ngOnInit() {
    if (this.trialId === undefined || this.trialId === null) {
      this.router.navigate(['/layout/access-denied']);
    }
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      AppConstants.OktaPluginUrl
    );

    this.trialArchived = sessionStorage.getItem('trialStatusId') === '5';
    this.getTrialChange();
  }

  closeModals(refresh = false) {
    this.loadEditTrialVendor = false;
    this.loadAddTrialVendor = false;
    this.loadUnlinkTrialVendor = false;
    this.loadEditTrialHome = false;
    if (refresh) {
      this.getData();
    }
  }

  openAddTrialVendor() {
    this.loadAddTrialVendor = true;
    /*     if (sessionStorage.getItem('Oktaplugin') === 'Y') {
      this.loadAddTrialVendor = true;
    } else {
      document.getElementById('loading').style.display = 'block';
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        AppConstants.OktaPluginUrl
      );
      checkForPlugin().then((data) => {
        document.getElementById('loading').style.display = 'none';
        if (data === 'Y') {
          this.loadAddTrialVendor = true;
        } else {
          this.OktaConfirm = true;
        }
      });
    }
 */
  }

  openEditTrialHome() {
    this.loadEditTrialHome = true;
  }

  openEditTrialVendor(vendor: Vendor) {
    // Check for the plugin again

    this.selectedVendor = vendor;
    this.loadEditTrialVendor = true;
    /*     if (sessionStorage.getItem('Oktaplugin') === 'Y') {
      this.loadEditTrialVendor = true;
    } else {
      document.getElementById('loading').style.display = 'block';
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        AppConstants.OktaPluginUrl
      );
      checkForPlugin().then((data) => {
        document.getElementById('loading').style.display = 'none';
        if (data === 'Y') {
          this.loadEditTrialVendor = true;
        } else {
          this.OktaConfirm = true;
        }
      });
    }
 */
  }

  openUnlinkTrialVendor(vendor: Vendor) {
    this.selectedVendor = vendor;
    this.loadUnlinkTrialVendor = true;
  }

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  getTrialChange() {
    const trialChangeData: UserSite = {
      siteId: this.siteId,
      trialId: this.trialId,
    };
    this.authService.regenerateTokenOnSiteChange(trialChangeData).subscribe(
      (data) => {
        if (data.authsuccess === true) {
          this.authService.setAuthUserSessionStorage(data, '');
          this.navService.setUserRoleNameState();
          this.userTrialRole = getCurrentUserRole();
          this.getData();
        } else {
          this.msgs = [
            {
              severity: 'warn',
              detail: data.authErrors[0],
            },
          ];
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  getData() {
    const getTrial = this.trialService.getTrialDetailsforEdit(
      this.trialId,
      this.siteId
    );
    const getVendors = this.trialService.getTrialVendors(
      this.trialId,
      this.siteId
    );
    forkJoin([getTrial, getVendors]).subscribe(
      ([trial, vendors]) => {
        this.trial = trial;
        this.trialName = trial.trialName;
        sessionStorage.setItem('trialName', trial.trialName);
        sessionStorage.setItem('trialStatusId', trial.trialStatusId.toString());
        this.allowEdits = trial.trialStatusId === 2;
        this.pMenuItems = [
          {
            label: 'Site Home',
            routerLink: '/layout/sitehome',
          },
          {
            label: sessionStorage.getItem('siteName'),
            routerLink: '/layout/home',
          },
          {
            label: sessionStorage.getItem('trialName'),
          },
        ];
        this.pMenuItems = makeBreadCrumbs(true);
        this.navService.setTrialState(true, trial.trialName, trial.trialId);
        this.vendors = vendors;
        document.getElementById('loading').style.display = 'none';
      },

      (error) => {
        const errors = RequestErrors(error);
        this.msgs = errors.map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  goToVendorSite(vendor: Vendor) {
    if (vendor.swaVendor) {
      if (!vendor.ssoAppUrl || vendor.ssoAppUrl === null) {
        this.msgs = [
          {
            severity: 'warn',
            detail: 'That vendor doesnt have a valid url',
          },
        ];
        return;
      }
    }
    if (vendor.swaVendor) {
      window.open(vendor.ssoAppUrl, '_blank');
    } else {
      window.open(vendor.vendorUrl, '_blank');
    }
    /*     if (sessionStorage.getItem('Oktaplugin') === 'Y') {
      if (vendor.swaVendor) {
        window.open(vendor.ssoAppUrl, '_blank');
      } else {
        window.open(vendor.vendorUrl, '_blank');
      }
    } else {
      document.getElementById('loading').style.display = 'block';
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        AppConstants.OktaPluginUrl
      );
      checkForPlugin().then((data) => {
        document.getElementById('loading').style.display = 'none';
        if (data === 'Y') {
          if (vendor.swaVendor) {
            window.open(vendor.ssoAppUrl, '_blank');
          } else {
            window.open(vendor.vendorUrl, '_blank');
          }
        } else {
          this.OktaConfirm = true;
        }
      });
    }
 */
  }
}
