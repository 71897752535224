<div class="b_container messages">
  <div class="rtheader">
    Add Existing Users
    <div class="closesidebar" (click)="closeModal()">X</div>
  </div>
  <div class="rtcontainer userpanel">
    <p-messages [value]="msgs"></p-messages>

    <button class="btn btn-primary marright" (click)="onAddUsers()">
      Add Users
    </button>
    <div class="usertypedd">
      <label class="col-form-label">User Type</label>
      <div class="dd95">
        <p-dropdown dropdownIcon="fa fa-caret-down" [options]="userTypeSelections" [(ngModel)]="selectedUserType"
          optionLabel="userTypeName" placeholder="User Type"></p-dropdown>
      </div>
    </div>

    <div class="datatable">

      <p-table #dt [columns]="cols" [value]="users" dataKey="userId" [(selection)]="selectedUsers" [paginator]="true"
        [rows]="RecsPerPage" [pageLinks]="pageLinksPerPage">
        <ng-template pTemplate="caption">
          <div class="wdsrch">
            Search Users
            <div class="wdsrch p-inputgroup">
              <input class="form-control" type="text" pInputText placeholder="User Name"
                (input)="dt.filterGlobal($event.target.value, 'contains')" />
              <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
            </div>

          </div>
          <th>Select the users to add</th>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr class="bdrtop">
            <th>Name</th>
            <th>Email Address</th>
            <th>User Status</th>

            <th style="width: 2.25em">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td><span class="text-truncate">{{rowData['displayName'] | truncate:28}}</span></td>
            <td>{{rowData['email']}}</td>
            <td>{{rowData['userStatusName']}}</td>
            <td>
              <p-tableCheckbox [value]="rowData" [pSelectableRow]="row" [index]="rowIndex"></p-tableCheckbox>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</div>