<div class="b_container messages">
    <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
    <div class="row">
        <div class="col-12 mb-2">
            <button type="button" [disabled]="!allowEdits" appAccessHide="Add/Edit Subjects"
                class="btn btn-primary marright" (click)="openAddPatient()">Add
                Subject</button>
        </div>
    </div>

    <div class="contentnav">

        <div class="row">
            <div class="col-md-12 trialview">
                <p-messages [value]="msgs"></p-messages>
                <p-dataView #dv [value]="subjects" [layout]="currentView" [emptyMessage]="emptyRecordMsg"
                    filterBy="displayName" [sortField]="sortField" [sortOrder]="sortOrder">
                    <p-header>

                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="sortOptions" [(ngModel)]="sortKey"
                                placeholder="Sort By" (onChange)="onSortChange($event)"></p-dropdown>


                            <div class="d-flex flex-row column-gap-3 justify-content-end align-items-center">
                                <div class="wdsrch p-inputgroup">
                                    <input class="form-control" type="text" pInputText placeholder="Subject Name"
                                        (keyup)="dv.filter($event.target.value)" />
                                    <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
                                </div>

                                <p-dataViewLayoutOptions (click)="onLayoutChange($event, dv)"></p-dataViewLayoutOptions>
                            </div>
                        </div>
                        <div *ngIf="currentView === 'list'" class="d-flex flex-row listheader align-items-center">
                            <div class="col-sm-3">Subject Name</div>
                            <div class="col-sm-2">Date Of Birth</div>
                            <div class="col-sm-3">Status</div>
                            <div class="col-sm-2 text-center" appAccessHide="Add/Edit Subjects">Edit Subject</div>
                            <div class="col-sm-2 text-center" appAccessHide="Add/Edit Subjects">Remove Subject</div>
                        </div>
                    </p-header>

                    <ng-template let-subject pTemplate="gridItem">
                        <div class="p-g-12 p-md-6 p-lg-4 gnav trialhomegrid" role="button">
                            <p-panel class="gridpad">
                                <ng-template pTemplate="header">
                                    <div style="width: 90%;" [pTooltip]="subject.displayName" tooltipPosition="right">
                                        {{subject.displayName | truncate:20 }}
                                    </div>
                                    <button (click)="openUnlinkTrialSubject(subject)" appAccessHide="Add/Edit Subjects"
                                        class="float-right unlinkvendor">
                                        <fa name="fa fa-user-xmark" class="fa-md" pTooltip="Remove Subject"
                                            tooltipPosition="top"></fa>
                                    </button>
                                </ng-template>

                                <div (click)="gotoSubjectDocs(subject)" class="d-flex trialhomecontainer cursorpointer">
                                    <div class="col align-items-center tvendor_logo">
                                        <div class="subjectname">{{subject.firstName}} {{subject.lastName}}</div>
                                        <div>{{subject.dateOfBirth | date:'MMM dd, yyyy'}}</div>
                                        <div [ngClass]="{
                                            'status-success':subject.subjectStatusName==='Enrolled',
                                            'status-secondary':subject.subjectStatusName==='Prescreened'
                                            }">{{subject.subjectStatusName}}</div>
                                        <div>{{subject.patientNumber}}</div>


                                    </div>
                                </div>
                                <div class="gfbtnnav">
                                    <button appAccessHide="Add/Edit Subjects" (click)="openEditPatient(subject)"
                                        class="editbtn">
                                        <fa name="pencil" pTooltip="Edit Subject" tooltipPosition="top"></fa>
                                    </button>
                                </div>
                            </p-panel>
                        </div>
                    </ng-template>

                    <ng-template let-subject pTemplate="listItem">
                        <div class="p-lg-12">
                            <div class="d-flex flex-row align-items-center" role="button"
                                (click)="gotoSubjectDocs(subject)">
                                <div class="col-sm-3">{{subject.displayName}}</div>
                                <div class="col-sm-2">{{subject.dateOfBirth | date:'MMM dd, yyyy'}}</div>
                                <div class="col-sm-3">{{subject.subjectStatusName}}</div>
                                <div class="col-sm-2 text-center">
                                    <button appAccessHide="Add/Edit Subjects" (click)="openEditPatient(subject)"
                                        class="listeditbtn">
                                        <fa name="pencil" pTooltip="Edit Subject" tooltipPosition="top"></fa>
                                    </button>
                                </div>
                                <div class="col-md-2 text-center">
                                    <button appAccessHide="Add/Edit Subjects" (click)="openUnlinkTrialSubject(subject)"
                                        class="listeditbtn">
                                        <fa name="fa fa-user-xmark" class="fa-md" pTooltip="Remove Subject"
                                            tooltipPosition="top"></fa>
                                    </button>
                                </div>


                            </div>
                        </div>
                    </ng-template>

                </p-dataView>
            </div>
        </div>

    </div>

    <p-sidebar *ngIf="loadEditPatient" [visible]="loadEditPatient" [dismissible]="false" position="right"
        [baseZIndex]="10000" styleClass="p-sidebar-md">
        <app-edit-patient [patient]="selectedPatient" (closeEditPatient)="closeModals($event)"></app-edit-patient>
    </p-sidebar>

    <p-sidebar *ngIf="loadUnlinkTrialSubject" [visible]="loadUnlinkTrialSubject" [dismissible]="false" position="right"
        [baseZIndex]="10000" styleClass="p-sidebar-md">
        <app-unlink-trial-subject (closeUnlinkSubject)="closeModals($event)"
            [subject]="selectedSubject"></app-unlink-trial-subject>

    </p-sidebar>

</div>