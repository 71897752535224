import { deepCopy } from 'src/app/common/utils';

export class Trial {
  trialId?: number;
  trialName?: string;
  protocolNumber?: string;
  siteNumber?: number;
  siteId?: number;
  sponsorName?: string;
  sponsorId?: number;
  piUserId?: number;
  piUserName?: string;
  sponsorLogo?: string;
  trialStatusId?: number;
  trialEndDate?: string;
  projectId?: string;
  isPiApproved?: boolean;
  trialStatusName?: string;
  actionItemCount?: number;
  isTrialUserPiApproved?: boolean;
  isTokenChangeNeeded?: boolean;
  userRoleName: string;
  userRoleId: number;
  trialUsers?: Array<{
    userRoleId?: number;
    siteId?: number;
    userId?: number;
    displayName?: string;
    isConfigured?: boolean;
  }> = [];

  constructor(defaultValues: Partial<Trial>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }

  clone() {
    return new Trial(deepCopy(this));
  }
}
