import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavMenuService {
  // Used to display the trialstate in the menu sidebar
  private trialStateSource = new BehaviorSubject<any>(false);
  trialState$ = this.trialStateSource.asObservable();

  // Subscribed by alerts and notifications to tell them to get new events
  private alertCountChanged = new Subject<any>();
  updateAlerts$ = this.alertCountChanged.asObservable();

  // Subecribe to this to find out when the user role has changed
  private userRoleName = new Subject<string>();
  userRoleNameState$ = this.userRoleName.asObservable();

  // Used to tell the header that isSiteHome has changed
  private isSiteHomeChanged = new Subject<boolean>();
  siteHomeChanged$ = this.isSiteHomeChanged.asObservable();

  // Used to tell the header that isSiteHome has changed
  private userTypeChanged = new Subject<number>();
  userTypeChanged$ = this.userTypeChanged.asObservable();

  // Called in home component to set the trial state to null
  // called in trial vendors to set the trial to whatever is selected
  setTrialState(trialSelected: boolean, trialName = '', trialId = 0) {
    if (trialSelected === false) {
      sessionStorage.removeItem('trialId');
      sessionStorage.removeItem('trialName');
      sessionStorage.removeItem('trialStatusId');
    }
    const trialDetails: any = {};
    trialDetails.trialSelected = trialSelected;
    trialDetails.trialName = trialName;
    trialDetails.trialId = trialId;

    this.trialStateSource.next(trialDetails);
  }

  changeUserType(userTypeId: number) {
    this.userTypeChanged.next(userTypeId);
  }

  // Call this when isSiteHome changes
  // to let the header know
  setIsSiteHome(inSiteHome: boolean) {
    if (inSiteHome === true) {
      sessionStorage.removeItem('siteId');
    }
    sessionStorage.setItem('isSiteHome', inSiteHome === true ? 'Yes' : 'No');
    this.isSiteHomeChanged.next(inSiteHome);
    this.setTrialState(false);
  }

  // Tell the alert and notification components to update the list - the site has changed
  notifyAlertsOfSiteChange() {
    this.alertCountChanged.next({});
  }

  // Subscribers can get the current user role with this
  // Not used right now
  setUserRoleNameState() {
    const roleId = sessionStorage.getItem('userRoleId');
    switch (roleId) {
      case '1':
        this.userRoleName.next('Remote Monitor');
        break;
      case '2':
        this.userRoleName.next('Remote Auditor');
        break;
      case '4':
        this.userRoleName.next('Principle Investigator');
        break;
      case '5':
        this.userRoleName.next('Sub Investigator');
        break;
      case '6':
        this.userRoleName.next('Research Coordinator');
        break;
      case '7':
        this.userRoleName.next('Data Manager');
        break;
      case '3':
      default:
        this.userRoleName.next('Other');
    }
  }
}
