import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { Message } from 'primeng/api';
import { ApiResponse } from '../../core/apiresponse';
import { WorkflowService } from 'src/app/workflow-routing/services/workflow.service';
import { createWorkflowRoutingList } from 'src/app/workflow-routing/model/workflowrouting.model';
import { UploadService } from 'src/app/shared/upload/upload.service';
import {
  AddDocument,
  BinderDocumentType,
} from 'src/app/documents/model/document-add.model';

import { User } from 'src/app/users/model/user.model';
import { FolderListItem } from 'src/app/documents/model/folderlist.model';
import { RequestErrors, getCurrentUserRole } from 'src/app/common/utils';
import { DocumentUpload } from '../model/upload-document.model';
import { getBinderDocLabel } from 'src/app/common/docutils';

@Component({
  selector: 'app-add-docs',
  templateUrl: './add-docs.component.html',
  styleUrls: ['./add-docs.component.scss'],
})
export class AddDocumentsComponent implements OnInit {
  @Output() closeAddDocsModal = new EventEmitter<boolean>();
  @Input() addDocumentData: AddDocument;
  @Input() folderList: FolderListItem[];
  trialName = sessionStorage.getItem('trialName');
  headerLabel = 'Add Document';
  folderNameLabel: string;
  uploadFile: File = null;
  gotFile = false;
  showSpinner = false;
  folderOptions: FolderListItem[];
  workFlowUsers: User[];
  workFlowType: string;
  uploadForm: UntypedFormGroup;
  apires: ApiResponse;
  msgs: Message[];
  userRole: string = undefined;
  showRoutingCheckbox = false;
  showWorkflowRouting = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private uploadService: UploadService,
    private workflowService: WorkflowService
  ) {}

  closeModal(refresh = false) {
    this.closeAddDocsModal.emit(refresh);
  }

  ngOnInit() {
    const pattern = '^[^/\\:*?"<>|]+$';
    this.userRole = getCurrentUserRole();
    // if this user is a site admin and has no trial role, only let them route if this is a
    // site document.
    if (this.userRole === undefined) {
      this.showRoutingCheckbox =
        this.addDocumentData.documentType === BinderDocumentType.sitedocument;
    } else {
      this.showRoutingCheckbox = true;
    }
    this.uploadForm = this.formBuilder.group({
      fileName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(40),
          Validators.pattern(pattern),
        ],
      ],
    });

    this.headerLabel =
      'Add ' + getBinderDocLabel(this.addDocumentData.documentType);

    if (
      this.addDocumentData.inSubFolder &&
      this.addDocumentData.folderList !== undefined
    ) {
      this.folderOptions = this.addDocumentData.folderList;
      const selectedItem =
        this.addDocumentData.folderId === undefined
          ? this.folderOptions[0]
          : this.folderOptions.find(
              (x) => x.folderId === this.addDocumentData.folderId
            );
      this.uploadForm.addControl(
        'folderId',
        new UntypedFormControl(selectedItem, Validators.required)
      );
    } else {
      this.folderNameLabel =
        this.addDocumentData.folderName !== null
          ? this.addDocumentData.folderName
          : getBinderDocLabel(this.addDocumentData.documentType);
    }
  }

  RouteCheckBoxMethod(e) {
    if (e.target.checked) {
      this.showWorkflowRouting = true;
    } else {
      this.showWorkflowRouting = false;
    }
  }

  workFlowSubmit(documentId: number) {
    const workFlowRoutingList = createWorkflowRoutingList(
      this.workFlowUsers,
      this.addDocumentData.siteId,
      this.addDocumentData.trialId,
      this.trialName,
      documentId,
      this.addDocumentData.documentType,
      this.uploadForm.value.fileName
    );

    this.workflowService.addWorkFlowList(workFlowRoutingList).subscribe(
      (messageResponse) => {
        this.showSpinner = false;
        this.apires = messageResponse;
        if (this.apires.success === true) {
          this.closeModal(true);
        }
        if (this.apires.success === false) {
          this.msgs = [
            { severity: 'warn', detail: this.apires.errorMessages[0] },
          ];
        }
      },

      (error) => {
        this.showSpinner = false;
        const errors = RequestErrors(error);
        this.msgs = errors.map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  updatedUserList(userList: User[]) {
    this.workFlowUsers = userList;
  }

  onFileSelect(e) {
    this.uploadFile = e.files[0];
    this.gotFile = true;
    this.uploadForm.controls.fileName.setValue(this.uploadFile.name);
    this.uploadForm.updateValueAndValidity();
  }

  onFileClear() {
    this.uploadFile = null;
    this.gotFile = false;
    this.uploadForm.controls.fileName.setValue('');
  }

  onSubmit(): void {
    if (this.showWorkflowRouting === true && this.workFlowUsers.length === 0) {
      this.msgs = [
        {
          severity: 'error',
          detail: 'Please select at least one user to route to',
        },
      ];
      document.getElementById('scrollPanel').scrollTo(0, 0);
      return;
    }

    if (!this.gotFile) {
      this.msgs = [{ severity: 'error', detail: 'Please Select a File' }];
      document.getElementById('scrollPanel').scrollTo(0, 0);
      return;
    }
    this.uploadDocument();
  }

  uploadDocument() {
    this.showSpinner = true;
    const fileName =
      this.uploadForm.controls.fileName.value == null
        ? ''
        : this.uploadForm.controls.fileName.value;
    const documentUpload: DocumentUpload = {
      documentType: this.addDocumentData.documentType,
      siteId: this.addDocumentData.siteId,
      trialId: this.addDocumentData.trialId,
      folderId: this.addDocumentData.folderId,
      subjectId: this.addDocumentData.subjectId,
      fileName: fileName,
      file: this.uploadFile,
    };
    this.uploadService.uploadDocument(documentUpload).subscribe(
      (messageResponse) => {
        this.apires = messageResponse;
        document.getElementById('scrollPanel').scrollTo(0, 0);

        this.showSpinner = false;
        if (this.apires.success === true) {
          this.uploadFile = null;
          this.gotFile = false;
          this.uploadForm.reset();
          if (this.showWorkflowRouting && this.workFlowUsers.length) {
            this.workFlowSubmit(this.apires.id);
          } else {
            this.closeModal(true);
          }
        }
        if (this.apires.success === false) {
          this.showSpinner = false;
          this.msgs = [
            {
              severity: 'error',
              detail: this.apires.errorMessages[0],
            },
          ];
        }
      },
      (error) => {
        this.showSpinner = false;
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
