<b style="color: #3298cb">Select users for workflow routing</b>
<div class="b_container messages">
  <p-messages [value]="msgs"></p-messages>

  <div class="row">
    <div class="col-md-12">
      <div class="datatable">
        <div *ngIf="siteDocumentType; else nonSite">
          Search Existing Site Users
        </div>

        <ng-template #nonSite> Search Existing Trial Users </ng-template>

        <p-table #dt [columns]="colsTrialDocs" [value]="users">
          <ng-template pTemplate="caption">
            <div class="input-group mb-3 wrtb">
              <input type="text" class="form-control" pInputText placeholder="Search"
                (input)="dt.filterGlobal($event.target.value, 'contains')" />
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
              </div>
            </div>

            <div class="bnone align-left">
              <div *ngIf="workFlowType === 'site'; else nonSite">
                Existing Site Users
              </div>
              <ng-template #nonSite> Existing Trial Users </ng-template>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngIf="userTrialRole !== 'External User'"></th>
              <th *ngFor="let col of columns; let i = index">
                {{ col.header }}
              </th>
              <th *ngIf="userTrialRole !== 'External User'">Approval</th>
              <th>Alert Only</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user let-columns="columns">
            <tr>
              <td class="appadd" *ngIf="userTrialRole !== 'External User'">
                <input type="checkbox" class="edituser" id="ch{{ user.userId }}" [checked]="user.isWorkFlowUserSelected"
                  value="{{ user.userId }}" (change)="onClickCheckbox(user)" />
              </td>
              <td class="appadd" *ngFor="let col of columns; let i = index">
                {{ user[col.field] }}
              </td>
              <td class="appadd" *ngIf="userTrialRole !== 'External User'">
                <input type="radio" class="edituser" [checked]="user.isWorkFlowApproval"
                  (click)="onClickApproveRadio(user)" />
              </td>
              <td class="appadd">
                <input type="radio" class="edituser" [checked]="user.isWorkFlowNotification"
                  (click)="onClickNotifyRadio(user)" />
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">{{ emptyRecordMsg }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>