<div class="b_container messages">
  <p-messages [value]="msgs"></p-messages>
  <div class="rtcontainer">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <div class="row my-3 mt-2 align-items-center">
            <label class="col-2">Site Name</label>
            <div class="col-8">
              <input pInputText class="form-control" type="text" readonly formControlName="siteName"
                placeholder="Site Name" />
            </div>
          </div>
          <div class="row my-3 align-items-center" *ngIf="!isSiteDocument">
            <label class="col-2">Trial Name</label>
            <div class="col-8">
              <input readonly pInputText class="form-control" type="text" formControlName="trialName"
                placeholder="Trial name" />
            </div>
          </div>
          <div class="row my-3 align-items-center" style="margin: 40px 0px 40px 0px;">
            <div class="col-12">
              <div class="spacer3"></div>
              <div class="docicon f_18" style="cursor: pointer">
                <a (click)="onDocumentClick()">
                  <div class="doc-display">
                    <app-doc-type-icon [documentType]="docExt"></app-doc-type-icon>
                    <div style="text-align: center; align-items: center;">
                      {{docName}}.{{docExt}}
                    </div>

                  </div>
                </a>
              </div>
            </div>
          </div>

          <div *ngIf="showApproval; else showDismiss">
            <div class="sectionheader">
              Enter your username and password to approve this document
            </div>
            <div class="row my-3 align-items-center">
              <label class="col-2">User Name</label>
              <div class="col-sm-8">
                <input pInputText class="form-control" type="text" formControlName="username" placeholder="Username" />
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <label class="col-2">Password</label>
              <div class="col-8">
                <div class="p-inputgroup">
                  <input type="password" pPassword class="form-control" [showPassword]="passwordVisible"
                    placeholder="Password" feedback="false" formControlName="password">
                  <span (click)="passwordVisible = !passwordVisible" *ngIf="!passwordVisible"
                    class="p-inputgroup-addon"><i class="fa fa-eye"></i></span>
                  <span (click)="passwordVisible = !passwordVisible" *ngIf="passwordVisible"
                    class="p-inputgroup-addon"><i class="fa fa-eye-slash"></i></span>
                </div>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-sm-8">
                <div class="btnnav">
                  <button type="button" (click)="onClick(2)" class="btn btn-primary marright">Approve</button>
                  <button type="button" (click)="onClick(4)" class="btn btn-primary marright">Reject</button>

                </div>
              </div>
            </div>
          </div>

          <ng-template #showDismiss>
            <div class="row my-3">
              <div class="col-sm-8">
                <div class="btnnav">
                  <button type="button" (click)="onDismiss()" class="btn btn-primary marright">Dismiss</button>

                </div>
              </div>
            </div>
          </ng-template>
        </div>

      </div>
    </form>

  </div>
</div>