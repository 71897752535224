<div class="b_container messages">
  <p-messages [value]="msgs"></p-messages>
  <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
  <div class="clear"></div>
  <div class="spacer"></div>

  <div class="row">
    <div class="col mb-3">
      <div class="float-sm-left">
        <button type="button" class="btn btn-primary marright" (click)="goToHelpList()">My Requests</button>
        <button type="button" class="btn btn-primary" (click)="addRequest()">Add Request</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col helpmd">

      <p-table #dt [columns]="cols" [value]="faqList" [globalFilterFields]="['faqQuestion', 'faqAnswer']">
        <ng-template pTemplate="caption">
          <div class="d-flex flex-row justify-content-between align-items-center">

            <div class="dd95">
              <p-dropdown dropdownIcon="fa fa-caret-down" [options]="faqList" [(ngModel)]="selectedFeatureName"
                [readonly]="isDropDownHide === 'true'"
                (onChange)="dt.filterGlobal(selectedFeatureName.faqQuestion, 'contains')" optionLabel="faqQuestion"
                placeholder="Select"></p-dropdown>
            </div>
            <div class="d-flex flex-row column-gap-3 justify-content-end align-items-center">
              <div class="wdsrch p-inputgroup">
                <input class="form-control" type="text" pInputText placeholder="Search"
                  (input)="dt.filterGlobal($event.target.value, 'contains')" />
                <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>

              <button type="button" class="btn btn-primary" (click)="dt.clear()">Reset</button>

            </div>
          </div>

        </ng-template>
        <ng-template pTemplate="header" let-columns="columns">
        </ng-template>

        <ng-template pTemplate="body" let-rowValue let-columns="columns">

          <div class="qhd" [id]="rowValue.faqLibraryId">{{rowValue.faqQuestion}}</div>
          <div class="qans">
            <span *ngIf="rowValue.faqLibraryId === 57">Please click <a (click)="AddRequestSubscription()"
                class="himg cursorpointer alink"><b>here</b></a> to upgrade to a paid subscription.</span>
            <span *ngIf="rowValue.faqLibraryId !== 57">{{rowValue.faqAnswer}} </span>
          </div>
        </ng-template>

      </p-table>
    </div>
  </div>

</div>