import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppsettingsService } from 'src/app/core/appsettings.service';
import { ApiResponse } from 'src/app/core/apiresponse';
import { Site } from '../model/site.model';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, private settings: AppsettingsService) {}

  getSubscription(siteId: number) {
    return this.http.post<any>(
      this.settings.baseUrl + 'api/site/GetSubscription/' + siteId,
      this.httpOptions
    );
  }
  updateSite(site: Site) {
    return this.http.put<ApiResponse>(
      `${this.settings.baseUrl}api/site/${site.siteId}`,
      site,
      this.httpOptions
    );
  }
}
