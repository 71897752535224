import { Component, OnInit } from '@angular/core';
import { EncrDecrService } from '../../../EncrDecr/encr-decr.service';
import { Router } from '@angular/router';
import { NavMenuService } from '../nav-menu/nav-menu.service';
import { AppAccessService } from 'src/app/core/directives/access.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  routeChanger: boolean;
  isSiteHome: boolean;
  showUserManagement = false;
  constructor(
    private EncrDecr: EncrDecrService,
    private router: Router,
    private navService: NavMenuService,
    private appAccessService: AppAccessService
  ) {
    this.navService.siteHomeChanged$.subscribe(() => {
      if (sessionStorage.getItem('isSiteHome') === 'Yes') {
        this.isSiteHome = true;
      } else {
        this.isSiteHome = false;
      }
    });
  }

  ngOnInit() {
    if (sessionStorage.getItem('isSiteHome') === 'Yes') {
      this.isSiteHome = true;
    } else {
      this.isSiteHome = false;
    }
    this.navService.userTypeChanged$.subscribe(() => {
      this.showUserManagement = this.appAccessService.hasAccess(
        'Site User Management'
      );
    });
  }

  addRequest(requestType: string) {
    const reqTypeValue = this.EncrDecr.encryptData(requestType);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/layout/faq/addrequest'], {
        queryParams: {
          reqType: reqTypeValue,
        },
      })
    );
  }

  helpHomePage() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/layout/lido-training/help']));
  }
}
