<div class="b_container messages">

    <div class="rtheader">
        Edit Vendor<div class="closesidebar" (click)="onCancel()">X</div>
    </div>

    <div class="rtcontainer">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <p-messages [value]="msgs"></p-messages>

            <div class="row">
                <div class="col-12">
                    <div class="row my-3 align-items-center">
                        <label class="col-2 col-form-label">Vendor Name</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="text" formControlName="vendorName"
                                placeholder="Vendor Name" />
                        </div>
                    </div>
                    <div class="row my-3 align-items-center">
                        <label class="col-2 col-form-label">Vendor Url</label>
                        <div class="col-8">
                            <input pInputText class="form-control" formControlName="vendorUrl" type="text"
                                placeholder="Enter Valid URL with http:// or https://" />
                        </div>
                    </div>

                    <div class="row my-3 align-items-center">
                        <label class="col-2 col-form-label">Vendor Category</label>
                        <div class="col-8 dd95">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="vendorCategories"
                                formControlName="vendorCategoryId" optionLabel="categoryName"></p-dropdown>
                            <div *ngIf="form.controls['vendorCategoryId'].invalid && form.controls['vendorCategoryId'].touched"
                                class="alert alert-danger">
                                Vendor Category is Required
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center" *ngIf="vendorUser?.swaVendor === true">
                        <label class="col-2 col-form-label">Login Details</label>
                        <div class="col-sm-1">
                            <label class="chckbox">
                                <input type="checkbox" formControlName="changePassword" /><span
                                    class="checkmark"></span></label>
                        </div>
                        <div class="col-sm-7">
                            <div class="lableresetpwd">
                                Update Username and Password
                            </div>
                        </div>
                    </div>

                    <div class="row my-3 align-items-center" *ngIf="changeCredentials">
                        <label class="col-2 col-form-label">User Name</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="text" placeholder="User Name"
                                formControlName="vendorUserName" />
                        </div>
                    </div>

                    <div class="row my-3 align-items-center" *ngIf="changeCredentials">
                        <label class="col-2 col-form-label">Password</label>
                        <div class="col-8">
                            <div class="p-inputgroup">
                                <input type="password" pPassword class="form-control" [showPassword]="passwordVisible"
                                    placeholder="Password" feedback="false" formControlName="vendorPassword">
                                <span (click)="passwordVisible = !passwordVisible" *ngIf="!passwordVisible"
                                    class="p-inputgroup-addon"><i class="fa fa-eye"></i></span>
                                <span (click)="passwordVisible = !passwordVisible" *ngIf="passwordVisible"
                                    class="p-inputgroup-addon"><i class="fa fa-eye-slash"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="forminfo">
                            <ul>
                                <li><span class="mandatory">*</span> fields are mandatory</li>
                            </ul>
                        </div>
                    </div>

                    <div class="btnnav mt_15">
                        <button type="submit" [disabled]="!form.valid || subBtndisable===true"
                            class="btn btn-primary marright">
                            {{btnUpdate}}
                            <i class="fa fa-spinner fa-spin" *ngIf="btnUpdate==='Processing..'"></i>
                        </button>
                        <button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="row my-3">
                                <div class="col-sm-12">
                                    <b>Note:</b> Please click <a (click)=AddRequest()
                                        class="himg cursorpointer alink"><b>here</b></a> to request help if you are
                                    having problems
                                    setting up single sign-on.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </form>
    </div>

</div>