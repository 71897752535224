import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { VendorCategory } from '../model/vendorcategory.model';
import { AppsettingsService } from '../../core/appsettings.service';
import { VendorDropdown } from '../model/vendornamedropdown.model';
import { VendorUser } from '../model/vendoruser.model';
import { ApiResponse } from 'src/app/core/apiresponse';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  // Returns all vendor categories not deleted
  vendorCategoryDropdownListforAdd(): Observable<VendorCategory[]> {
    return this.http.get<VendorCategory[]>(
      this.settings.baseUrl + 'api/vendorcategory/vendorcategorydropdown'
    );
  }

  // Returns all vendor categories even if deleted
  vendorCategoryDropdownListforEdit(): Observable<VendorCategory[]> {
    return this.http.get<VendorCategory[]>(
      this.settings.baseUrl + 'api/vendorcategory/vendorcategorydropdownedit'
    );
  }

  createCustomVendor(vendorUser: VendorUser) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/vendor/CreateCustomVendor',
      vendorUser
    );
  }

  // Returns the list of standard vendors
  //TODO - Get category for default value
  getVendorsForDropdown(vendorType = 1): Observable<VendorDropdown[]> {
    return this.http.get<VendorDropdown[]>(
      this.settings.baseUrl + `api/vendor/VendorsForDropdown/${vendorType}`
    );
  }
}
