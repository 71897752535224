import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';

import { ApiResponse } from '../core/apiresponse';
import { UserService } from '../users/service/user.service';

import { User } from '../users/model/user.model';
import { EncrDecrService } from '../EncrDecr/encr-decr.service';
import { SecurityQuestionDTO } from '../users/model/securityquestionDto.model';
import { mergeMap } from 'rxjs/operators';
import { MenuItem, Message } from 'primeng/api';
import { RequestErrors, makeBreadCrumbs } from '../common/utils';
import { SecurityQuestionService } from '../users/service/securityquestion.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profileForm: UntypedFormGroup;
  showChangePassword = false;
  trialId = Number(sessionStorage.getItem('trialId'));
  msgs: Message[] = [];
  apires: ApiResponse;
  user: User;
  isSignupVerified: boolean;
  isProfileVerified: boolean;

  pMenuItems: MenuItem[] = [];
  public securityQuestions: SecurityQuestionDTO[];
  loadingSub = false;
  btnCreate: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private securityQuestionService: SecurityQuestionService,
    private router: Router,
    private encrDecrService: EncrDecrService
  ) {}

  cancelProfile() {
    if (!this.isSignupVerified || !this.user.isProfileVerified) {
      this.msgs = [
        {
          severity: 'warn',
          detail:
            'Set your password and set up your profile to leave this page',
        },
      ];
      return;
    }
    if (
      sessionStorage.getItem('isSiteHome') === 'no' ||
      Number(sessionStorage.getItem('numberOfSites')) === 1
    ) {
      this.router.navigate(['/layout/home']);
    } else {
      this.router.navigate(['/layout/sitehome']);
    }
  }

  uniqueQuestions(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const question1 = group.controls['securityQuestionId1'];
      const question2 = group.controls['securityQuestionId2'];
      const question3 = group.controls['securityQuestionId3'];
      if (
        question1.value.securityQuestionId ===
          question2.value.securityQuestionId ||
        question2.value.securityQuestionId ===
          question3.value.securityQuestionId ||
        question3.value.securityQuestionId ===
          question1.value.securityQuestionId
      ) {
        group.setErrors({ notEquivalent: true });
      }
      return null;
    };
  }

  onSubmit() {
    this.loadingSub = true;
    this.btnCreate = 'Processing..';
    this.user.shortName = this.profileForm.controls.shortName.value;
    this.user.displayName = this.profileForm.controls.displayName.value;
    sessionStorage.setItem('displayName', this.user.displayName);
    this.user.phoneNumber = this.profileForm.controls.phoneNumber.value;
    this.user.email = this.profileForm.controls.email.value;
    this.user.securityQuestionId1 =
      this.profileForm.controls.securityQuestionId1.value.securityQuestionId;
    this.user.answer1 = this.profileForm.controls.answer1.value;
    this.user.securityQuestionId2 =
      this.profileForm.controls.securityQuestionId2.value.securityQuestionId;
    this.user.answer2 = this.profileForm.controls.answer2.value;
    this.user.securityQuestionId3 =
      this.profileForm.controls.securityQuestionId3.value.securityQuestionId;
    this.user.answer3 = this.profileForm.controls.answer3.value;
    this.user.signatureOption = parseInt(
      this.profileForm.controls.signatureOption.value
    );
    this.user.isProfileVerified = true;
    this.user.isSignatureAccept = true;
    this.saveUser();
  }

  saveUser() {
    this.userService.editUser(this.user.userId, this.user).subscribe(
      (messageResponse) => {
        this.apires = messageResponse;
        this.loadingSub = false;
        this.btnCreate = 'Submit';
        if (this.apires.success === true) {
          this.isProfileVerified = true;
          sessionStorage.setItem('isProfileVerified', 'true');
          if (this.isSignupVerified) {
            this.cancelProfile();
          }
        }

        if (this.apires.success === false) {
          this.msgs = [
            {
              severity: 'error',
              detail: this.apires.errorMessages[0],
            },
          ];
        }
      },
      (error) => {
        this.loadingSub = false;
        this.btnCreate = 'Submit';
        this.msgs = [];
        let errors: string[] = [];
        errors = RequestErrors(error);
        for (const msg of errors) {
          this.msgs.push({ severity: 'warn', detail: msg });
        }
      }
    );
  }

  ngOnInit() {
    this.initForm();
    this.pMenuItems = makeBreadCrumbs(false, 'Profile');
    this.btnCreate = 'Submit';
    this.isSignupVerified = this.encrDecrService.decryptData(
      sessionStorage.getItem('sgv')
    );
    this.isSignupVerified =
      sessionStorage.getItem('isSignupVerified') === 'true';
    this.isProfileVerified =
      sessionStorage.getItem('isProfileVerified') === 'true';

    this.getData();
    if (!this.isSignupVerified) {
      this.changePassword();
    }
  }

  initForm() {
    this.profileForm = this.formBuilder.group({
      shortName: ['', [Validators.required, Validators.minLength(3)]],
      displayName: ['', [Validators.required, Validators.minLength(3)]],
      phoneNumber: [''],
      email: [''],
      signatureOption: [''],
      Signature: [''],
      Initials: [''],
      Signature2: [''],
      Initials2: [''],
      Signature3: [''],
      Initials3: [''],
      securityQuestionId1: ['', [Validators.required]],
      answer1: ['', [Validators.required]],
      securityQuestionId2: ['', [Validators.required]],
      answer2: ['', [Validators.required]],
      securityQuestionId3: ['', [Validators.required]],
      answer3: ['', [Validators.required]],
    });
    this.profileForm.controls['securityQuestionId1'].valueChanges.subscribe(
      (val) => {
        if (
          val === this.profileForm.controls.securityQuestionId2.value ||
          val === this.profileForm.controls.securityQuestionId3.value
        ) {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            summary: 'Question Already Selected..!',
            detail: 'Please Select Different Security Question',
          });
          this.profileForm.controls.securityQuestionId1.patchValue('');
        }
      }
    );
    this.profileForm.controls['securityQuestionId2'].valueChanges.subscribe(
      (val) => {
        if (
          val === this.profileForm.controls.securityQuestionId1.value ||
          val === this.profileForm.controls.securityQuestionId3.value
        ) {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            summary: 'Question Already Selected..!',
            detail: 'Please Select Different Security Question',
          });
          this.profileForm.controls.securityQuestionId2.patchValue('');
        }
      }
    );
    this.profileForm.controls['securityQuestionId3'].valueChanges.subscribe(
      (val) => {
        if (
          val === this.profileForm.controls.securityQuestionId1.value ||
          val === this.profileForm.controls.securityQuestionId2.value
        ) {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            summary: 'Question Already Selected..!',
            detail: 'Please Select Different Security Question',
          });
          this.profileForm.controls.securityQuestionId2.patchValue('');
        }
      }
    );
  }

  patchForm() {
    this.profileForm.patchValue({
      shortName: this.user.shortName,
      displayName: this.user.displayName,
      email: this.user.email,
      phoneNumber: this.user.phoneNumber,
      signatureOption: this.user.signatureOption.toString(),
    });
    if (this.user.securityQuestionId1 != null) {
      const sq1 = this.securityQuestions.find(
        (x) => x.securityQuestionId === this.user.securityQuestionId1
      );
      this.profileForm.patchValue({
        securityQuestionId1: sq1,
        answer1: this.user.answer1,
      });
    }
    if (this.user.securityQuestionId2 != null) {
      const sq2 = this.securityQuestions.find(
        (x) => x.securityQuestionId === this.user.securityQuestionId2
      );
      this.profileForm.patchValue({
        securityQuestionId2: sq2,
        answer2: this.user.answer2,
      });
    }
    if (this.user.securityQuestionId3 != null) {
      const sq3 = this.securityQuestions.find(
        (x) => x.securityQuestionId === this.user.securityQuestionId3
      );
      this.profileForm.patchValue({
        securityQuestionId3: sq3,
        answer3: this.user.answer3,
      });
    }
  }

  changePassword() {
    this.showChangePassword = true;
  }

  closeChangePassword(passwordSaved = false) {
    if (this.isSignupVerified === false) {
      this.isSignupVerified = passwordSaved;
    }
    this.showChangePassword = false;
  }

  getData() {
    const tokenUserId = this.encrDecrService.decryptData(
      sessionStorage.getItem('tokenUserId')
    );
    this.userService
      .getByUserId(tokenUserId)
      .pipe(
        mergeMap((user) => {
          this.user = user;
          this.isProfileVerified = this.user.isProfileVerified;
          return this.securityQuestionService.securityQuestionDropDown();
        })
      )
      .subscribe(
        (res: SecurityQuestionDTO[]) => {
          this.securityQuestions = res;
          this.patchForm();
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }
}
