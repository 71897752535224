<div class="b_container">

  <div class="rtheader">
    Route - {{ sourceFileName }}
    <span class="closesidebar" (click)=" closeDocumentRouting()">X</span>
  </div>

  <div class="rtcontainer messages" id="docRoutingScrollPanel">
    <p-messages [value]="msgs"></p-messages>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

      <app-workflow-routing [binderDocumentType]="binderDocumentType"
        (updatedUserList)="updatedUserList($event)"></app-workflow-routing>

      <div class="btnnav">
        <button type="submit" class="btn btn-primary marright" [disabled]="!form.valid">Submit</button>

        <button type="button" (click)=" closeDocumentRouting()" class="btn btn-default">Cancel</button>
      </div>

    </form>

  </div>
</div>