<div class="b_container messages">
  <p-menu #menu [popup]="true" [model]="dotMenuActions" appendTo="body"></p-menu>
  <p-confirmDialog #cd>
    <p-button type="button" icon="pi pi-check" (click)="cd.accept()">Yes</p-button>
    <p-button class="p-button-info" icon="pi pi-times" type="p-button" (click)="cd.reject()">No</p-button>
  </p-confirmDialog>
  <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>

  <div class="row">
    <div class="col-12 mb-2">
      <button *ngIf="!parentIsArchived" type="button" class="btn btn-primary marright" (click)="openAddFolder()"
        [disabled]="trialArchived || showArchivedFolders" appAccessHide="Add/Edit Docs">
        Add Subfolder
      </button>
      <button *ngIf="!parentIsArchived" type="button" class="btn btn-primary marright" (click)="openAddDocument()"
        [disabled]="trialArchived || showArchivedFolders" appAccessHide="Add/Edit Docs">
        Add Document
      </button>
      <button type="button" appAccessHide="Doc Flow Log" class="btn btn-primary marright" (click)="docFlowLog()">
        Doc Flow Log
      </button>

      <button class="btn btn btn-primary marright" type="button" appAccessHide="Show Archived Docs"
        (click)="toggleShowArchived()">
        {{ archivedFoldersLabel }}
      </button>
    </div>
  </div>

  <div class="contentnav">
    <div class="row">
      <div class="col-md-12 doclist">
        <p-messages [value]="msgs"></p-messages>

        <p-dataView #dv [value]="documentList" filterBy="filterBy" [layout]="currentView"
          [emptyMessage]="emptyRecordMsg">
          <p-header>


            <div class="d-flex flex-row justify-content-between align-items-center">
              <p-dropdown dropdownIcon="fa fa-caret-down" [options]="sortOptions" [(ngModel)]="sortKey"
                placeholder="Sort By" (onChange)="onSortChange($event)"></p-dropdown>


              <div class="d-flex flex-row column-gap-3 justify-content-end align-items-center">
                <div class="wdsrch p-inputgroup">
                  <input class="form-control" type="text" pInputText placeholder="Name"
                    (keyup)="dv.filter($event.target.value)" />
                  <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
                </div>

                <p-dataViewLayoutOptions (click)="onLayoutChange($event, dv)"></p-dataViewLayoutOptions>
              </div>
            </div>

            <div *ngIf="currentView === 'list'" class="d-flex flex-row listheader align-items-center">
              <div class="col-sm-2">Type</div>
              <div class="col-sm-3">Name</div>
              <div class="col-sm-3">Uploaded By</div>
              <div class="col-sm-3">Uploaded On</div>
              <div class="col-sm-1 text-center">Actions</div>
            </div>
          </p-header>

          <ng-template let-document pTemplate="listItem">
            <div class="p-lg-12">
              <div class="d-flex flex-row align-items-center" role="button"
                [ngClass]="{ filtertr: document.isArchived === true }" (click)="onDocClick(document)">
                <div class="col-sm-2">
                  <app-doc-type-icon [documentType]="document.docType" size="25"></app-doc-type-icon>
                </div>
                <div class="col-sm-3" *ngIf="document.isDocument; else isFolder">
                  {{ document.subjectDocumentName }}.{{ document.docType }}
                </div>
                <ng-template #isFolder>
                  <div class="col-sm-3">
                    {{ document.subjectRecordsFolderName }}
                  </div>
                </ng-template>
                <div class="col-sm-3">
                  {{ document.modifiedByUserName }}
                </div>
                <div class="col-sm-3">
                  {{ document.modifiedOn | date : 'fullDate' }}
                </div>
                <div class="col-sm-1 text-center">
                  <button (click)="$event.stopPropagation(); onShowMenu($event, document)" class="menu-button">
                    <i id="menubutton" class="pi pi-ellipsis-v"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template let-document pTemplate="gridItem">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 gnav">
              <p-panel class="gridpad">


                <!-- if this is a folder-->
                <div class="docicon" role="button">
                  <a (click)="onDocClick(document)">
                    <app-doc-type-icon [documentType]="document.docType"></app-doc-type-icon>
                  </a>

                  <span *ngIf="document.docType !== 'Folder'" [pTooltip]="document.subjectDocumentName"
                    tooltipPosition="top" class="flabel text-truncate">{{
                    document.subjectDocumentName | truncate: 15
                    }}
                  </span>
                  <span *ngIf="document.docType === 'Folder'" [pTooltip]="document.subjectRecordsFolderName"
                    tooltipPosition="top" class="flabel text-truncate">{{
                    document.subjectRecordsFolderName | truncate: 15
                    }}</span>

                </div>

                <div class="gfbtnnav">
                  <button [disabled]="document.isArchived" (click)="onShowMenu($event, document)" class="menu-button">
                    <i id="menubutton" class="pi pi-ellipsis-v"></i>
                  </button>
                </div>
              </p-panel>
            </div>
          </ng-template>
        </p-dataView>
      </div>
    </div>
  </div>

  <p-dialog #pdialog *ngIf="loadDocumentViewer" [style]="{width: '75%'}" [visible]="loadDocumentViewer" [modal]="true"
    [draggable]="false" [closable]="false" [baseZIndex]="10000">
    <p-header>
      {{ selectedDocument.subjectDocumentName }}
    </p-header>
    <app-document-viewer [fileExtension]="selectedDocument.docType" [fileName]="selectedDocument.subjectDocumentName"
      [docPath]="selectedDocument.docPath" (closeModal)="closeModals(true)"></app-document-viewer>
  </p-dialog>

  <p-sidebar *ngIf="loadAddDocument" [dismissible]="false" [visible]="loadAddDocument" position="right"
    [baseZIndex]="10000" styleClass="p-sidebar-md">
    <app-add-docs (closeAddDocsModal)="closeModals($event)" [addDocumentData]="addDocumentData"></app-add-docs>
  </p-sidebar>

  <p-sidebar *ngIf="loadAddFolder" [visible]="loadAddFolder" position="right" [baseZIndex]="10000"
    styleClass="p-sidebar-md">
    <app-add-folder (closeModal)="closeModals($event)" [folder]="folder"></app-add-folder>
  </p-sidebar>

  <p-sidebar *ngIf="loadDocumentRouting" [dismissible]="false" [visible]="loadDocumentRouting" position="right"
    [baseZIndex]="10000" styleClass="p-sidebar-md">
    <app-document-routing (closeRouteDocNav)="closeModals($event)" [sourceFileId]="selectedDocument.subjectDocumentId"
      [binderDocumentType]="documentType" [sourceFileName]="selectedDocument.subjectDocumentName"
      [subjectId]="subjectId" [sourceFolderId]="selectedDocument.subjectRecordFolderId"></app-document-routing>
  </p-sidebar>
</div>