export class HelpDetails {
  helpDetailsId: number;
  helpDescription: string;
  helpReasonId: number;
  helpReasonName: string;
  helpPriorityId: number;
  userId: number;
  docName: string;
  docType: string;
  docPath: string;
  siteId?: number;
  trialId?: number;
  hasAttachments: boolean;
  isDeleted: boolean;
  helpStatusId: number;
  helpStatusName?: string;
  displayName?: string;
  email?: string;
  phoneNumber?: string;

  constructor(defaultValues: Partial<HelpDetails>) {
    Object.keys(defaultValues).forEach((key) => {
      this[key] = defaultValues[key];
    });
  }
}
