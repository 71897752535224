import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrialService } from '../trial/service/trial.service';
import { Trial } from '../trial/model/trial.model';
import { EncrDecrService } from '../EncrDecr/encr-decr.service';
import { UserSite } from '../users/model/user-site.model';
import { ApiResponse } from '../core/apiresponse';
import { AuthUser } from '../users/model/authuser.model';
import { AppConstants } from '../common/app.constants';
import { AuthService } from '../auth/auth.service';
import { MenuItem, Message } from 'primeng/api';
import { RequestErrors, makeBreadCrumbs } from '../common/utils';
import { SiteService } from './services/site.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  siteName = sessionStorage.getItem('siteName');
  siteId = Number(sessionStorage.getItem('siteId'));
  numberOfSites = Number(sessionStorage.getItem('numberOfSites'));
  initialView = AppConstants.vendorFlowView;
  currentView = AppConstants.vendorFlowView;
  dataViewRecsPerPage = AppConstants.gridViewRecsPerPage;
  recsPerPage = 6;
  pageLinksPerPage = AppConstants.dataViewPagerLinks;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  isSiteChanged = false;
  pMenuItems: MenuItem[] = [];
  showActiveTrials = true;
  defaultSponsorLogo = AppConstants.defaultSponsorLogo;
  displayTrialSubscriptionDialog = false;
  trials: Trial[];
  archivedTrials: Trial[];
  activeTrials: Trial[];
  allTrials: Trial[];
  showAllTrialsLabel = 'Archived Trials';
  sortOptions = [
    { label: 'Name A to Z', value: 'trialName' },
    { label: 'Name Z to A', value: '!trialName' },
    { label: 'Status', value: 'trialStatusName' },
  ];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  sites: UserSite[];
  sitesChanges: UserSite = {};
  authUser: AuthUser;

  isBcLink = false;
  tid: number;

  loadAddTrial = false;
  loadEditTrial = false;

  msgs: Message[] = [];

  tokenUserId: number;
  apires: ApiResponse;
  redirectUrl: string;

  constructor(
    private trialService: TrialService,
    private siteService: SiteService,
    private router: Router,
    private EncrDecr: EncrDecrService,
    private authService: AuthService
  ) {}

  onLayoutChange($event, dv) {
    if (dv.layout === 'list') {
      this.recsPerPage = AppConstants.listViewRecsPerPage;
      this.currentView = 'list';
    } else {
      this.recsPerPage = AppConstants.gridViewRecsPerPage;
      this.currentView = 'grid';
    }
  }

  openAddTrial() {
    this.loadAddTrial = true;
  }

  openEditTrial(trial: Trial) {
    this.loadEditTrial = true;
    this.tid = trial.trialId;
  }

  closeModals(refresh = false) {
    this.loadAddTrial = false;
    this.loadEditTrial = false;
    if (refresh) {
      this.getData();
    }
  }

  public refresh() {
    this.getData();
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  getData() {
    this.sortKey = '';
    this.trialService.getAllTrialsBySiteId(this.siteId).subscribe(
      (trialsFromService) => {
        this.allTrials = trialsFromService;
        this.activeTrials = trialsFromService.filter(
          (x) => x.trialStatusId !== 5
        );
        this.archivedTrials = trialsFromService.filter(
          (x) => x.trialStatusId === 5
        );
        this.trials = this.showActiveTrials
          ? this.activeTrials
          : this.archivedTrials;
        if (this.numberOfSites === 1) {
          this.pMenuItems[1] = {
            label: this.showActiveTrials ? 'Active Trials' : 'Archived Trials',
          };
        } else {
          this.pMenuItems[2] = {
            label: this.showActiveTrials ? 'Active Trials' : 'Archived Trials',
          };
        }
      },
      (error) => {
        const errors = RequestErrors(error);
        this.msgs = errors.map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  goToTrial(trial: Trial) {
    sessionStorage.setItem('trialId', trial.trialId.toString());
    this.router.navigate(['/layout/trialVendors']);
  }

  toggleShowAllTrials() {
    this.sortKey = '';
    this.trials = [];
    this.showActiveTrials = !this.showActiveTrials;
    this.showAllTrialsLabel = this.showActiveTrials
      ? 'Archived Trials'
      : 'Active Trials';

    this.trials = this.showActiveTrials
      ? this.activeTrials
      : this.archivedTrials;
    if (this.numberOfSites === 1) {
      this.pMenuItems[1] = {
        label: this.showActiveTrials ? 'Active Trials' : 'Archived Trials',
      };
    } else {
      this.pMenuItems[2] = {
        label: this.showActiveTrials ? 'Active Trials' : 'Archived Trials',
      };
    }
  }

  ngOnInit() {
    this.tokenUserId = this.EncrDecr.decryptData(
      sessionStorage.getItem('tokenUserId')
    );
    this.sitesChanges.siteId = this.siteId;
    this.sitesChanges.trialId = 0;
    this.getSite();
    this.pMenuItems = makeBreadCrumbs(false);
  }

  getTrialSubscription() {
    this.displayTrialSubscriptionDialog = false;
    this.siteService.getSubscription(this.siteId).subscribe(
      (messageResponse) => {
        if (messageResponse.success === true) {
          this.openAddTrial();
        } else {
          this.displayTrialSubscriptionDialog = true;
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  closeTrialSubscription() {
    this.displayTrialSubscriptionDialog = false;
  }

  AddRequest() {
    const reqTypeValue = this.EncrDecr.encryptData('AddRequest');
    const helpReason = this.EncrDecr.encryptData(
      'Upgrade to Paid Subscription'
    );
    this.router.navigate(['layout/faq/addrequest'], {
      queryParams: {
        reqType: reqTypeValue,
        helpReason: helpReason,
      },
    });
  }

  getSite() {
    this.authService.regenerateTokenOnSiteChange(this.sitesChanges).subscribe(
      (data) => {
        this.authUser = data;
        // Changing to a new site
        if (this.authUser.authsuccess === true) {
          if (this.authUser.numberOfSites === 0) {
            this.msgs = [];
            this.msgs.push({
              severity: 'warn',
              detail:
                'You are not yet assigned to a Site or Trial. Please reach out to your Site Administrator or open a Help Request with the LIDO Team for further assistance',
            });
          }
          this.authService.setAuthUserSessionStorage(this.authUser, '');
          this.getData();
        }
        if (this.authUser.authsuccess === false) {
          this.msgs = [];
          this.msgs.push({
            severity: 'warn',
            detail: this.apires.errorMessages[0],
          });
        }
      },

      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
