import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FaqEditRequestComponent } from './faqeditrequest.component';
import { HelpRequestListComponent } from './help-request-list/help-request-list.component';
import { HelpComponent } from './help/help.component';

@NgModule({
  declarations: [
    HelpComponent,
    HelpRequestListComponent,
    FaqEditRequestComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    HelpComponent,
    HelpRequestListComponent,
    FaqEditRequestComponent,
  ],
})
export class FaqModule {}
