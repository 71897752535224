<a href="#" id="notifymenu" class="dropdown-toggle" appcustomdropdown data-bs-toggle="dropdown" aria-haspopup="true"
  aria-expanded="false">
  <fa name="bell"></fa>
  <div class="badge badge-danger">{{itemCount}}</div>
  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="notifymenu">
    <div class="drpheader">
      ACTIONABLE ALERTS
    </div>
    <div class="drpbody">
      <a (click)="onEventClick(item)" *ngFor="let item of items">
        {{item.userEventName}}
        <p>{{item.createdOn}}</p>
      </a>
    </div>
    <div class="drpfooter">
      <a routerLink="/layout/notifications-list">Show More Actionable Alerts</a>
    </div>
  </div>
</a>