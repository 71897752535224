<div class="b_container messages">
  <p-messages [value]="msgs"></p-messages>
  <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
  <div class="clear"></div>
  <div class="spacer"></div>

  <div class="row">
    <div class="col-sm-12">
      <div class="datatable">
        <p-table #dv [columns]="cols" [value]="helpDetails" [globalFilterFields]="[
            'requestId',
            'helpDescription',
            'helpReasonName',
            'helpPriorityName',
            'userName',
            'status'
          ]">
          <ng-template pTemplate="caption" let-columns="columns">
            <div class="row">
              <div class="col-sm-4">
                <div class="wdsrch">
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" pInputText placeholder="Search"
                      (input)="dv.filterGlobal($event.target.value, 'contains')" />
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3 dd95 text-left"></div>
              <div class="col-sm-3 align-right">
                <button type="button" class="btn btn-primary marright" (click)="helpTopicsNav()">
                  Help Topics
                </button>
                <button type="button" class="btn btn-primary" (click)="addRequestNav()">
                  Add Request
                </button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th width="10%">Request ID</th>
              <th width="25%">Details</th>
              <th width="25%">Priority</th>
              <th width="25%">Reason</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <a (click)="editRequest(rowData)">{{
                  rowData.helpDetailsId
                  }}</a>
              </td>
              <td>
                {{ rowData.helpDescription | truncate : 30 }}
              </td>
              <td>
                <div *ngIf="rowData.helpPriorityId === 1">Low</div>
                <div *ngIf="rowData.helpPriorityId === 2">Medium</div>
                <div *ngIf="rowData.helpPriorityId === 3">High</div>
              </td>
              <td>{{ rowData.helpReasonName }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage"> No Record Found </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="spacer5"></div>
  <div class="spacer5"></div>
</div>