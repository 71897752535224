<div id="loading">
    <div class="loading"></div>
</div>

<div class="b_container messages">

    <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
    <div class="row">
        <div class="col-12 mb-2">
            <button *ngIf="userTrialRole !== undefined" type="button" (click)="openAddTrialVendor()"
                class="btn btn-primary marright" appAccessHide="Vendors" [disabled]="!allowEdits">Add Vendor </button>
            <button type=" button" (click)="openEditTrialHome()" class="btn btn-primary marright"
                appAccessHide="Add - Edit Trial">Edit Trial
            </button>
        </div>
    </div>

    <div class="contentnav">

        <div class="row">
            <div class="col-md-12 trialview">
                <p-messages [value]="msgs"></p-messages>
                <p-dataView #dv [value]="vendors" layout="grid" [layout]="initialView" [emptyMessage]="emptyRecordMsg"
                    filterBy="vendorName" [sortField]="sortField" [sortOrder]="sortOrder">

                    <p-header>


                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="sortOptions" [(ngModel)]="sortKey"
                                placeholder="Sort By" (onChange)="onSortChange($event)"></p-dropdown>


                            <div class="d-flex flex-row column-gap-3 justify-content-end align-items-center">
                                <div class="wdsrch p-inputgroup">
                                    <input class="form-control" type="text" pInputText placeholder="Vendor Name"
                                        (keyup)="dv.filter($event.target.value)" />
                                    <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
                                </div>

                                <p-dataViewLayoutOptions (click)="onLayoutChange($event, dv)"></p-dataViewLayoutOptions>
                            </div>

                        </div>
                        <div class="p-lg-12">
                            <div *ngIf="currentView === 'list'" class="d-flex flex-row listheader align-items-center">
                                <div class="col-sm-3">Vendor</div>
                                <div class="col-sm-3">Name</div>
                                <div class="col-sm-3">Category</div>
                                <div class="col-sm-1 text-center">Edit</div>
                                <div class="col-sm-1 text-center">Unlink</div>
                            </div>
                        </div>

                    </p-header>

                    <ng-template let-vendor pTemplate="gridItem">

                        <div class="p-g-12  p-md-6 p-lg-4 gnav trialhomegrid">
                            <p-panel class="gridpad">
                                <ng-template pTemplate="header">
                                    <div style="width: 95%;" [pTooltip]="vendor.vendorName" tooltipPosition="right">
                                        {{ vendor.vendorName | truncate:20 }}
                                    </div>
                                    <button (click)="openUnlinkTrialVendor(vendor)" class="unlinkvendor"
                                        appAccess="Vendors">
                                        <fa name="chain-broken" class="fa-md" pTooltip="Unlink Vendor"
                                            tooltipPosition="top"></fa>
                                    </button>
                                </ng-template>
                                <div (click)="goToVendorSite(vendor)" class="d-flex trialhomecontainer cursorpointer">
                                    <div class="col align-items-center tvendor_logo" id="divTrialLogo">

                                        <ng-container
                                            *ngIf="((vendor.vendorLogo !== undefined) && (vendor.vendorLogo !== '') && (vendor.vendorLogo !== null)); else noLogo">
                                            <img src="{{vendor.vendorLogo | imgurl}}" class="logo-cardsize"
                                                alt="Vendor Logo" />
                                        </ng-container>
                                        <ng-template #noLogo>
                                            <img src="{{defaultVendorLogo}}" class="logo-cardsize" alt="Vendor Logo" />
                                        </ng-template>

                                        <div>{{vendor.categoryName}}</div>
                                    </div>

                                    <div class="gfbtnnav">
                                        <button (click)="$event.stopPropagation(); openEditTrialVendor(vendor)"
                                            class="editbtn" appAccess="Vendors">
                                            <fa name="pencil" pTooltip="Edit Vendor" tooltipPosition="top"></fa>
                                        </button>
                                    </div>
                                </div>
                            </p-panel>
                        </div>

                    </ng-template>

                    <ng-template let-vendor pTemplate="listItem">
                        <div class="p-lg-12">
                            <div class="d-flex flex-row align-items-center vdr-grey" role="button">
                                <div class="col-sm-3">

                                    <a (click)="goToVendorSite(vendor)" appAccess="Vendors" class="cursorpointer">
                                        <ng-container
                                            *ngIf="((vendor.vendorLogo !== undefined) && (vendor.vendorLogo !== '') && (vendor.vendorLogo !== null)); else noLogo">
                                            <img src="{{vendor.vendorLogo | imgurl }}" class="logo-listsize"
                                                alt="Vendor Logo" />
                                        </ng-container>
                                        <ng-template #noLogo>
                                            <img src="{{defaultVendorLogo}}" class="logo-listsize" alt="Vendor Logo" />
                                        </ng-template>
                                    </a>
                                </div>
                                <div class="col-sm-3">
                                    {{vendor.vendorName}}
                                </div>
                                <div class="col-sm-3">{{vendor.categoryName}}</div>
                                <div class="col-sm-1 text-center">
                                    <button (click)="$event.stopPropagation(); openEditTrialVendor(vendor)"
                                        class="listeditbtn" appAccess="Vendors">
                                        <fa name="pencil" pTooltip="Edit Vendor" tooltipPosition="top"></fa>
                                    </button>
                                </div>
                                <div class="col-sm-1 text-center">
                                    <button (click)="$event.stopPropagation(); openUnlinkTrialVendor(vendor)"
                                        class="listeditbtn" appAccess="Vendors">
                                        <fa name="chain-broken" pTooltip="Unlink Vendor" tooltipPosition="top"></fa>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </ng-template>

                </p-dataView>
            </div>
        </div>

    </div>

    <p-sidebar *ngIf="loadAddTrialVendor" [visible]="loadAddTrialVendor" [dismissible]="false" position="right"
        [baseZIndex]="10000" styleClass="p-sidebar-md">
        <app-add-trial-vendor (closeVendornav)="closeModals($event)"></app-add-trial-vendor>
    </p-sidebar>

    <p-sidebar *ngIf="loadEditTrialVendor" [visible]="loadEditTrialVendor" [dismissible]="false" position="right"
        [baseZIndex]="10000" styleClass="p-sidebar-md">
        <app-edit-trial-vendor (closeEditTrialVendorDetailsModal)="closeModals($event)"
            [vendorId]="selectedVendor.vendorId" [trialId]="trialId"></app-edit-trial-vendor>
    </p-sidebar>

    <p-sidebar *ngIf="loadUnlinkTrialVendor" [visible]="loadUnlinkTrialVendor" [dismissible]="false" position="right"
        [baseZIndex]="10000" styleClass="p-sidebar-md">
        <app-unlink-trial-vendor (closeUnlinkVendornav)="closeModals($event)"
            [vendorId]="selectedVendor.vendorId"></app-unlink-trial-vendor>
    </p-sidebar>

    <p-dialog [modal]="true" *ngIf="OktaConfirm" [style]="{width: '50vw'}" [visible]="OktaConfirm">
        <app-okta-confirmation (closeOktaPopup)="OktaConfirm=$event"></app-okta-confirmation>
    </p-dialog>

    <p-sidebar *ngIf="loadEditTrialHome" [visible]="loadEditTrialHome" (onHide)="loadEditTrialHome = false"
        [dismissible]="false" position="right" [baseZIndex]="10000" styleClass="p-sidebar-md">
        <app-edit-trial [trialId]="trialId" (closeModal)="closeModals($event)">
        </app-edit-trial>
    </p-sidebar>
</div>

<iframe id="okta-frame" style="display:none" [src]="url"
    onload="contentWindow.postMessage('pluginVersion', 'https://lido-io.okta.com/app/UserHome/plugin-info')"></iframe>