import { Component, OnInit, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-okta-confirmation',
  templateUrl: './okta-confirmation.component.html',
  styleUrls: ['./okta-confirmation.component.scss'],
})
export class OktaConfirmationComponent implements OnInit {
  @Output() closeOktaPopup = new EventEmitter<boolean>();
  btnName: string;

  ngOnInit() {
    this.btnName = 'Install Plugin';
  }
  closePluginNav() {
    this.btnName = 'Install Plugin';
    this.closeOktaPopup.emit(false);
  }

  OktaPlugin() {
    if (this.btnName == 'Install Plugin') {
      this.btnName = 'Close';
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf('edge') > -1:
          window.open(
            'https://www.microsoft.com/en-us/p/okta-browser-plugin/9pkzgd0qvlx6?activetab=pivot:overviewtab'
          );
          return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
          return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
          window.open(
            'https://chrome.google.com/webstore/detail/okta-browser-plugin/glnpjglilkicbckjpbgcfkogebgllemb/related',
            '_blank'
          );
          // this.closeOkt aPopup.emit(false);
          return;
        case agent.indexOf('trident') > -1:
          return 'ie';
        case agent.indexOf('firefox') > -1:
          window.open(
            'https://addons.mozilla.org/en-US/firefox/addon/okta-browser-plugin/',
            '_blank'
          );
          return 'firefox';
        case agent.indexOf('safari') > -1:
          return 'safari';
        default:
          return 'other';
      }
    } else {
      this.btnName = 'Install Plugin';
      this.closeOktaPopup.emit(false);
    }
  }
}
