import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FaqLibrary } from '../model/faqlibrary';
import { EncrDecrService } from 'src/app/EncrDecr/encr-decr.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Message } from 'primeng/api';
import { RequestErrors, makeBreadCrumbItemsHelp } from 'src/app/common/utils';
import { FaqService } from '../service/faq.service';
import { NavMenuService } from 'src/app/layout/components/nav-menu/nav-menu.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  providers: [ConfirmationService],
})
export class HelpComponent implements OnInit {
  faqList: FaqLibrary[];
  msgs: Message[];
  cols: any[];
  selectedFeatureName: FaqLibrary;
  pMenuItems: MenuItem[] = [];

  constructor(
    public faqService: FaqService,
    private router: Router,
    private encrDecr: EncrDecrService,
    private navMenuService: NavMenuService
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'faqQuestion', header: 'question' },
      { field: 'faqAnswer', header: 'answer' },
    ];
    this.navMenuService.trialState$.subscribe((trialDetails) => {
      this.pMenuItems = makeBreadCrumbItemsHelp(true, trialDetails.trialSelected);
    });

    this.getData();
  }

  goToHelpList() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/layout/faq/helprequestlist']));
  }

  addRequest(requestType = 'AddRequest') {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/layout/faq/addrequest'], {
        queryParams: {
          reqType: requestType,
        },
      })
    );
  }

  getData() {
    this.faqService.getFeatureDetails().subscribe(
      (data) => {
        this.faqList = data;
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  AddRequestSubscription() {
    const reqTypeValue = this.encrDecr.encryptData('AddRequest');
    const helpReason = this.encrDecr.encryptData(
      'Upgrade to Paid Subscription'
    );
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/layout/faq/addrequest'], {
        queryParams: {
          reqType: reqTypeValue,
          helpReason: helpReason,
        },
      })
    );
  }
}
