import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { ResetRegistrationValidator } from '../reset-password/resetpassword-equal-validator';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';

import { ApiResponse } from '../../core/apiresponse';
import { ForgotPasswordService } from '../forgot-password/service/forgotpassword.service';
import { ResetPassword } from '../forgot-password/model/resetpassword.model';
import { RequestErrors } from 'src/app/common/utils';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  resetPassword: ResetPassword = {};
  passwordVisible = false;
  repeatPasswordVisible = false;
  apires: ApiResponse;
  msgs: Message[] = [];
  respwdtxt: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private forgotPasswordService: ForgotPasswordService
  ) {}

  getYear() {
    return new Date().getUTCFullYear();
  }

  cancelResetPassword() {
    this.router.navigate(['/auth/login'], { skipLocationChange: true });
    sessionStorage.clear();
  }

  onSubmit() {
    this.resetPassword.currentPassword =
      this.form.controls.repeatPassword.value;
    this.resetPassword.changedPassword = this.form.controls.password.value;
    this.resetPassword.userId = sessionStorage.getItem('userId');
    this.resetPassword.sendEmail = false;
    this.resetPassword.requestType = +sessionStorage.getItem('requestType');
    this.forgotPasswordService.resetPassword(this.resetPassword).subscribe(
      (messageResponse) => {
        this.apires = messageResponse;
        if (this.apires.success === true) {
          this.authService.logout();
          this.router.navigate(['/auth/login'], {
            skipLocationChange: true,
          });
        }
        if (this.apires.success === false) {
          this.msgs = [
            {
              severity: 'error',
              detail: this.apires.errorMessages[0],
            },
          ];
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(12),
          ResetRegistrationValidator.pswvalidate,
        ]),
        repeatPassword: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(12),
          ResetRegistrationValidator.pswvalidate,
        ]),
      },
      {
        validator: ResetRegistrationValidator.validate.bind(this),
      }
    );
  }
}
