<div class="b_container messages">
    <div class="rtheader">
        {{pageTitle}}
        <div *ngIf="loading===true" class="closesidebar">X</div>
        <div *ngIf="loading!==true" class="closesidebar" (click)="closeForm()">X</div>
    </div>
    <div class="rtcontainer userform">
        <p-messages [value]="msgs"></p-messages>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="row my-3">
                <label class="col-sm-4 col-form-label">New Password</label>
                <div class="col-sm-8">
                    <div class="p-inputgroup">
                        <input type="password" pPassword class="form-control" [showPassword]="passwordVisible"
                            placeholder="Password" feedback="false" formControlName="password">
                        <span (click)="passwordVisible = !passwordVisible" *ngIf="!passwordVisible"
                            class="p-inputgroup-addon"><i class="fa fa-eye"></i></span>
                        <span (click)="passwordVisible = !passwordVisible" *ngIf="passwordVisible"
                            class="p-inputgroup-addon"><i class="fa fa-eye-slash"></i></span>
                    </div>

                </div>
                <p></p>
                <div class="alert alert-danger"
                    *ngIf="!form.controls['password'].valid && form.controls['password'].dirty">
                    Password should be Minimum 6 and Maximum 12 Characters, at least one UPPERCASE letter,
                    one lowercase letter, one number and one special character
                </div>
            </div>
            <div class="row my-3 row">
                <label class="col-sm-4 col-form-label">Confirm Password</label>
                <div class="col-sm-8">
                    <div class="p-inputgroup">
                        <input type="password" pPassword class="form-control" [showPassword]="repeatPasswordVisible"
                            placeholder="Confirm Password" feedback="false" formControlName="repeatPassword">
                        <span (click)="repeatPasswordVisible = !repeatPasswordVisible" *ngIf="!repeatPasswordVisible"
                            class="p-inputgroup-addon"><i class="fa fa-eye"></i></span>
                        <span (click)="repeatPasswordVisible = !repeatPasswordVisible" *ngIf="repeatPasswordVisible"
                            class="p-inputgroup-addon"><i class="fa fa-eye-slash"></i></span>

                    </div>
                </div>
                <p></p>
                <div class="alert alert-danger" *ngIf="(form.controls.repeatPassword.value!=='' && form.controls.password.value!==form.controls.repeatPassword.value)
                           && (form.dirty) && (form.touched)">
                    Password and Confirm Password must match
                </div>
            </div>
            <div class="btnnav">
                <button type="submit" class="btn btn-primary marright" [disabled]="!form.valid" pButton
                    label="Submit"></button>
                <button type="button" [disabled]="!isVerified" (click)="closeForm()"
                    class="btn btn-default">Cancel</button>
            </div>
        </form>
    </div>
</div>