<div class="b_container messages">
    <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
    <div class="pronav">

        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <p-messages [value]="msgs"></p-messages>

            <p-tabView>
                <p-tabPanel header="Edit Personal Details">

                    <div class="container">
                        <div class="col">
                            <div class="row my-3 mt-5 align-items-center">
                                <label class="col-2" for="shortName">Legal Name</label>
                                <div class="col-4">
                                    <input id="shortName" pInputText class="form-control" formControlName="shortName"
                                        type="text" />
                                </div>
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!profileForm.controls['shortName'].valid && profileForm.controls['shortName'].dirty">
                                Display Name must be at least 3 characters long
                            </div>
                        </div>

                        <div class="col">
                            <div class="row my-3 align-items-center">
                                <label class="col-2" for="displayName">Display Name</label>
                                <div class="col-4">
                                    <input id="displayName" pInputText class="form-control" type="text"
                                        formControlName="displayName" />
                                </div>
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!profileForm.controls['displayName'].valid && profileForm.controls['displayName'].dirty">
                                Display Name must be at least 3 characters long
                            </div>
                        </div>

                        <div class="col">
                            <div class="row my-3 align-items-center">
                                <label class="col-2" for="email">Email</label>
                                <div class="col-4">
                                    <input pInputText class="form-control" readonly type="email"
                                        placeholder="Email Address" formControlName="email" />
                                </div>

                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!profileForm.controls['email'].valid && profileForm.controls['email'].dirty">
                                Enter a valid email
                            </div>
                        </div>
                        <div class="col">
                            <div class="row my-3 align-items-center">
                                <label class="col-2">Phone</label>
                                <div class="col-4">
                                    <p-inputMask mask="(999) 999-9999" placeholder="(999) 999-9999"
                                        formControlName="phoneNumber">
                                    </p-inputMask>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="buttonrow"></ng-container>
                </p-tabPanel>
                <p-tabPanel header="Select Signature">
                    <div class="container">

                        <div class="row my-3 mt-5 align-items-center">
                            <div class="col-4">
                                <input class="marright" type="radio" formControlName="signatureOption"
                                    name="signatureOption" inputId="radioOption1" value="1" />
                                <label for="radioOption1">Signature Style 1</label>
                            </div>
                        </div>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['signatureOption'].valid && profileForm.controls['signatureOption'].dirty">
                            Choose at least one signature
                        </div>

                        <div class="row my-3 align-items-center">
                            <label class="col-2">Signature</label>
                            <div class="col-4">
                                <input pInputText [readonly]="true"
                                    value="{{profileForm.controls['displayName'].value}}"
                                    class="form-control form-control-signature signftf" formControlName="Signature"
                                    type="text" />
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-2">Initials</label>
                            <div class="col-4">
                                <input pInputText [readonly]="true"
                                    value="{{profileForm.controls['displayName'].value | GetfirstLetter}}"
                                    class="form-control signftf" formControlName="Initials" type="text" />
                            </div>
                        </div>




                        <div class="row my-3 align-items-center">
                            <div class="col-4">
                                <input class="marright" type="radio" formControlName="signatureOption"
                                    name="signatureOption" inputId="radioOption2" value="2" />
                                <label for="radioOption2">Signature Style 2</label>
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!profileForm.controls['signatureOption'].valid && profileForm.controls['signatureOption'].dirty">
                                Choose at least one signature
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-2">Signature</label>
                            <div class="col-4">
                                <input pInputText [readonly]="true"
                                    value="{{profileForm.controls['displayName'].value}}" class="form-control signfts"
                                    formControlName="Signature2" type="text" />
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-2">Initials</label>
                            <div class="col-4">
                                <input pInputText [readonly]="true"
                                    value="{{profileForm.controls['displayName'].value | GetfirstLetter}}"
                                    class="form-control signfts" formControlName="Initials2" type="text" />
                            </div>
                        </div>



                        <div class="row my-3 align-items-center">
                            <div class="col-4">
                                <input class="marright" type="radio" formControlName="signatureOption"
                                    name="signatureOption" inputId="radioOption3" value="3" />
                                <label for="radioOption3">Signature Style 3</label>
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!profileForm.controls['signatureOption'].valid && profileForm.controls['signatureOption'].dirty">
                                Choose at least one signature
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-2">Signature</label>
                            <div class="col-4">
                                <input pInputText [readonly]="true"
                                    value="{{profileForm.controls['displayName'].value}}" class="form-control signftt"
                                    formControlName="Signature3" type="text" />
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-2">Initials</label>
                            <div class="col-4">
                                <input pInputText [readonly]="true"
                                    value="{{profileForm.controls['displayName'].value | GetfirstLetter}}"
                                    class="form-control signftt" formControlName="Initials3" type="text" />
                            </div>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="buttonrow"></ng-container>


                </p-tabPanel>
                <p-tabPanel header="Edit Security Questions">
                    <div class="container">
                        <div class="row my-3 mt-5 align-items-center dd95">
                            <label class="col-2">Security Question 1</label>
                            <div class="col-4">
                                <p-dropdown dropdownIcon="fa fa-caret-down" [options]="securityQuestions"
                                    formControlName="securityQuestionId1" placeholder="Security Question"
                                    optionLabel="securityQuestion">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-2">Security Answer 1</label>
                            <div class="col-4">
                                <input pInputText class="form-control" formControlName="answer1" type="text"
                                    placeholder="Answer 1" />
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!profileForm.controls['answer1'].valid && profileForm.controls['answer1'].dirty">
                                Answer 1 is Required
                            </div>
                        </div>

                        <div class="row my-3 align-items-center dd95">
                            <label class="col-2">Security Answer 2</label>
                            <div class="col-4">
                                <p-dropdown dropdownIcon="fa fa-caret-down" [options]="securityQuestions"
                                    formControlName="securityQuestionId2" placeholder="Security Question"
                                    optionLabel="securityQuestion"></p-dropdown>
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-2">Security Answer 2</label>
                            <div class="col-4">
                                <input pInputText class="form-control" type="text" formControlName="answer2"
                                    placeholder="Answer 2" />
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!profileForm.controls['answer2'].valid && profileForm.controls['answer2'].dirty">
                                Answer 2 is Required
                            </div>
                        </div>

                        <div class="row my-3 align-items-center dd95">
                            <label class="col-2">Security Answer 3</label>
                            <div class="col-4">
                                <p-dropdown dropdownIcon="fa fa-caret-down" [options]="securityQuestions"
                                    formControlName="securityQuestionId3" placeholder="Security Question"
                                    optionLabel="securityQuestion"></p-dropdown>
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-2">Security Answer 3</label>
                            <div class="col-4">
                                <input pInputText class="form-control" type="text" formControlName="answer3"
                                    placeholder="Answer 3" />
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="!profileForm.controls['answer3'].valid && profileForm.controls['answer3'].dirty">
                                Answer 3 is Required
                            </div>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="buttonrow"></ng-container>
                </p-tabPanel>

            </p-tabView>


        </form>
    </div>
</div>

<ng-template #buttonrow>
    <div class="btnnav">
        <button type="submit" [disabled]="(!profileForm.valid)
        || (btnCreate==='Processing..')" class="btn btn-primary marright">{{btnCreate}}
            <i class="fa fa-spinner fa-spin" *ngIf="loadingSub"></i>
        </button>
        <button type="button" (click)="cancelProfile()" class="btn btn-default marright">Cancel</button>
        <button class="btn btn-primary marright" type="button" (click)="changePassword()">Change
            Password</button>
    </div>
</ng-template>

<p-sidebar *ngIf="showChangePassword" [visible]="showChangePassword" position="right" [blockScroll]="true"
    [dismissible]="false" [baseZIndex]="10000" [closeOnEscape]="false" styleClass="p-sidebar-md" blockScroll="false">
    <app-change-password [user]="user" [isVerified]="isSignupVerified"
        (closeChangePassword)="closeChangePassword($event)"></app-change-password>
</p-sidebar>