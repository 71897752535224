import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppsettingsService } from '../../core/appsettings.service';

import { SponsorDropdown } from '../model/sponsordropdown.model';

@Injectable({
  providedIn: 'root',
})
export class SponsorLibraryService {
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  getSponsorList(): Observable<SponsorDropdown[]> {
    return this.http.get<[SponsorDropdown]>(
      this.settings.baseUrl + 'api/sponsorlibrary/SponsorLibraryDropdown'
    );
  }
}
