import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './auth/auth-http-error-interceptor';
import { AuthHttpInterceptor } from './auth/auth-http-interceptor';
import { AuthModule } from './auth/auth.module';
import { EnvServiceProvider } from './env-service/env.service.provider';
import { HomeModule } from './home/home.module';
import { LayoutModule } from './layout/layout.module';

import { OperationsBinderModule } from './operations-binder/operations-binder.module';
import { ProfileModule } from './profile/profile.module';
import { RegulatoryBinderModule } from './regulatory-binder/regulatory-binder.module';
import { SiteDocumentsModule } from './site-documents/site-documents.module';
import { SubjectModule } from './subject/subject.module';
import { TrialVendorsModule } from './trialvendors/trial-vendors.module';
import { UserListModule } from './users/user-list.module';
import { DocFlowLogModule } from './doc-flow-log/doc-flow-log.module';
import { FaqModule } from './FAQ/faq.module';
import { WorkFlowRoutingModule } from './workflow-routing/workflow-routing.module';
import { TermsOfUseModule } from './terms-of-use/terms-of-use.module';
import { SharedModule } from './shared/shared.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NotificationsModule } from './notifications/notifications.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    LayoutModule,
    AuthModule,
    SiteDocumentsModule,
    SubjectModule,
    DocFlowLogModule,
    TrialVendorsModule,
    FaqModule,
    UserListModule,
    OperationsBinderModule,
    RegulatoryBinderModule,
    NotificationsModule,
    ProfileModule,
    WorkFlowRoutingModule,
    HomeModule,
    TermsOfUseModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    EnvServiceProvider,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
