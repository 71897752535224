<div class="dialoghd">
  Okta Browser Plugin not Installed <div *ngIf="btnName ==='Install Plugin'" class="closesidebar"
    (click)="closePluginNav()">X</div>
</div>
<div class="scpanel">
  <p-scrollPanel [style]="{width: '100%', height: '400px'}">
    <p><b>If the Okta Browser Plugin is not installed on your browser you will see this pop up message:</b></p>
    <p>1. How to identify Okta Okta Browser Plugin is installed or not:</p>
    <ul>
      <li>If installed, you will see the Okta Browser Plugin Icon near browser address bar on right side
        <br><img src="assets/images/common/plugin-installed.png" />
      </li>
      <li>The icon won't be there if the plugin is not installed
        <br><img src="assets/images/common/plugin-notinstalled.png" />
      </li>
    </ul>
    <p>2. Click on "Install Plugin" button</p>
    <p>3. Okta Browser Plugin Installation Page will open up in new browser window or tab</p>
    <p>4. Install Okta Browser Plugin</p>
    <p>5. Now you will see Okta Browser Plugin Icon near browser address bar on right side</p>
    <p>7. Click on "X" on the okta pop up</p>
    <img src="assets/images/common/close-icon.PNG" /><br>
    <p>8. Close the Okta Browser Plugin Installation Page and go to LynK Application page</p>
    <p>9. Click on "Close" button and now you will not this pop up page again</p>

  </p-scrollPanel>
</div>

<p-footer>
  <button type="button" (click)="OktaPlugin()" class="btn btn-primary">{{btnName}} </button>
</p-footer>