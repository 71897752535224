<div class="b_container">
  <div class="rtheader">
    Add New Trial
    <div class="closesidebar" (click)="closeAddTrial()">X</div>
  </div>

  <div class="rtcontainer messages">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <p-messages [value]="msgs"></p-messages>
      <div class="row">

        <p-tabView>
          <p-tabPanel header="Trial Details">
            <div class="col-12">
              <div class="row my-3 mt-4 align-items-center">
                <label class="col-2">Site Name</label>
                <div class="col-8">
                  <input pInputText class="form-control" placeholder="{{ siteName }}" type="text" readonly />
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <label class="col-2">Trial Name</label>
                <div class="col-8">
                  <input pInputText class="form-control" type="text" formControlName="trialName"
                    placeholder="Trial name" />
                  <span class="mandatory">*</span>
                </div>

                <div class="alert alert-danger" *ngIf="
              !form.controls['trialName'].valid &&
              (form.controls['trialName'].dirty ||
                form.controls['trialName'].touched)
            ">
                  Trial name is required and must be at least 3 characters long
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <label class="col-2">Protocol Number</label>
                <div class="col-8">
                  <input pInputText class="form-control" type="text" formControlName="protocolNumber"
                    placeholder="Protocol Number" />
                  <span class="mandatory">*</span>

                </div>
                <div class="alert alert-danger" *ngIf="
              !form.controls['protocolNumber'].valid &&
              (form.controls['protocolNumber'].dirty ||
                form.controls['protocolNumber'].touched)
            ">
                  Protocol number is required and must be at least 3 characters long
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <label class="col-2">Project ID</label>
                <div class="col-8">
                  <input pInputText class="form-control" type="text" formControlName="projectId"
                    placeholder="Project ID" />
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <label class="col-2">Site Number</label>
                <div class="col-8">
                  <input pInputText class="form-control" type="text" formControlName="siteNumber"
                    placeholder="Site number" />
                  <span class="mandatory">*</span>
                </div>
                <div class="alert alert-danger" *ngIf="
              !form.controls['siteNumber'].valid &&
              (form.controls['siteNumber'].dirty ||
                form.controls['siteNumber'].touched)
            ">
                  Site number is required and must be at least 3 characters long
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <label class="col-2">Sponsor</label>
                <div class="col-8 dd95">
                  <p-dropdown dropdownIcon="fa fa-caret-down" [options]="sponsorList" formControlName="sponsorSelect"
                    placeholder="Sponsor" optionLabel="sponsorName">
                    <ng-template let-sponsor pTemplate="item">
                      <div class="logolistnav">
                        <ng-container *ngIf="
                    sponsor?.value.sponsorLogo !== undefined &&
                      sponsor?.value.sponsorLogo !== '' &&
                      sponsor?.value.sponsorLogo !== null;
                    else noLogo
                  ">
                          <img src="{{ sponsor?.value.sponsorLogo | imgurl }}" class="logolist" alt="Sponsor Logo" />
                        </ng-container>
                        <ng-template #noLogo>
                          <img src="{{ defaultSponsorLogo }}" class="logolist" alt="Sponsor Logo" />
                        </ng-template>
                        <div class="logoname">{{ sponsor?.value.sponsorName }}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <span class="mandatory">*</span>
                </div>
                <div *ngIf="
              form.controls['sponsorSelect'].invalid &&
              (form.controls['sponsorSelect'].touched ||
                form.controls['sponsorSelect'].dirty)
            " class="alert alert-danger">
                  Sponsor Is Required
                </div>
              </div>
              <div *ngIf="otherSponsor" class="row my-3 align-items-center">
                <label class="col-2">Sponsor Name</label>
                <div class="col-8">
                  <input pInputText class="form-control" type="text" formControlName="sponsorName"
                    placeholder="Sponsor Name" />
                  <span class="mandatory">*</span>
                </div>
                <div class="alert alert-danger" *ngIf="
              !form.controls['sponsorName'].valid &&
              (form.controls['sponsorName'].dirty ||
                form.controls['sponsorName'].touched)
            ">
                  Sponsor Name is Required and must be at least 3 characters long
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="forminfo">
                <ul>
                  <li><span class="mandatory">*</span> fields are mandatory</li>
                </ul>
              </div>
            </div>
            <div class="btnnav">
              <button type="submit" [disabled]="!form.valid || processing" class="btn btn-primary marright">
                Create
              </button>
              <button type="button" (click)="closeAddTrial()" class="btn btn-default">
                Cancel
              </button>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Trial Users">
            <div class="row my-2"></div>
            <app-add-trialusers [trial]="trial" [users]="users" [needPi]="true"
              (sendTrialUsers)="saveTrialUsers($event)"></app-add-trialusers>

            <div class="btnnav">
              <button type="submit" [disabled]="!form.valid || piUser === undefined || processing"
                class="btn btn-primary marright">
                Create
              </button>
              <button type="button" (click)="closeAddTrial()" class="btn btn-default">
                Cancel
              </button>
            </div>
          </p-tabPanel>
        </p-tabView>

      </div>





    </form>
  </div>
</div>