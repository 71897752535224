import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { AppConstants } from 'src/app/common/app.constants';
import { Message } from 'primeng/api';
import { RequestErrors } from 'src/app/common/utils';
import { Trial } from 'src/app/trial/model/trial.model';
import { UserRole } from 'src/app/users/model/userrole.model';
import { TrialUser } from 'src/app/trial/model/trialuser.model';
import { UserService } from 'src/app/users/service/user.service';

export interface TrialUserAdd {
  trialUserId?: number;
  trialId?: number;
  displayName?: string;
  email?: string;
  userId?: number;
  siteId?: number;
  userTypeName?: string;
  userRole?: UserRoleItem;
  userRoleName?: string;
  allowAccessToArchivedTrial?: boolean;
  IsUserAddedAtArchivedStatus?: boolean;
}

export interface UserRoleItem {
  label: string;
  value: number;
}
@Component({
  selector: 'app-edit-trialusers',
  templateUrl: './edit-trialusers.component.html',
  styleUrls: ['./edit-trialusers.component.scss'],
})
export class EditTrialUsersComponent implements OnChanges {
  @Output() sendTrialUsers = new EventEmitter<TrialUser[]>();
  @Output() removeTrialUser = new EventEmitter<TrialUser>();
  @Input() trial: Trial;
  @Input() originalTrialUsers: TrialUser[];

  RecsPerPage = 6;
  pageLinksPerPage = 6;
  emptyRecordMsg = AppConstants.emptyRecordMsg;
  editAddUserList = true;
  msgs: Message[] = [];
  havePi = false;
  userRoles: UserRole[];

  showConfirmRemoveUser = false;
  selectUserTaskComp = false;
  refreshOnCancel = false;
  cols = [
    { field: 'displayName', header: 'Name' },
    { field: 'userTypeName', header: 'User Type' },
    { field: 'userRole', header: 'Trial Role' },
  ];

  externalUserRole: UserRoleItem;
  externalUserRoles: UserRoleItem[]; // List of one item that can be selected for an external user
  defaultUserRole: UserRoleItem;
  userRoleItems: UserRoleItem[]; // List of items that can be selected for a non external user
  piUserRole: UserRoleItem;
  trialUsers: TrialUserAdd[] = []; // List of component specific trial user objects
  clonedTrialUsers: TrialUserAdd[] = []; // Holds the component specific trial user objects which are being edited
  editedTrialUsers: TrialUser[] = []; // The current list of trial users which have been changed
  selectedTrialUser: TrialUserAdd;

  constructor(private userService: UserService) {}

  showAddUserListNav() {
    this.editAddUserList = true;
    this.selectUserTaskComp = false;
  }

  ngOnChanges() {
    if (this.originalTrialUsers !== undefined) {
      this.getData();
    }
  }

  getUserRole(userRoleId): UserRoleItem {
    if (userRoleId === 1) {
      return this.externalUserRoles[0];
    } else {
      return this.userRoleItems.find((x) => x.value === userRoleId);
    }
  }

  getData() {
    // Filter out unneeded roles

    this.userService.getUserRoles().subscribe(
      (data) => {
        this.userRoles = data;
        this.userRoleItems = this.userRoles
          .filter((x) => {
            return x.userRoleName !== 'External User';
          })
          .map((x) => {
            return {
              label: x.userRoleName,
              value: x.userRoleId,
            };
          });
        this.externalUserRoles = this.userRoles
          .filter((x) => x.userRoleName === 'External User')
          .map((x) => {
            return {
              label: x.userRoleName,
              value: x.userRoleId,
            };
          });

        this.defaultUserRole = {
          label: 'Trial Role',
          value: 0,
        };
        this.piUserRole = this.userRoleItems.find((x) => x.value === 4);
        this.trialUsers = this.originalTrialUsers.map((x) => {
          return {
            trialUserId: x.trialUserId,
            userId: x.userId,
            trialId: this.trial.trialId,
            siteId: this.trial.siteId,
            displayName: x.displayName,
            userTypeName: x.userTypeName,
            email: x.email,
            allowAccessToArchivedTrial: true,
            userRole: this.getUserRole(x.userRoleId),
            userRoleName: x.userRoleName,
            IsUserAddedAtArchivedStatus:
              this.trial.trialStatusName === 'Archived',
          };
        });
        const sorted = this.trialUsers.sort((a, b) =>
          a.displayName.localeCompare(b.displayName, undefined, {
            caseFirst: 'upper',
          })
        );
        this.trialUsers = [...sorted];
        this.checkForPi();
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  checkForPi() {
    let idx = this.trialUsers.findIndex((x) => x.userRole.value === 4);
    this.havePi = idx !== -1;
    idx = this.userRoleItems.findIndex((x) => x.value === 4);
    if (this.havePi) {
      if (idx !== -1) {
        this.userRoleItems.splice(idx, 1);
      }
    } else {
      if (idx === -1) {
        this.userRoleItems.push(this.piUserRole);
      }
    }
  }

  openRemoveUserFromSite(trialUser: TrialUser) {
    this.selectedTrialUser = trialUser;
    this.showConfirmRemoveUser = true;
  }

  closeConfirmRemoveTrialUser(answer: boolean) {
    if (answer === true) {
      this.removeTrialUser.emit(this.selectedTrialUser);
    }
    this.showConfirmRemoveUser = false;
  }

  onRowEditInit(trialUser: TrialUserAdd, index: number) {
    this.clonedTrialUsers[index] = { ...trialUser };
    // if this user is not external and is the pi - all choices
    if (trialUser.userRole.value === 4) {
      // this person is already the pi, add the piRole to the dropdown,
      // it'll get removed during save or cancel
      this.userRoleItems.push(this.piUserRole);
    }
  }

  onRowEditSave(trialUser: TrialUserAdd) {
    const newTrialUser = new TrialUser(trialUser);
    newTrialUser.userId = trialUser.userId;
    newTrialUser.trialId = this.trial.trialId;
    newTrialUser.siteId = this.trial.siteId;
    newTrialUser.displayName = trialUser.displayName;
    newTrialUser.userRoleId = trialUser.userRole.value;
    newTrialUser.userRoleName = trialUser.userRole.label;
    trialUser.userRoleName = trialUser.userRole.label;
    // Check to see if this trialUser is already in edited
    let idx = this.editedTrialUsers.findIndex(
      (x) => x.userId === trialUser.userId
    );
    if (idx === -1) {
      this.editedTrialUsers.push(newTrialUser);
    } else {
      this.editedTrialUsers.splice(idx, 1, newTrialUser);
    }

    this.checkForPi();

    idx = this.trialUsers.findIndex((x) => x.userId === trialUser.userId);
    this.sendTrialUsers.emit(this.editedTrialUsers);
  }

  onRowEditCancel(index: number) {
    this.checkForPi();
    this.trialUsers[index] = this.clonedTrialUsers[index];
    delete this.clonedTrialUsers[index];
  }
}
