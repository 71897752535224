import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppsettingsService } from '../../core/appsettings.service';
import { ApiResponse } from '../../core/apiresponse';
import { Observable } from 'rxjs';

import { UserPermissionDTO } from '../model/userpermission.dto';
import { UserSite } from '../model/user-site.model';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, public settings: AppsettingsService) {}

  // Get all sites a user has for dropdown on homecomponent
  getUserSiteDropdown(): Observable<UserSite[]> {
    return this.http.get<UserSite[]>(
      this.settings.baseUrl + 'api/userpermission/GetUserSiteDropdown'
    );
  }

  // Get a users type for a site
  getUserType(userId: number, siteId: number) {
    return this.http.get<ApiResponse>(
      this.settings.baseUrl +
        'api/userpermission/UserId/' +
        userId +
        '/SiteId/' +
        siteId,
      this.httpOptions
    );
  }

  // Used by userlist to remove a user from a site
  removeUserFromSite(userId: number, siteId: number) {
    return this.http.delete<ApiResponse>(
      this.settings.baseUrl +
        'api/userpermission/UserId/' +
        userId +
        '/SiteId/' +
        siteId,
      this.httpOptions
    );
  }

  // Used by userlist to add users to a site
  changeUserType(userPermission: UserPermissionDTO) {
    return this.http.put<ApiResponse>(
      this.settings.baseUrl + 'api/userpermission',
      userPermission,
      this.httpOptions
    );
  }

  // Used by userlist to add users to a site
  addUsersToSite(users: UserPermissionDTO[]) {
    return this.http.post<ApiResponse>(
      this.settings.baseUrl + 'api/userpermission',
      users,
      this.httpOptions
    );
  }
}
