import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { DocumentRoutingModule } from '../document-routing/document-routing.module';
import { OperationsBinderComponent } from './operations-binder.component';
import { WorkFlowRoutingModule } from '../workflow-routing/workflow-routing.module';
import { DocumentsModule } from '../documents/documents.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    WorkFlowRoutingModule,
    DocumentRoutingModule,
    DocumentsModule,
  ],
  declarations: [OperationsBinderComponent],
  exports: [OperationsBinderComponent],
})
export class OperationsBinderModule {}
