import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Message } from 'primeng/api';
import { SubjectService } from 'src/app/subject/service/subject.service';
import { RequestErrors } from 'src/app/common/utils';
import { Subject } from '../model/subject.model';

@Component({
  selector: 'app-unlink-trial-subject',
  templateUrl: './unlink-trial-subject.component.html',
  styleUrls: ['./unlink-trial-subject.component.scss'],
})
export class UnlinkTrialSubjectComponent implements OnInit {
  msgs: Message[] = [];
  siteId = Number(sessionStorage.getItem('siteId'));
  trialId = Number(sessionStorage.getItem('trialId'));
  trialName: string = sessionStorage.getItem('trialName');

  @Output() closeUnlinkSubject = new EventEmitter<boolean>();

  @Input() subject: Subject;

  subBtnDisable: boolean;
  btnConfirm: string;
  constructor(private subjectService: SubjectService) {}

  CancelUnlinkSubject(refresh = false) {
    this.closeUnlinkSubject.emit(refresh);
  }

  onUnlink() {
    this.btnConfirm = 'Processing..';

    this.subjectService.deleteSubject(this.subject.subjectId).subscribe(
      (messageResponse) => {
        this.btnConfirm = 'Confirm';
        if (messageResponse.success === true) {
          this.CancelUnlinkSubject(true);
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            detail: messageResponse.errorMessages[0],
          });
          this.subBtnDisable = false;
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }

  ngOnInit() {
    this.btnConfirm = 'Confirm';
    this.subBtnDisable = false;

    this.trialName = sessionStorage.getItem('trialName');
  }
}
