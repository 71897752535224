<div class="b_container">

    <div class="rtheader">
        Add Vendor<div class="closesidebar" (click)="cancelEditVendor(false)">X</div>
    </div>

    <div class="rtcontainer messages">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <p-messages [value]="msgs"></p-messages>
            <div class="row">
                <div class="col-12">
                    <div class="sectionheader">
                        Select a vendor to add
                    </div>
                    <!-- For adding a standard vendor -->
                    <div *ngIf="!createCustom" class="row my-3 align-items-center">
                        <label class="col-3">Vendor</label>
                        <div class="col-8 dd95">
                            <p-autoComplete [suggestions]="filteredVendors" field="vendorName"
                                formControlName="vendorSelect" (completeMethod)="filterVendorDropDown($event)"
                                (onSelect)="onVendorSelect($event)" [minLength]="1" placeholder="Vendor"
                                [dropdown]="true" dropdownIcon="fa fa-caret-down">
                                <ng-template let-vendorUserName pTemplate="item">

                                    <div class="logolistnav">
                                        <ng-container
                                            *ngIf="((vendorUserName.vendorLogo !== undefined) && (vendorUserName.vendorLogo !== '') && (vendorUserName.vendorLogo !== null)); else noLogo">
                                            <img src="{{vendorUserName.vendorLogo | imgurl}}" class="logolist"
                                                alt="Sponsor Logo" />
                                        </ng-container>
                                        <ng-template #noLogo>
                                            <img src="{{defaultVendorLogo}}" class="logolist" alt="Vendor Logo" />
                                        </ng-template>
                                        <div class="logoname">{{vendorUserName.vendorName}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                            <span class="mandatory">*</span>
                        </div>
                        <div *ngIf="form.controls['vendorSelect'].invalid && form.controls['vendorSelect'].touched"
                            class="alert alert-danger">
                            Vendor is Required
                        </div>
                    </div>
                    <div class="row my-3 align-items-center">
                        <label class="col-3"></label>
                        <div class="col-8">
                            <a *ngIf="!createCustom" (click)="createCustomToggle()">My vendor is not in the list, click
                                here to create your
                                own</a>
                            <a *ngIf="createCustom" (click)="createCustomToggle()">I want to select a standard vendor
                                from the dropdown
                            </a>

                        </div>
                    </div>

                    <div *ngIf="createCustom" class="row my-3 align-items-center">
                        <label class="col-3">Vendor Name</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="text" formControlName="vendorName"
                                placeholder="Vendor Name" />
                        </div>
                        <div *ngIf="form.controls['vendorName'].invalid && form.controls['vendorName'].touched"
                            class="alert alert-danger">
                            Vendor Name is Required and must be between 3 and 50 characters in length
                        </div>
                    </div>

                    <div *ngIf="createCustom" class="row my-3 align-items-center">
                        <label class="col-3">Vendor Url</label>
                        <div class="col-8">
                            <input pInputText class="form-control" type="url" formControlName="vendorUrl"
                                placeholder="Vendor Url" />
                        </div>
                        <div *ngIf="form.controls['vendorUrl'].invalid && form.controls['vendorUrl'].touched"
                            class="alert alert-danger">
                            Vendor Url is Required and must be a valid url
                        </div>

                    </div>

                    <!-- Category, default to vendor's when adding  -->
                    <div class="row my-3 align-items-center">
                        <label class="col-3">Category</label>
                        <div class="col-8 dd95">

                            <p-dropdown dropdownIcon="fa fa-caret-down" [options]="vendorCategories"
                                formControlName="vendorCategoryId" placeholder="Category"
                                optionLabel="categoryName"></p-dropdown>
                            <span class="mandatory">*</span>
                        </div>
                        <div *ngIf="form.controls['vendorCategoryId'].invalid && form.controls['vendorCategoryId'].touched"
                            class="alert alert-danger">
                            Vendor Category is Required
                        </div>
                    </div>

                    <div *ngIf="swaVendor; else notSwa">
                        <div class="sectionheader">
                            User Credentials for this vendor
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-3">User Name</label>
                            <div class="col-8">
                                <input pInputText class="form-control" type="text" formControlName="vendorUserName"
                                    placeholder="User Name" />
                            </div>
                        </div>
                        <div class="row my-3 align-items-center">
                            <label class="col-3">Password</label>
                            <div class="col-8">
                                <div class="p-inputgroup">
                                    <input type="password" pPassword class="form-control"
                                        [showPassword]="passwordVisible" placeholder="Password" feedback="false"
                                        formControlName="vendorPassword">
                                    <span (click)="passwordVisible = !passwordVisible" *ngIf="!passwordVisible"
                                        class="p-inputgroup-addon"><i class="fa fa-eye"></i></span>
                                    <span (click)="passwordVisible = !passwordVisible" *ngIf="passwordVisible"
                                        class="p-inputgroup-addon"><i class="fa fa-eye-slash"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #notSwa>
                        <div class="row my-3 align-items-center">
                            <div class="col">

                            </div>
                        </div>
                    </ng-template>
                    <div class="col-md-4">
                        <div class="forminfo">
                            <ul>
                                <li><span class="mandatory">*</span> fields are mandatory</li>
                            </ul>
                        </div>
                    </div>
                    <div class="btnnav">
                        <button type="submit" [disabled]="(!form.valid || buttonSubmit ==='Processing..')"
                            class="btn btn-primary marright">
                            {{buttonSubmit}}
                            <i class="fa fa-spinner fa-spin" *ngIf="buttonSubmit==='Processing..'"></i>
                        </button>
                        <button type="button" (click)="cancelEditVendor(false)" class="btn btn-default">Cancel</button>
                    </div>

                </div>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="row my-3">
                        <div class="col-sm-12">
                            <b>Note:</b> If your vendor is not in the dropdown list or you are having issues setting up
                            your vendor please click <a (click)=AddRequest()
                                class="himg cursorpointer alink"><b>here</b></a>
                            for assistance
                        </div>
                    </div>
                </div>
            </div>

        </form>

    </div>
</div>