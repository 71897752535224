<div class="b_container messages">
  <app-p-breadcrumbs [items]="pMenuItems"></app-p-breadcrumbs>
  <p-messages [value]="msgs"></p-messages>
  <div class="clear"></div>
  <div class="spacer"></div>
  <div class="contentnav">
    <div class="row">
      <div class="col-md-12 trialdashboard" id="tdash">
        <div class="datatable">
          <p-table [columns]="cols" #dt [loading]="loading" [value]="documentFlowLogData"
            filterBy="routedOnDate,eventStatusName,docName" [sortField]="sortField" [sortOrder]="sortOrder"
            resizableColumns="true" columnResizeMode="expand">
            <ng-template pTemplate="caption">
              <div class="d-flex flex-row mb-3 justify-content-between align-items-center">

                <p-dropdown dropdownIcon="fa fa-caret-down" [options]="sortOptions" [(ngModel)]="sortKey"
                  placeholder="Sort By" (onChange)="onSortChange($event)"></p-dropdown>

                <div class="wdsrch p-inputgroup">
                  <input class="form-control" type="text" pInputText placeholder="File Name"
                    (keyup)="dv.filter($event.target.value)" />
                  <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
                </div>


              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>

              <tr>
                <th>Document</th>
                <th>Routed From</th>
                <th>Routed To</th>
                <th>Routed On</th>
                <th>Status</th>
                <th>Signature</th>
                <th>Approval/Rejection Date</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr (click)="onDocClick(rowData)" style="cursor: pointer;">

                <td>
                  {{rowData.docName }}.{{rowData.docExt}}
                </td>
                <td>
                  {{rowData.routedFromDisplayName}} - {{rowData.routedFromUserRole}}
                </td>
                <td>
                  {{rowData.routedToDisplayName}}
                </td>
                <td>
                  {{rowData.routedOnDate | date:'MMM dd, yyyy'}}
                </td>
                <td>
                  {{rowData.eventStatusName}}
                </td>
                <td>
                  <div *ngIf="rowData.eventStatusName === 'Approved'">
                    <span class="signftf" *ngIf="rowData.signatureOption === 1">{{rowData.approvedByDisplayName}}</span>
                    <span class="signfts" *ngIf="rowData.signatureOption === 2">{{rowData.approvedByDisplayName}}</span>
                    <span class="signftt" *ngIf="rowData.signatureOption === 3">{{rowData.approvedByDisplayName}}</span>
                  </div>
                </td>
                <td>
                  <div *ngIf="rowData.eventStatusName === 'Rejected' || rowData.eventStatusName === 'Approved'"
                    class="appadd">
                    {{rowData.approvedDate | date:'MMM dd, yyyy'}}
                  </div>

                </td>

              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              {{emptyRecordMsg}}
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog #pdialog *ngIf="loadDocumentViewer" [style]="{width: '75%'}" [visible]="loadDocumentViewer" [modal]="true"
  [draggable]="false" [closable]="false" [baseZIndex]="10000">
  <p-header>
    {{ selectedDocument.docName }}
  </p-header>
  <app-document-viewer [fileExtension]="selectedDocument.docExt" [fileName]="selectedDocument.docName"
    [docPath]="selectedDocument.docPath" (closeModal)="closeModals(true)"></app-document-viewer>
</p-dialog>