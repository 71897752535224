import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { User } from 'src/app/users/model/user.model';
import { UserService } from 'src/app/users/service/user.service';
import { Message } from 'primeng/api';
import { RequestErrors, updateCurrentUserRole } from 'src/app/common/utils';
import { Trial } from 'src/app/trial/model/trial.model';
import { TrialUser } from 'src/app/trial/model/trialuser.model';
import { TrialUserService } from 'src/app/trial/service/trialuser.service';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-add-trialuser-list',
  templateUrl: './add-trialuser-list.component.html',
  styleUrls: ['./add-trialuser-list.component.scss'],
})
export class AddTrialUserListComponent implements OnInit {
  @Output() closeAddUserModal = new EventEmitter<boolean>();
  @Input() trial: Trial;
  @Input() needPi: boolean;
  siteId = Number(sessionStorage.getItem('siteId'));
  userId = Number(sessionStorage.getItem('userId'));
  trialUsers: TrialUser[] = [];
  editAddUserList = true;
  msgs: Message[] = [];
  selectUserTaskComp = false;
  users: User[];

  constructor(
    private userService: UserService,
    private trialUserService: TrialUserService
  ) {}

  closeModal(save = false) {
    let currentUserRoleId = 0;
    if (this.trialUsers.length > 0 && save) {
      const saves = [];
      this.trialUsers.forEach((x) => {
        if (x.userId === this.userId) {
          currentUserRoleId = x.userRoleId;
        }
        saves.push(this.trialUserService.addTrialUser(x));
      });
      forkJoin(saves).subscribe(
        () => {
          // Check to see if I need to update my trialuserrole
          if (currentUserRoleId !== 0) {
            updateCurrentUserRole(currentUserRoleId);
          }
          this.closeAddUserModal.emit(true);
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return {
              severity: 'warn',
              detail: x,
            };
          });
        }
      );
    } else {
      this.closeAddUserModal.emit(false);
    }
  }

  showAddUserListNav() {
    this.editAddUserList = true;
    this.selectUserTaskComp = false;
  }

  ngOnInit() {
    this.getData();
  }

  getData(): void {
    this.userService
      .getNonPIUserDetailsforTrials(this.siteId, this.trial.trialId)
      .subscribe(
        (users) => {
          this.users = users;
        },
        (error) => {
          this.msgs = RequestErrors(error).map((x) => {
            return { severity: 'warn', detail: x };
          });
        }
      );
  }

  saveTrialUsers(trialUsers: TrialUser[]) {
    this.trialUsers = [...trialUsers];
  }
}
